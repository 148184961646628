import { spacing } from '@blink/components';
import { createUseStyles } from 'react-jss';

export const useUserBorrowsSectionStyle = createUseStyles({
    root: {
        marginTop: spacing['09'],
    },
    cardsWrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: spacing['04'],
        marginTop: spacing['06'],
    },
    paginationWrapper: {
        float: 'right',
        marginTop: spacing['04'],
    },
    borrowRatesListButtons: {
        width: 'fit-content',
        minWidth: 295,
        marginBottom: spacing['08'],
        '& > label': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: '1 0 0',
            padding: spacing['04'],
        },
        '& .ant-radio-button-wrapper': {
            height: 44,
        },
    },
});
