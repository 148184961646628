import { Api } from '@blink/components';
import { NETWORK } from '@blink/components/src/constants/global';

export enum ApyChartFormat {
    DAYS = 'days',
    WEEKS = 'weeks',
    MONTHS = 'months',
}

type GetApyForAssetProps = {
    assetName: string;
    format: ApyChartFormat;

    blockchainName?: string;
    from?: number;
    to?: number;
};

export type ApyForAssetChartProps = {
    base_apy: number;
    protocol_asset_apy: number;
    profit_sharing_apy: number;
    timestamp: number;
};

export const getApyForAsset = ({
    assetName,
    format,
    to,
    blockchainName = NETWORK.ETHEREUM,
    from,
}: GetApyForAssetProps) => {
    return Api.getResponse<ApyForAssetChartProps[]>(
        `${
            Api.LEVERAGE_MARGIN_ENGINE_URL
        }public/leverage/${blockchainName}/${assetName}/chart?format=${format}${
            from ? `from=${from}` : ''
        }${to ? `to=${to}` : ''}`, //TODO: remove legacy
    );
};
