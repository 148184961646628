import { DEX } from '../constants/global';

export type AssetBalance = {
    balance: number;
    price: number;
};

export interface Asset {
    name: AssetName;
    exchange: string;
}

export enum ALLOWANCE_STATUS {
    ERROR = 'error',
    SUCCESS = 'success',
    PROCESSING = 'processing',
    WARNING = 'warning',
    WAITING = 'waiting',
}

export interface AssetPair {
    assetFrom?: AssetName | null;
    assetTo?: AssetName | null;
}

export interface AssetPairExchange extends AssetPair {
    exchange: string;
}

export interface Exchange {
    base_symbol: string;
    exchange_name: DEX;
    pool_address: string;
    price: number;
    price_difference_number_in_usdt: number;
    price_in_usdt: number;
    quote_symbol: string;
    volume: number;
}

export interface SelectedAssetPairInfo {
    current_price: number;
    high_in_usdt: number;
    low_in_usdt: number;
    price_difference_number_in_usdt: number;
    price_difference_percent_in_usdt: number;
    volume_in_usdt: number;
}

export type AssetName = string;
