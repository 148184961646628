import { createUseStyles } from 'react-jss';
import { color, fontSize, spacing } from '@blink/components';

export const useAssetsToSupplySectionStyle = createUseStyles({
    totalSupplyWrapper: {
        width: '25%',
        fontWeight: '500 !important',
    },
    claimReward: {
        textAlign: 'left !important',
        width: '100% !important',
        paddingLeft: '20px',
    },
    buttons: {
        width: '10%',
    },
    asset: {
        width: 250,
    },
    supply: {
        display: 'flex',
        alignItems: 'center',
        width: 'max-content',
        cursor: 'default',
        '&:hover': {
            '& svg > path': {
                fill: color.border.selected,
            },
        },
    },
    icon: {
        marginLeft: spacing['03'],
        width: 14,
        '& path': {
            fill: color.content.secondary,
        },
    },
    rewardText: {
        fontSize: fontSize.sm,
        color: color.content.secondary,
    },
    rewardPercent: {
        fontSize: fontSize.sm,
        color: color.content.primary,
    },
    tooltip: {
        padding: `${spacing['04']}`,
    },
    tooltipSupplyRoot: {
        borderRadius: 4,
        width: 264,
        '& .ant-tooltip-inner': {
            padding: spacing['05'],
            border: `1px solid ${color.border.opaque}`,
            background: color.background.secondary,
        },
    },
    title: {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: fontSize.md,
        marginBottom: spacing['03'],
    },
    reward: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 30,
    },
    spin: {
        textAlign: 'center',
        height: 50,
    },
});
