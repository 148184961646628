import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as WalletIcon } from '@blink/assets/src/icons/wallet-white.svg';
import { WalletHelper } from '@blink/components/src/utils';
import { Button } from '@blink/components';

import { Store } from 'src/store';
import { useWalletManagerStyles } from './ConnectWalletInfo.style';
import { showModalAction } from '../../store/modals/actions';
import { ModalName } from '../../store/modals/constants';

export const ConnectedWalletInfo = () => {
    const classes = useWalletManagerStyles();
    const address = useSelector((state: Store) => state.wallets.active.address);
    const dispatch = useDispatch();
    const shortAddressName = WalletHelper.addressShortName(address);

    const handleClick = () => {
        dispatch(showModalAction({ modalName: ModalName.WALLET_INFO_MODAL }));
    };

    return (
        <Button className={classes.root} onClick={handleClick}>
            <WalletIcon />
            <div className={classes.text}>{shortAddressName}</div>
        </Button>
    );
};
