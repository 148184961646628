import { put, select, takeLatest, call } from 'redux-saga/effects';
import { Contract, ethers, formatUnits } from 'ethers';
import { Api } from '@blink/components';
import { AbstractWallet, WalletHelper } from '@blink/components/src/utils';
import { walletMap } from '@blink/components/src/constants/wallet';
import { Errors } from '@blink/components/src/constants/errors';

import {
    GET_ASSETS_TO_SUPPLY,
    GET_FUNDS_TO_CLAIM,
    GET_USERS_SUPPLIES,
} from '../store/supplies/constants';
import { setAssetsToSupply, setFundAction, setUsersSupplies } from '../store/supplies/actions';
import { Store } from '../store';
import { Permission, Token, getPermisions, getTokens } from '../api/borrow';
import FundRegistryABI from '../abi/FundRegistryABI.json';
import PoolRegistryAbi from '../abi/PoolRegistryAbi.json';
import PoolAbi from '../abi/PoolAbi.json';
import FundABI from '../abi/FundABI.json';
import IERCAbi from '../abi/IERCAbi.json';
import {
    assetsToSupplyErrorAction,
    fundsToClaimErrorAction,
    usersSuppliesErrorAction,
} from 'src/store/errors/actions';
import { mergeArraysById } from 'src/utils/helpers';
import { UserSupply } from 'src/store/supplies';

const getAssetBalance = async (
    liquidityPool: any,
    fundRegistry: any,
    signer: any,
    assetAddress: string,
    assetName: string,
) => {
    const poolAddress = await liquidityPool.getPool(assetAddress);
    const fundAddress = await fundRegistry.getFund(poolAddress);
    const fund = new Contract(fundAddress, FundABI, signer);
    const balanceBigInt = await fund.balanceOf(signer.address);
    return { balance: Number(balanceBigInt), assetName, assetAddress };
};

const ASSET_NOT_SUPPORTED_ERROR = '0xff159595';

function* getFundBalanceSaga(): any {
    try {
        const { type, blockchainName, assetsToSupply } = yield select((state: Store) => ({
            type: state.wallets.active.type,
            blockchainName: state.wallets.network,
            assetsToSupply: state.supplies.assetsToSupply,
        }));
        const wallet: AbstractWallet = walletMap.get(type) as AbstractWallet;
        const walletProvider = yield wallet.getProvider();

        const provider = new ethers.BrowserProvider(
            walletProvider,
            WalletHelper.getNetworkNumber(blockchainName),
        );
        const signer = yield provider.getSigner();
        const fundRegistry = new Contract(
            Api.INSURANCE_FUND_ADDRESS as string,
            FundRegistryABI,
            signer,
        );
        const liquidityPool = new Contract(
            Api.LIQUIDITY_POOL_ADDRESS as string,
            PoolRegistryAbi,
            signer,
        );
        const assetsToSupplyArray = [];
        for (const assetToSupply of assetsToSupply) {
            if (assetToSupply.leverage) {
                const promise = new Promise((res) => {
                    res(
                        getAssetBalance(
                            liquidityPool,
                            fundRegistry,
                            signer,
                            assetToSupply.address,
                            assetToSupply.symbol,
                        ),
                    );
                });
                assetsToSupplyArray.push(promise);
            }
        }

        const resultArray = yield Promise.allSettled(assetsToSupplyArray);
        const ids = [];
        const balances: any = {};
        const addresses: any = {};
        let isVisible = false;
        for (const resultItem of resultArray) {
            if (resultItem.status === 'rejected') {
                console.warn(resultItem.reason);
                if (resultItem.reason?.data?.toLowerCase().includes(ASSET_NOT_SUPPORTED_ERROR)) {
                    console.warn('Asset not supported');
                }
                continue;
            }
            const id = resultItem?.value?.assetName;
            const value = resultItem?.value?.balance;
            const address = resultItem?.value?.assetAddress;
            if (!isVisible && value > 0) {
                isVisible = true;
            }
            ids.push(id);
            balances[id] = value;
            addresses[id] = address;
        }

        yield put(setFundAction({ ids, balances, addresses, isVisible }));
    } catch (e: any) {
        yield put(fundsToClaimErrorAction({ message: e.message || Errors.FUNDS_TO_CLAIM }));
        console.error(e);
    }
}

function* getUserSuppliesSaga(): Generator<any, any, any> {
    try {
        const {
            type,
            blockchainName,
            assetsToSupply,
            walletAddress,
        }: { type: any; blockchainName: any; assetsToSupply: Token[]; walletAddress: string } =
            yield select((state: Store) => ({
                type: state.wallets.active.type,
                blockchainName: state.wallets.network,
                walletAddress: state.wallets.active.address,
                assetsToSupply: state.supplies.assetsToSupply,
            }));

        const wallet: AbstractWallet = walletMap.get(type) as AbstractWallet;
        if (wallet) {
            const walletProvider = yield wallet.getProvider();

            const provider = new ethers.BrowserProvider(
                walletProvider,
                WalletHelper.getNetworkNumber(blockchainName),
            );

            const liquidityPool = new Contract(
                Api.LIQUIDITY_POOL_ADDRESS as string,
                PoolRegistryAbi,
                provider,
            );

            const signer = yield provider.getSigner();

            const leverageSupply: Token[] = assetsToSupply.filter(
                (item: Token) => item.leverage === true,
            );

            const userSupplies: UserSupply[] = [];

            for (const asset of leverageSupply) {
                try {
                    const poolAddress = yield liquidityPool.getPool(asset.address);
                    const pool = new Contract(poolAddress, PoolAbi, signer);
                    const balance = yield pool.getPositionInfo(walletAddress);
                    const reward = yield pool.getPendingRewards(walletAddress);

                    if (balance !== 0n) {
                        userSupplies.push({
                            asset_name: asset.name,
                            asset_symbol: asset.symbol,
                            balance: formatUnits(balance, asset.decimals),
                            reward: formatUnits(reward, asset.decimals),
                            apy: asset.apy,
                            decimals: asset.decimals,
                        });
                    }
                } catch (error) {
                    console.log(error);
                }
            }
            yield put(setUsersSupplies(userSupplies as UserSupply[]));
        }
    } catch (e: any) {
        yield put(setUsersSupplies([]));
        yield put(usersSuppliesErrorAction({ message: e.message || Errors.USERS_SUPPLIES }));
        console.error(e);
    }
}

function* getAssetsToSupplySaga(): Generator<any, void, any> {
    try {
        const {
            type,
            blockchainName,
            walletAddress,
        }: { type: any; blockchainName: any; walletAddress: string } = yield select(
            (state: Store) => ({
                type: state.wallets.active.type,
                blockchainName: state.wallets.network,
                walletAddress: state.wallets.active.address,
            }),
        );
        const wallet: AbstractWallet = walletMap.get(type) as AbstractWallet;

        if (wallet) {
            const walletProvider: any = yield wallet.getProvider();

            const provider = new ethers.BrowserProvider(
                walletProvider,
                WalletHelper.getNetworkNumber(blockchainName),
            );
            const signer: ethers.Signer = yield provider.getSigner();

            const liquidityPool = new Contract(
                Api.LIQUIDITY_POOL_ADDRESS as string,
                PoolRegistryAbi,
                provider,
            );

            const supplies: Token[] = yield call(getTokens);
            const permissions: Permission[] = yield call(getPermisions);

            const resultSupplies = mergeArraysById(supplies, permissions);

            for (const token of resultSupplies) {
                const tokenAddress = token.address || '';

                try {
                    const tokenContract = new Contract(tokenAddress, IERCAbi, signer);
                    const balance = yield tokenContract.balanceOf(walletAddress);

                    const balanceInToken: string = formatUnits(balance, token?.decimals);
                    token.balance = balanceInToken;

                    if (token.leverage) {
                        const poolAddress = yield liquidityPool.getPool(tokenAddress);
                        const pool = new Contract(poolAddress, PoolAbi, signer);

                        token.apyRewards = '0'; //Temporary hardcoded AR-658

                        const totalSupplyMax = yield pool.thresholdOnTotalDeposit();
                        const totalSupplyMaxInToken: string = formatUnits(
                            totalSupplyMax,
                            token?.decimals,
                        );
                        token.totalSupplyMax = totalSupplyMaxInToken;

                        const totalSupplyCurrent = yield pool.totalDeposited();
                        const totalSupplyCurrentInToken: string = formatUnits(
                            totalSupplyCurrent,
                            token?.decimals,
                        );
                        token.totalSupplyCurrent = totalSupplyCurrentInToken;
                    }
                } catch (error) {
                    yield put(assetsToSupplyErrorAction({ message: Errors.ASSETS_TO_SUPPLY }));
                }
            }

            yield put(setAssetsToSupply(resultSupplies as Token[]));
        }
    } catch (e: any) {
        yield put(assetsToSupplyErrorAction({ message: e.message || Errors.ASSETS_TO_SUPPLY }));
        console.error(e);
    }
}

export function* suppliesWatcher() {
    yield takeLatest(GET_FUNDS_TO_CLAIM, getFundBalanceSaga);
    yield takeLatest(GET_USERS_SUPPLIES, getUserSuppliesSaga);
    yield takeLatest(GET_ASSETS_TO_SUPPLY, getAssetsToSupplySaga);
}
