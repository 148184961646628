import React, { FC, useState } from 'react';
import { Spin } from 'antd';
import { AssetWithIcon, InputNumberCustom } from '@blink/components';
import { concatClasses } from '@blink/components/src/utils';

import { useLiquidityAssetInputStyle } from './AssetInputSupply.style';

type AssetInputSupplyProps = {
    assetName: string;
    balance: number;
    value?: string;
    setValue: (value: any) => void;
    maxBorrowLoading?: boolean;
    disabled?: boolean;
    customTitle?: string;
    customBalanceLabel?: string;
    classes?: Partial<Record<AssetInputSupplyClasses, string>>;
    isSupply?: boolean;
    borrowApy?: string;
    decimals: number;
};

type AssetInputSupplyClasses = 'root';

export const AssetInputSupply: FC<AssetInputSupplyProps> = ({
    value,
    setValue,
    assetName,
    balance,
    classes,
    customBalanceLabel,
    customTitle,
    disabled,
    maxBorrowLoading,
    isSupply = true,
    borrowApy,
    decimals,
}) => {
    const baseClasses = useLiquidityAssetInputStyle();
    const [isError, setIsError] = useState<boolean>(false);

    const handleMaxClick = () => {
        setValue(balance);
        setIsError(false);
    };

    const handleAmountChange = (value: number | null) => {
        setValue(value);
        const isValueMoreThanBalance = Number(value) > Number(balance);
        setIsError(isValueMoreThanBalance);
    };

    const title = () => {
        if (customTitle && !isError) {
            return customTitle;
        } else if (customTitle && isError) {
            return 'Balance Exceeded';
        } else {
            if (!isError) {
                return 'Your Deposit';
            } else {
                return 'Amount Exceeds Wallet Balance';
            }
        }
    };

    const getMaxBorrow = () => {
        return maxBorrowLoading ? (
            <div>
                <Spin size='small' />
            </div>
        ) : (
            <div>{balance}</div>
        );
    };

    return (
        <div
            className={concatClasses(
                baseClasses.root,
                classes?.root || '',
                `${isError && 'error'}`,
                `${isSupply && 'supply'}`,
            )}
        >
            <div className={baseClasses.header}>
                <div className='title'>{title()}</div>
                <div className={baseClasses.balanceWrapper}>
                    <div>{`${customBalanceLabel ?? 'Available'}: `}</div>
                    {isSupply ? (
                        <div className={baseClasses.balance}>{balance}</div>
                    ) : (
                        <div className={baseClasses.balance}>{getMaxBorrow()}</div>
                    )}
                </div>
            </div>
            <div className={baseClasses.footer}>
                <div className={baseClasses.inputWrapper}>
                    <AssetWithIcon
                        assetName={assetName || ''}
                        classes={{ assetName: baseClasses.assetName }}
                    />
                </div>
                <div className={baseClasses.input}>
                    <InputNumberCustom
                        placeholder={'0'}
                        value={value}
                        handleChange={handleAmountChange}
                        disabled={disabled}
                        max={balance ? balance.toString() : ''}
                        handleMaxClick={handleMaxClick}
                        decimals={decimals}
                    />
                </div>
            </div>
            {!isSupply && (
                <div className={baseClasses.borrowRates}>
                    <div className={baseClasses.borrowApyWrapper}>
                        <div className={baseClasses.borrowApy}>
                            <div>Borrow APY</div>
                        </div>
                        <div>{Number(borrowApy)}%</div>
                    </div>
                </div>
            )}
        </div>
    );
};
