import { createUseStyles } from 'react-jss';

import { color, fontSize, spacing } from '../variables';

export const useInputStyles = createUseStyles({
    maxButtonLink: {
        padding: `0 ${spacing['02']}`,
        color: color.textPrimary,
        height: 'fit-content',
        '&:hover': {
            color: `${color.strokeHover} !important`,
        },
    },
    maxButton: {
        display: 'flex',
        padding: `${spacing['02']}`,
        color: color.content.primary,
        backgroundColor: color.primary,
        height: 26,
        width: 44,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 4,
        '&:hover': {
            backgroundColor: `${color.primary} !important`,
        },
        '& span': {
            textDecoration: 'none !important',
            fontSize: fontSize.sm,
            fontWeight: 600,
            color: color.background.primary,
        },
    },
    inputWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
});
