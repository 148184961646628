import * as actions from './actions';
import {
    GET_USER_BORROWS,
    SET_USER_BORROWS,
    SET_USER_BORROWS_REGISTERED,
    SET_USER_BORROWS_SUSPENDED,
    SET_USER_BORROWS_CLOSED,
    SET_USER_BORROWS_MAINTENANCE,
    SET_USER_BORROWS_MAINTENANCE_HISTORY,
} from './constants';
import { UserBorrow } from 'src/api/borrow';

export type BorrowState = {
    userBorrowsLoading: boolean;
    userBorrows: UserBorrow[];
    userBorrowsSuspended: UserBorrow[];
    userBorrowsRegistered: UserBorrow[];
    userBorrowsClosed: UserBorrow[];
};

const defaultState: BorrowState = {
    userBorrowsLoading: false,
    userBorrows: [],
    userBorrowsSuspended: [],
    userBorrowsRegistered: [],
    userBorrowsClosed: [],
};

type BorrowAction = ReturnType<TInferValueTypes<typeof actions>>;

export default function borrows(state = defaultState, action: BorrowAction): BorrowState {
    switch (action.type) {
        case GET_USER_BORROWS: {
            return {
                ...state,
                userBorrowsLoading: true,
            };
        }
        case SET_USER_BORROWS: {
            const updatedUserBorrows = action.payload.map((newBorrow) => {
                const existingBorrow = state.userBorrows.find(
                    (borrow) => borrow.address === newBorrow.address,
                );
                if (existingBorrow) {
                    return {
                        ...existingBorrow,
                        leverage: {
                            ...existingBorrow.leverage,
                            ...newBorrow.leverage,
                        },
                        opened_at: newBorrow.opened_at || existingBorrow.opened_at,
                    };
                }
                return newBorrow;
            });

            return {
                ...state,
                userBorrowsLoading: false,
                userBorrows: updatedUserBorrows,
            };
        }
        case SET_USER_BORROWS_SUSPENDED: {
            return {
                ...state,
                userBorrowsLoading: false,
                userBorrowsSuspended: action.payload,
            };
        }
        case SET_USER_BORROWS_REGISTERED: {
            return {
                ...state,
                userBorrowsLoading: false,
                userBorrowsRegistered: action.payload,
            };
        }
        case SET_USER_BORROWS_CLOSED: {
            return {
                ...state,
                userBorrowsLoading: false,
                userBorrowsClosed: action.payload,
            };
        }
        case SET_USER_BORROWS_MAINTENANCE: {
            const address = action.payload.address;

            return {
                ...state,
                userBorrows: state.userBorrows.map((item) => {
                    if (item.address === address) {
                        return {
                            ...item,
                            maintenance: action.payload,
                        };
                    }

                    return { ...item };
                }),
            };
        }
        case SET_USER_BORROWS_MAINTENANCE_HISTORY: {
            return {
                ...state,
                userBorrows: state.userBorrows.map((borrow) => {
                    const updatedHistory = action.payload.find(
                        (item) => item.data.address === borrow.address,
                    );
                    if (updatedHistory) {
                        return {
                            ...borrow,
                            history: [...(borrow.history || []), updatedHistory],
                        };
                    }
                    return borrow;
                }),
            };
        }

        default: {
            return state;
        }
    }
}
