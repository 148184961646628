import { createUseStyles } from 'react-jss';
import { color, fontSize, radius, spacing } from '@blink/components';

export const useAllowanceStyle = createUseStyles({
    root: {
        padding: spacing['05'],
        color: color.content.primary,
    },
    assetIconWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: spacing['05'],
    },
    processing: {
        border: `1px solid ${color.border.accent}`,
        borderRadius: radius.overall,
        backgroundColor: `rgba(54, 247, 211, 0.1)`,
    },
    warningMessage: {
        fontSize: fontSize.sm,
        background: `rgba(236, 152, 91, 0.2)`,
        color: color.border.warning,
        display: 'flex',
        width: '100%',
        minHeight: '55px',
        marginTop: spacing['03'],
        borderRadius: radius.overall,
        '& > svg': {
            minHeight: '55px',
            padding: `0 ${spacing['04']}`,
            width: '14px',
            height: '14px',
        },
    },
    errorMessage: {
        fontSize: fontSize.sm,
        background: `#27152D`,
        color: color.border.negative,
        display: 'flex',
        width: '100%',
        minHeight: '55px',
        marginTop: spacing['03'],
        padding: `${spacing['03']} 0`,
        borderRadius: radius.overall,
        '& > svg': {
            marginTop: '3px',
            padding: `0 ${spacing['04']}`,
            width: '14px',
            height: '14px',
            '& > path': {
                fill: color.border.negative,
            },
        },
    },
    successMessage: {
        background: color.background.alertSuccess,
        color: color.content.positive,
        display: 'flex',
        marginTop: spacing['03'],
        borderRadius: radius.overall,
        padding: spacing['04'],
        gap: spacing['03'],
        maxWidth: 439,
    },
    tryAgain: {
        marginRight: spacing['03'],
        color: color.content.primary,
        textDecoration: 'underline',
        cursor: 'pointer',
        fontSize: fontSize.sm,
    },
    warningText: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    successText: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        fontSize: fontSize.sm,
        lineHeight: '16px',
    },
    waiting: {
        border: `1px solid ${color.border.opaque}`,
        borderRadius: radius.overall,
        backgroundColor: color.background.secondary,
    },
    error: {
        border: `1px solid ${color.border.negative}`,
        borderRadius: radius.overall,
        backgroundColor: `rgba(210, 30, 80, 0.1)`,
    },
    success: {
        border: `1px solid ${color.border.accent}`,
        borderRadius: radius.overall,
        backgroundColor: `rgba(54, 247, 211, 0.1)`,
    },
    warning: {
        border: `1px solid ${color.border.warning}`,
        borderRadius: radius.overall,
        backgroundColor: `rgba(236, 152, 91, 0.1)`,
    },
    placeholder: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: fontSize.sm,
    },
});
