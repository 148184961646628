import { createUseStyles } from 'react-jss';
import { color, spacing } from '@blink/components';

export const useCollateralAssetInputStyle = createUseStyles({
    inputSection: {
        display: 'flex',
        justifyContent: 'space-between',
        background: 'rgba(54, 247, 211, 0.05)',
        borderLeft: `2px solid ${color.border.accent}`,
        padding: spacing['04'],
        alignItems: 'center',
    },
    assetImage: {
        width: 17,
        height: 17,
    },
    inputSectionRight: {
        display: 'flex',
        alignItems: 'center',
        color: color.content.secondary,
    },
    maxButton: {
        padding: `0 ${spacing['02']}`,
        color: color.textPrimary,
        height: 'fit-content',
        '&:hover': {
            color: `${color.strokeHover} !important`,
        },
    },
    submitButton: {
        padding: `0 ${spacing['02']}`,
        '&:hover': {
            color: `${color.ctaHover} !important`,
        },
    },
    inputSectionConclusion: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: spacing['04'],
        marginLeft: spacing['04'],
        borderLeft: `1px solid ${color.border.opaque}`,
        gap: spacing['02'],
    },
    secondary: {
        color: color.content.secondary,
    },
    input: {
        border: 'none',
        background: 'transparent',
        '&:hover': {
            background: color.background.secondary,
            borderInlineEndWidth: 0,
        },
        '&:focus': {
            boxShadow: 'none',
            background: color.background.secondary,
            borderInlineEndWidth: 0,
        },
        '&.error': {
            border: `1px solid ${color.danger}`,
        },
    },
    hidden: {
        display: 'none',
    },
    max: {
        '&.error': {
            color: color.danger,
        },
    },
});
