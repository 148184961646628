import { createUseStyles } from 'react-jss';
import { color, spacing } from '@blink/components';

export const useInfoCardStyle = createUseStyles({
    root: {
        padding: `${spacing['04']} ${spacing['05']}`,
        display: 'flex',
        gap: spacing['03'],
        borderRadius: 4,
        '& svg': {
            width: 20,
            height: 20,
        },
    },
    success: {
        background: color.successBg,
        color: color.successText,
        '& svg > path': {
            fill: color.successText,
        },
    },
    alert: {
        background: color.alertBg,
        color: color.background.warning,
        '& svg > path': {
            fill: color.border.warning,
        },
    },
    error: {
        background: color.errorBg,
        color: color.background.negative,
        '& svg > path': {
            fill: color.border.negative,
        },
    },
    info: {
        background: color.content.secondary15,
        color: color.content.secondary,
        '& svg > path': {
            fill: color.content.secondary,
        },
    },
});
