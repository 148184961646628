import React, { FC } from 'react';
import { AssetWithIcon, ButtonStroke } from '@blink/components';
import { useDispatch } from 'react-redux';
import { ButtonSize } from '@blink/components/src/Button/Button.types';

import { useUserSuppliesRowsStyle } from './UserSuppliesRows.style';
import { showModalAction } from '../../../store/modals/actions';
import { ModalName } from '../../../store/modals/constants';
import { UserSupply } from 'src/store/supplies';

type UserSuppliesRowsProps = {
    items: UserSupply[];
};

export const UserSuppliesRows: FC<UserSuppliesRowsProps> = ({ items }) => {
    const classes = useUserSuppliesRowsStyle();
    const dispatch = useDispatch();

    if (!items) {
        return null;
    }

    return (
        <>
            {items.map((item, index) => {
                const handleWithdraw = () => {
                    dispatch(
                        showModalAction({
                            modalName: ModalName.LIQUIDITY_MODAL,
                            modalTitle: 'Withdraw Liquidity',
                            modalProps: {
                                assetName: item.asset_symbol,
                                balance: item.balance,
                                currentApy: Number(item.apy),
                                poolCap: '10M',
                                poolCapMax: '100M',
                                rate: 1.007,
                                isWithdraw: true,
                                decimals: item.decimals,
                            },
                        }),
                    );
                };
                return (
                    <tr key={index} className={classes.tr}>
                        <td className={classes.firstTd}>
                            <AssetWithIcon assetName={item.asset_symbol || 'DAI'} />
                        </td>
                        <td>
                            <div>{item.balance}</div>
                            {/* <div className={classes.secondary}>$ {item.balance.value_in_usdt}</div> */}
                        </td>
                        <td>{item.apy}%</td>
                        <td>
                            <div className={classes.rewards}>
                                <div>
                                    {item.reward} {item.asset_symbol}
                                </div>
                            </div>
                        </td>
                        <td className={classes.buttonTd}>
                            <ButtonStroke
                                size={ButtonSize.md}
                                className={classes.button}
                                isSecondary={true}
                                onClick={handleWithdraw}
                            >
                                Withdraw
                            </ButtonStroke>
                        </td>
                    </tr>
                );
            })}
        </>
    );
};
