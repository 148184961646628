import { createUseStyles } from 'react-jss';

import { color, fontSize, spacing } from '../variables';

export const useConnectWalletStyles = createUseStyles({
    button: {
        height: '100%',
        width: '100%',
        color: color.content.primary,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row-reverse',
        padding: `${spacing['04']} ${spacing['03']} ${spacing['04']} ${spacing['04']}`,
        fontSize: `${fontSize.md} !important`,
        borderColor: color.border.opaque,
        '& svg': {
            marginLeft: spacing['04'],
            fill: color.border.selected,
        },
        '&:hover svg': {
            fill: color.primary,
        },
    },
});
