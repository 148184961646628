export const ADD_WALLETS = 'ADD_WALLETS';
export const ADD_ACTIVE_WALLET = 'ADD_ACTIVE_WALLET';
export const REMOVE_WALLETS = 'REMOVE_WALLETS';
export const ADD_INSTALLED_WALLETS = 'ADD_INSTALLED_WALLETS';
export const ADD_NETWORK = 'ADD_NETWORK';

export const CHANGE_NETWORK_SAGA = 'CHANGE_NETWORK_SAGA';
export const ADD_WALLETS_SAGA = 'ADD_META_MASK_WALLETS_SAGA';
export const ADD_WALLET_CONNECT_SAGA = 'ADD_WALLET_CONNECT_SAGA';
export const RECONNECT_IF_WALLET_AVAILABLE_SAGA = 'RECONNECT_IF_WALLET_AVAILABLE_SAGA';
export const DISCONNECT_WALLET_SAGA = 'DISCONNECT_WALLET_SAGA';
export const DISCONNECT_WALLET_CONNECT_SAGA = 'DISCONNECT_WALLET_CONNECT_SAGA';

export const ADD_WALLET_CONNECT = 'ADD_WALLET_CONNECT';
export const SET_WALLET_CONNECT_PAIRING_PROCESSING = 'SET_WALLET_CONNECT_PAIRING_PROCESSING';
