import { ThemeConfig } from 'antd/es/config-provider/context';

// variables

export const spacing = {
    '01': '2px',
    '02': '4px',
    '03': '8px',
    '04': '12px',
    '05': '16px',
    '06': '20px',
    '07': '24px',
    '08': '32px',
    '09': '40px',
    '10': '48px',
    '11': '64px',
    '12': '80px',
    '13': '96px',
    '14': '120px',
};

const radiusBase = 2;
export const radius = {
    overall: '4px',
    xs: `${radiusBase}px`, // 2
    md: `${radiusBase * 3}px`, // 6
    lg: `${radiusBase * 4}px`, // 8
};

//typography
export const fontSize = {
    xs: '10px',
    sm: '12px',
    md: '14px',
    lg: '17px',
    xl: '21px',
    xxl: '24px',
};

export const fontWeight = {
    light: 300,
    regular: 400,
    medium: 500,
    bold: 600,
};

export const lineHeight = {
    xs: 1.2,
    sm: 1.4,
    md: 1.5,
    lg: 1.6,
};

//colors
export const color = {
    background: {
        primary: '#081327',
        secondary: '#101A2F',
        tertiary: '#495170',
        quaternary: '#333D5A',
        disabled: 'rgba(0, 0, 0, 0.15)',
        accent: '#36F7D3',
        accent1: '#2990FF',
        positive: '#36C9A0',
        warning: '#EC985B',
        negative: '#D21E50',
        lightAccent: 'rgba(54, 247, 211, 0.20)',
        lightAccent1: '#D4E9FF',
        lightPositive: '#D7F4EC',
        lightWarning: '#FBEADE',
        lightNegative: '#F6D2DC',
        alwaysDark: '#000',
        alwaysLight: '#FFF',
        alertSuccess: '#15303C',
    },
    border: {
        opaque: '#576085',
        tranparent: 'rgba(87, 96, 133, 0.40)',
        selected: '#FFF',
        inverseOpaque: '#081327',
        disable: 'rgba(116, 129, 164, 0.10)',
        accent: '#36F7D3',
        accentTransparent: '#rgba(54, 247, 211, 0.50)',
        accentMuted: '#0C2635',
        accent1: '#2990FF',
        positive: '#36C9A0',
        warning: '#EC985B',
        negative: '#D21E50',
        lightAccent: '#D7FDF6',
    },
    content: {
        primary: '#FFF',
        secondary: '#7481A4',
        tertiary: '#36F7D3',
        disable: 'rgba(116, 129, 164, 0.40)',
        inversePrimary: '#081327',
        colorDisable: 'rgba(255, 255, 255, 0.40)',
        onColor: '#FFF',
        onColorInverse: '#000',
        accent: '#36F7D3',
        accent1: '#2990FF',
        overlayLight: 'rgba(0, 0, 0, 0.30)',
        overlayDark: 'rgba(0, 0, 0, 0.15)',
        overlayDark1: 'rgba(116, 129, 164, 0.05)',
        overlayDark2: 'rgba(116, 129, 164, 0.10)',
        overlayDark3: 'rgba(116, 129, 164, 0.40)',
        overlayAccent: 'rgba(54, 247, 211, 0.05)',
        hoverDarkOverlay1: 'rgba(255, 255, 255, 0.10)',
        hoverDarkOverlay2: 'rgba(0, 0, 0, 0.10)',
        hoverDarkOverlay3: 'rgba(116, 129, 164, 0.10)',
        pressedLightOverlay1: 'rgba(255, 255, 255, 0.20)',
        pressedLightOverlay2: 'rgba(0, 0, 0, 0.20)',
        pressedLightOverlay3: 'rgba(116, 129, 164, 0.20)',
        secondary15: 'rgba(116, 129, 164, 0.15)',
        positive: '#36C9A0',
    },
    primary: '#00F0C1',
    border40: '#57608566',
    danger: '#F7365A',
    chartLine: '#667DF0',
    chartLine2: '#4732D4',
    strokeHover: '#E3E0DE',
    strokeActive: '#D0CBC8',
    textPrimary: '#F9FAFB',
    hoverSecondary: 'rgba(116, 129, 164, 0.1)',
    activeSecondary: '#E7E7E9',
    textTertiaryHover: '#071123',
    textTertiaryActive: '#060F1F',
    ctaHover: '#16E3BD',
    ctaActive: '#12B597',
    disabledText: 'rgba(116, 129, 164, 0.3)',
    successBg: 'rgba(96, 212, 180, 0.1)',
    successText: '#60D4B4',
    alertBg: 'rgba(236, 152, 91, 0.15)',
    errorBg: 'rgba(210, 30, 80, 0.15)',
    paletteSurfaceForeground: '#333D5A',
    overlay: 'rgba(255, 255, 255, 0.1)',
};

// -------- Theme Ant Variables Start --------
const themeAntToken: ThemeConfig = {
    token: {
        fontSize: 14,
        fontSizeLG: 17,
        fontSizeXL: 20,
        colorPrimary: color.primary,
        colorBgLayout: color.background.primary,
        fontFamily: 'Inter',
        colorText: color.content.primary,
        colorBgElevated: color.background.secondary,
        colorBgContainer: color.background.secondary,
        colorTextQuaternary: color.content.primary,
        colorPrimaryBg: 'none',
    },
};

const themeAntComponents: ThemeConfig = {
    components: {
        Tabs: {
            colorPrimary: color.content.primary,
            fontSize: themeAntToken.token?.fontSizeLG,
            colorText: color.content.secondary,
        },
        Dropdown: {
            paddingXS: 0,
            paddingXXS: 0,
            marginXXS: 0,
            marginXS: 0,
            controlPaddingHorizontal: 0,
        },
        Menu: {
            fontSize: themeAntToken.token?.fontSizeLG,
        },
        Input: {
            colorBorder: color.border.opaque,
            borderRadius: radiusBase * 2, // 4
            fontSize: 14,
            fontSizeLG: 17,
            lineHeight: 1.4,
            lineHeightLG: 1.4,
            colorTextPlaceholder: color.content.secondary,
        },
        Steps: {
            navArrowColor: color.content.secondary,
            iconSize: 24,
        },
    },
};

export const themeAnt: ThemeConfig = {
    ...themeAntComponents,
    ...themeAntToken,
};

// -------- Theme Ant Variables End --------
