import React, { useState } from 'react';
import { Input, InputNumberProps } from 'antd';
import { TextButton } from '@blink/components';
import { parseUnits } from 'ethers';

import { useInputStyles } from './Input.style';

type InputNumberCustomProps = InputNumberProps & {
    handleChange: (value: any) => void;
    max?: string;
    isMax?: boolean;
    handleMaxClick: () => void;
    btnLink?: boolean;
    disabled?: boolean;
    className?: string;
    decimals: number;
};

export const InputNumberCustom: React.FC<InputNumberCustomProps> = ({
    value,
    placeholder,
    handleChange,
    max = '',
    handleMaxClick,
    btnLink = false,
    disabled,
    className,
    decimals,
}) => {
    const classes = useInputStyles();
    const [inputValue, setInputValue] = useState(value || '');
    const [inputPlaceholder, setInputPlaceholder] = useState(placeholder);

    const handleMax = () => {
        max && setInputValue(max);
        handleMaxClick();
    };

    const handleBlur = () => {
        setInputPlaceholder(placeholder);
    };

    const handleFocus = () => {
        setInputPlaceholder('');
    };

    const handleOnChange = (e: { target: { value: string } }) => {
        const inputValue = e.target.value.trim();
        if (/^(0|[1-9]\d*)(\.\d{0,18})?$/.test(inputValue)) {
            try {
                parseUnits(e.target.value.trim(), decimals);
            } catch (e) {
                return;
            }

            setInputValue(inputValue);
            handleChange(inputValue);
        }

        if (inputValue === '') {
            setInputValue('');
            handleChange(inputValue);
            return;
        }
    };

    return (
        <div className={classes.inputWrapper}>
            <Input
                autoFocus
                type='text'
                value={inputValue}
                onChange={handleOnChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder={inputPlaceholder}
                disabled={disabled}
                className={className}
            />
            <TextButton
                className={btnLink ? classes.maxButtonLink : classes.maxButton}
                onClick={handleMax}
            >
                Max
            </TextButton>
        </div>
    );
};
