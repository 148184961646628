import { createUseStyles } from 'react-jss';

import { radius, color, fontSize, spacing } from '../variables';

export const useBtnStyles = createUseStyles({
    btn: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '124px',
        height: '44px',
        padding: spacing['04'],
        borderRadius: radius.overall,
        border: `1px solid ${color.border.selected}`,
        color: color.content.primary,
        '& .ant-float-btn-description': {
            fontSize: `${fontSize.md} !important`,
        },
        '& .ant-float-btn-icon': {
            width: '12px !important',
            margin: '0px !important',
            paddingBottom: spacing['02'],
        },
        '& .ant-float-btn-content': {
            justifyContent: 'space-between !important',
            flexDirection: 'row-reverse !important',
            padding: '0px !important',
            width: '100%',

            '& svg': {
                width: '11px',
                height: '6px',
                transform: 'rotate(180deg)',
            },
            '& path': {
                fill: color.border.selected,
            },
        },
    },
});
