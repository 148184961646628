import React, { FC } from 'react';
import { TooltipProps } from 'recharts/types/component/Tooltip';
import dayjs from 'dayjs';
import {
    CartesianGrid,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { color } from '@blink/components';

import {
    axisTickStyles,
    tooltipWrapperStyles,
    useAssetToSupplyChartStyle,
} from './AssetToSupplyChart.style';

type AssetToSupplyChartProps = {
    chartData: any[];
    dataKey: string;
    title: string;
};

const TICK_MARGIN = 20;
const MIN_TICK_GAP = 100;
const STROKE_WIDTH = 2;
const TOOLTIP_POSITION = { x: 45, y: -100 };

const timestampTickFormatter = (value: any) => {
    return dayjs(value * 1000).format('MMM DD');
};

export const AssetToSupplyChart: FC<AssetToSupplyChartProps> = ({ chartData, dataKey, title }) => {
    const classes = useAssetToSupplyChartStyle();

    const renderTooltip = (props: TooltipProps<any, any>) => {
        const entry = !props.payload?.length
            ? chartData[chartData.length - 1]
            : props.payload[0].payload;
        const value = Number(entry[dataKey]) || 0;
        const timestamp = dayjs(entry.timestamp * 1000).format('D MMM YYYY');

        return (
            <div>
                <div className={classes.tooltipTitle}>{title}</div>
                <div className={classes.tooltipValue}>{value.toFixed(2)}%</div>
                <div className={classes.timestamp}>{timestamp}</div>
            </div>
        );
    };

    return (
        <div className={classes.wrapper}>
            <ResponsiveContainer width='99%' height='99%'>
                <LineChart data={chartData}>
                    <CartesianGrid vertical={false} strokeDasharray='2' />
                    <XAxis
                        dataKey='timestamp'
                        tickFormatter={timestampTickFormatter}
                        minTickGap={MIN_TICK_GAP}
                        tick={axisTickStyles}
                        tickMargin={TICK_MARGIN}
                    />
                    <YAxis tick={axisTickStyles} />
                    <Tooltip
                        active={true}
                        wrapperStyle={tooltipWrapperStyles}
                        content={renderTooltip}
                        position={TOOLTIP_POSITION}
                    />
                    <Line
                        type='monotone'
                        dataKey={dataKey}
                        stroke={color.primary}
                        strokeWidth={STROKE_WIDTH}
                        dot={false}
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};
