import React, { useEffect, useState } from 'react';
import { Api, AssetWithIcon, ButtonStroke } from '@blink/components';
import { ReactComponent as InfoIcon } from '@blink/assets/src/icons/info.svg';
import { AbstractWallet, WalletHelper } from '@blink/components/src/utils';
import { walletMap } from '@blink/components/src/constants/wallet';
import { Contract, ethers, formatUnits } from 'ethers';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonSize } from '@blink/components/src/Button/Button.types';
import { message } from 'antd';

import { useModalStyles } from './FundClaimModal.style';
import { useHideModal, useModal } from '../ModalWrapperHooks';
import { Store } from '../../../store';
import FundRegistryABI from '../../../abi/FundRegistryABI.json';
import PoolRegistryAbi from '../../../abi/PoolRegistryAbi.json';
import FundABI from '../../../abi/FundABI.json';
import { getFundAction } from '../../../store/supplies/actions';

export const FundClaimModal = () => {
    const classes = useModalStyles();
    const { type, blockchainName } = useSelector((state: Store) => ({
        type: state.wallets.active.type,
        blockchainName: state.wallets.network,
    }));
    const dispatch = useDispatch();
    const [fundContract, setFundContract] = useState<Contract>();
    const [loading, setLoading] = useState<boolean>(false);
    const { modalProps } = useModal();
    const { assetName, balance = '0', address, decimals } = modalProps || {};
    const { handleCloseModal } = useHideModal();

    useEffect(() => {
        (async () => {
            if (!type || !blockchainName) {
                return;
            }
            const wallet: AbstractWallet = walletMap.get(type) as AbstractWallet;
            const walletProvider = await wallet.getProvider();

            const provider = new ethers.BrowserProvider(
                walletProvider,
                WalletHelper.getNetworkNumber(blockchainName),
            );
            const signer = await provider.getSigner();
            const fundRegistry = new Contract(
                Api.INSURANCE_FUND_ADDRESS as string,
                FundRegistryABI,
                signer,
            );
            const liquidityPool = new Contract(
                Api.LIQUIDITY_POOL_ADDRESS as string,
                PoolRegistryAbi,
                signer,
            );
            const poolAddress = await liquidityPool.getPool(address);
            const fundAddress = await fundRegistry.getFund(poolAddress);
            const fund = new Contract(fundAddress, FundABI, signer);
            setFundContract(fund);
        })();
    }, [type, blockchainName]);

    const onWithdrawHandler = async () => {
        if (!fundContract) {
            return;
        }
        try {
            setLoading(true);
            await fundContract.withdrawAll();
            handleCloseModal();
            message.success(
                `Total withdrawal amount ${assetName} ${formatUnits(balance.toString(), decimals)}`,
            );
            dispatch(getFundAction());
        } catch (e) {
            console.warn(e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <div className={classes.header}>
                    <div className={classes.title}>
                        <InfoIcon className={classes.amountIcon} /> Amount to withdraw
                    </div>
                </div>
                <div className={classes.footer}>
                    <div>
                        <AssetWithIcon
                            assetName={assetName || ''}
                            classes={{ assetName: classes.assetName }}
                        />
                    </div>
                    <div className={classes.input}>{balance}</div>
                </div>
            </div>
            <div className={classes.container}>
                <div className={classes.estimation}>Estimation time to Arrival</div>
                <div>1 minute</div>
            </div>
            <div className={classes.wrapperAccount}>
                <div>
                    <InfoIcon className={classes.iconInfo} />
                </div>
                <div>
                    Your accounts have been liquidated, but we have a bonus for you, as we conduct
                    liquidation with a small gap that you can claim.{' '}
                </div>
            </div>
            <div className={classes.line}></div>
            <ButtonStroke
                size={ButtonSize.md}
                className={classes.submitButton}
                isSecondary={true}
                disabled={loading}
                loading={loading}
                onClick={onWithdrawHandler}
            >
                Withdraw
            </ButtonStroke>
        </div>
    );
};
