import React from 'react';
import { Skeleton } from 'antd';

import { useSkeletonRowtyles } from './SkeletonRow.style';

export const SkeletonRow: React.FC = () => {
    const classes = useSkeletonRowtyles();
    return (
        <tr className={classes.skeleton}>
            <td className={classes.firstTd}>
                <div className={classes.horizontal}>
                    <Skeleton.Avatar active />
                    <div className={classes.vertical}>
                        <Skeleton.Input active />
                        <Skeleton.Input className={classes.small} active />
                    </div>
                </div>
            </td>
            <td>
                <div className={classes.vertical}>
                    <Skeleton.Input active />
                    <Skeleton.Input className={classes.small} active />
                </div>
            </td>
            <td>
                <div className={classes.vertical}>
                    <Skeleton.Input active />
                    <Skeleton.Input className={classes.small} active />
                </div>
            </td>
            <td>
                <div className={classes.vertical}>
                    <Skeleton.Input active />
                    <Skeleton.Input className={classes.small} active />
                </div>
            </td>
            <td>
                <div className={classes.buttonWrapper}>
                    <Skeleton.Button className={classes.btn} active />
                </div>
            </td>
        </tr>
    );
};
