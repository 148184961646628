import React, { useEffect, useState } from 'react';
import { Api, AssetWithIcon } from '@blink/components';
import { Contract, ethers, formatUnits, parseUnits } from 'ethers';
import { ALLOWANCE_STATUS } from '@blink/components/src/types';
import { ReactComponent as CautionIcon } from '@blink/assets/src/icons/cautions.svg';
import { ReactComponent as SuccessIcon } from '@blink/assets/src/icons/checkCircle.svg';

import { useAllowanceStyle } from './Allowance.style';
import { Status } from '../Status';
import { AllowanceStep } from './AllowanceStep/AllowanceStep';
import IERCAbi from '../../abi/IERCAbi.json';
import { usePrevious } from '../../hooks/usePrevious';

type AllowanceType = {
    status: ALLOWANCE_STATUS;
    requiredAmount: string;
    asset: string;
    assetAddress: string;
    changeStatus: (status: ALLOWANCE_STATUS) => void;
    signer: ethers.JsonRpcSigner;
    lastItem?: boolean;
};

const RESET_ALLOWANCE_ERROR_MESSAGE = 'execution reverted';

export const assetsToResetAllowance = new Set().add('USDT');

export const Allowance = ({
    asset,
    status,
    requiredAmount,
    assetAddress,
    changeStatus,
    signer,
    lastItem,
}: AllowanceType) => {
    const baseClasses = useAllowanceStyle();
    const statusClass = (baseClasses as any)[status];
    const [errorAllowance, setErrorAllowance] = useState(
        assetsToResetAllowance.has(asset) || false,
    );
    const [contractException, setContractException] = useState(false);
    const [currentAllowance, setCurrentAllowance] = useState('');

    const [currentAllowanceLoading, setCurrentAllowanceLoading] = useState(true);
    const [resetAllowanceLoading, setResetAllowanceLoading] = useState(false);
    const [tryAgain, setTryAgain] = useState<boolean>(true);
    const isWaitingStatus = status === ALLOWANCE_STATUS.WAITING;
    const prevStatus = usePrevious(status);
    const isPrevStatusProcessing = prevStatus === ALLOWANCE_STATUS.PROCESSING;
    const isStatusChangedToSkip =
        isPrevStatusProcessing &&
        (status === ALLOWANCE_STATUS.WARNING || status === ALLOWANCE_STATUS.ERROR);

    useEffect(() => {
        (async () => {
            if (!status || isWaitingStatus || isStatusChangedToSkip) {
                return;
            }
            const token = new Contract(assetAddress, IERCAbi, signer);
            const userAllowanceBalance = await token.allowance(
                signer.address,
                Api.MARGIN_ACCOUNT_ADDRESS,
            );
            const decimal = await token.decimals();
            const amount = parseUnits(requiredAmount.toString(), decimal);

            const readableUserAllowance = formatUnits(userAllowanceBalance, decimal);
            setCurrentAllowance(readableUserAllowance);
            const isResetRequired =
                Number(readableUserAllowance) !== 0 &&
                Number(readableUserAllowance) < Number(requiredAmount);
            const isAllowanceNeedToReset = errorAllowance && isResetRequired;

            /** Check is need to reset allowance to 0 */
            if (isAllowanceNeedToReset) {
                try {
                    setResetAllowanceLoading(true);
                    const approveToken = await token.approve(Api.MARGIN_ACCOUNT_ADDRESS, 0);
                    await approveToken.wait();
                    setResetAllowanceLoading(false);
                } catch (e: any) {
                    console.warn(e);
                    if (e?.message?.toLowerCase().includes(RESET_ALLOWANCE_ERROR_MESSAGE)) {
                        setErrorAllowance(true);
                        setTryAgainHandler();
                        return;
                    }

                    if (e?.code === 'ACTION_REJECTED') {
                        setErrorAllowance(true);
                        changeStatus(ALLOWANCE_STATUS.WARNING);
                        console.warn('Allowance warning! User Reject to reset allowance!');
                        return;
                    }
                }
            }

            /** Check is user allowance more than required to make transaction */
            if (Number(readableUserAllowance) >= Number(requiredAmount)) {
                setCurrentAllowanceLoading(false);
                changeStatus(ALLOWANCE_STATUS.SUCCESS);
                return;
            }

            /** Make a new allowance for a user */
            try {
                const approveToken = await token.approve(Api.MARGIN_ACCOUNT_ADDRESS, amount);
                await approveToken.wait();
                const newUserAllowance = await token.allowance(
                    signer.address,
                    Api.MARGIN_ACCOUNT_ADDRESS,
                );
                const readableNewUserAllowance = formatUnits(newUserAllowance, decimal);
                if (Number(readableNewUserAllowance) < Number(requiredAmount)) {
                    setTryAgainHandler();
                    setCurrentAllowanceLoading(true);
                    return;
                }
                setCurrentAllowanceLoading(false);
                changeStatus(ALLOWANCE_STATUS.SUCCESS);
            } catch (e: any) {
                console.warn(e);
                if (e?.message?.toLowerCase().includes(RESET_ALLOWANCE_ERROR_MESSAGE)) {
                    setErrorAllowance(true);
                    return;
                }
                if (e?.code === 'ACTION_REJECTED') {
                    changeStatus(ALLOWANCE_STATUS.WARNING);
                    console.warn('Allowance warning! Trying to reset allowance to 0!');
                    return;
                }
                setContractException(true);
                changeStatus(ALLOWANCE_STATUS.ERROR);
            }
        })();
    }, [errorAllowance, tryAgain, status]);

    const resetYourBalance = () => {
        const newStatus = !resetAllowanceLoading ? ALLOWANCE_STATUS.SUCCESS : status;
        return errorAllowance &&
            !contractException &&
            Number(currentAllowance) !== 0 &&
            Number(currentAllowance) < Number(requiredAmount) ? (
            <AllowanceStep
                amount='0'
                title='Reset your current allowance'
                isLoading={resetAllowanceLoading}
                status={newStatus}
            />
        ) : null;
    };

    const setTryAgainHandler = () => {
        changeStatus(ALLOWANCE_STATUS.PROCESSING);
        setTryAgain((prevState) => !prevState);
    };

    const warningMessage = () => {
        return status === ALLOWANCE_STATUS.WARNING ? (
            <div className={baseClasses.warningMessage}>
                <CautionIcon />
                <div className={baseClasses.warningText}>
                    <span>Transaction was rejected by user.</span>
                    <div className={baseClasses.tryAgain} onClick={setTryAgainHandler}>
                        Try Again
                    </div>
                </div>
            </div>
        ) : null;
    };

    const successMessage = () => {
        return status === ALLOWANCE_STATUS.SUCCESS && lastItem ? (
            <div className={baseClasses.successMessage}>
                <SuccessIcon />
                <div className={baseClasses.successText}>
                    <span>
                        Great job! Your previous allowance has been reset and a new allowance has
                        been set! Now gear up, the next one is about to kick off!
                    </span>
                </div>
            </div>
        ) : null;
    };

    const getCurrentAllowance = (): string => {
        return currentAllowance === '' ? '' : currentAllowance;
    };

    return (
        <div>
            <main className={`${baseClasses.root} ${statusClass}`}>
                <header className={baseClasses.assetIconWrapper}>
                    <AssetWithIcon assetName={asset} />
                    <Status status={status} />
                </header>
                {status === ALLOWANCE_STATUS.WAITING ? (
                    <div className={baseClasses.placeholder}>
                        <span>Allowance</span>
                        <span>0</span>
                    </div>
                ) : (
                    <>
                        <AllowanceStep
                            amount={getCurrentAllowance()}
                            title='Your current allowance'
                            isLoading={setCurrentAllowance === null}
                            status={status}
                            border
                        />
                        {resetYourBalance()}
                        <AllowanceStep
                            amount={requiredAmount}
                            title='Required allowance'
                            isLoading={currentAllowanceLoading}
                            status={status}
                        />
                    </>
                )}
            </main>
            {warningMessage()}
            {successMessage()}
        </div>
    );
};
