import { createUseStyles } from 'react-jss';
import { color, spacing } from '@blink/components';

export const useCollateralAssetCardStyle = createUseStyles({
    root: {
        color: color.textPrimary,
        padding: `${spacing['04']} ${spacing['05']}`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 'max-content',
        width: '100%',
        '&:first-of-type': {
            marginTop: 0,
            borderRadius: 4,
        },
        '&:last-of-type': {
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
        },
        '&:hover': {
            '& > button': {
                display: 'revert',
            },
        },
    },
    amountWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: spacing['04'],
    },
    clearButton: {
        display: 'none',
    },
});
