import React from 'react';
import { AssetWithIcon, ButtonStroke } from '@blink/components';
import { ButtonSize } from '@blink/components/src/Button/Button.types';
import { useDispatch, useSelector } from 'react-redux';
import { formatUnits } from 'ethers';

import { useAssetsToSupplyRowsStyle } from '../../AssetsToSupply/AssetsToSupplyItemRow/AssetsToSupplyItemRow.style';
import { Store } from '../../../store';
import { showModalAction } from '../../../store/modals/actions';
import { ModalName } from '../../../store/modals/constants';

export type AssetToClaimProps = {
    assetName: string;
};

export const AssetToClaim = ({ assetName }: AssetToClaimProps) => {
    const classesItem = useAssetsToSupplyRowsStyle();
    const dispatch = useDispatch();
    const {
        balance = 0,
        address,
        assets,
    } = useSelector((state: Store) => ({
        balance: state?.supplies?.fund?.balances[assetName],
        address: state?.supplies?.fund?.addresses[assetName],
        assets: state.supplies.assetsToSupply,
    }));

    const decimals = assets.find((asset) => asset.address === address)!.decimals;

    const onWithdrawHandler = () => {
        dispatch(
            showModalAction({
                modalName: ModalName.FUND_CLAIM_MODAL,
                modalTitle: 'Withdraw Recovered Balance',
                modalProps: {
                    assetName,
                    balance: formatUnits(balance.toString(), decimals),
                    address,
                    decimals,
                },
            }),
        );
    };

    return balance > 0 ? (
        <>
            <tr className={`${classesItem.tr}`}>
                <td className={classesItem.firstTd}>
                    <AssetWithIcon assetName={assetName} />
                </td>
                <td>
                    <div className={classesItem.rewardAmount}>
                        <div>{formatUnits(balance.toString(), decimals)}</div>
                    </div>
                </td>
                <td>
                    <div className={classesItem.buttonWrapper}>
                        <ButtonStroke
                            isOutline
                            size={ButtonSize.md}
                            className={classesItem.withdrawButton}
                            onClick={onWithdrawHandler}
                            disabled={false}
                        >
                            Withdraw
                        </ButtonStroke>
                    </div>
                </td>
            </tr>
        </>
    ) : null;
};
