import { createUseStyles } from 'react-jss';
import { color, radius, spacing } from '@blink/components';

export const useWalletManagerStyles = createUseStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        background: color.background.secondary,
        borderRadius: radius.overall,
        border: `1px solid transparent`,
        height: 44,
        boxShadow: 'none !important',
        '&:hover': {
            background: `${color.border.disable} !important`,
            border: `1px solid ${color.border.disable}`,
        },
    },
    text: {
        marginLeft: spacing['03'],
        color: color.content.primary,
    },
});
