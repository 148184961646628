import React, { FC, useState } from 'react';

import { Layout } from '../../components/Layout/Layout';
import { GuideInfo } from '../../components/GuideInfo/GuideInfo';
import { LeverageScreenButtonGroup } from '../../components/Supply/Supply';
import { FixedRateMenu } from '../../components/FixedRateMenu/FixedRateMenu';
import { UserSuppliesSection } from '../../components/UserSupplies';
import { AssetsToSupplySection, LeverageMode } from '../../components/AssetsToSupply';
import { Faqs } from '../../components/Faqs/Faqs';
import { FixedRateMenuKey } from '../../components/FixedRateMenu/constants';

export const Leverage: FC = () => {
    const [selectedRate, setSelectedRate] = useState(FixedRateMenuKey.FIXED_RATE);
    return (
        <div>
            <Layout isBorder>
                <GuideInfo />
                <LeverageScreenButtonGroup />
                <FixedRateMenu setSelected={setSelectedRate} selected={selectedRate} />
            </Layout>
            <Layout>
                <UserSuppliesSection />
                <AssetsToSupplySection mode={LeverageMode.Supply} selectedRate={selectedRate} />
            </Layout>
            <Faqs />
        </div>
    );
};
