import { createUseStyles } from 'react-jss';
import { color, fontSize, radius, spacing } from '@blink/components';

export const useWalletInfoModalStyle = createUseStyles({
    root: {
        background: color.background.secondary,
        borderRadius: radius.overall,
        border: `1px solid ${color.border40}`,
        marginTop: spacing['07'],
        padding: `${spacing['06']} ${spacing['05']} ${spacing['04']}`,
        width: 398,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    walletNameWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    walletName: {
        marginLeft: spacing['04'],
        fontSize: fontSize.md,
    },
    buttonWrapper: {
        display: 'flex',
        gap: spacing['03'],
        '& > button': {
            padding: `${spacing['03']} ${spacing['04']}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    button: {
        width: 87,
    },
    buttonPrimary: {
        background: color.background.alwaysLight,
        color: color.background.primary,
        marginRight: spacing['03'],
    },
    address: {
        color: color.content.secondary,
        marginTop: spacing['02'],
        paddingBottom: spacing['04'],
        borderBottom: `1px solid ${color.border40}`,
    },
    textButton: {
        color: `${color.content.secondary} !important`,
        width: 'fit-content',
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        gap: spacing['03'],
        height: 44,
        transition: 'none',
        border: 'none',
        '& path': {
            fill: color.content.secondary,
        },
        '&:hover': {
            color: `${color.content.primary} !important`,
            backgroundColor: 'transparent !important',
            '& path': {
                fill: color.border.selected,
            },
        },
    },
    fillBtn: {
        border: 'none',
    },
    footer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 10,
        gap: spacing['07'],
    },
    walletConnectWrapper: {
        paddingTop: spacing['04'],
        paddingBottom: spacing['04'],
        borderBottom: `1px solid ${color.border.opaque}`,
    },
    walletConnectInputWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    walletConnectInput: {
        flex: 1,
    },
    walletConnectButton: {
        display: 'flex',
        alignItems: 'center',
        marginRight: spacing['07'],
    },
    walletConnectLogo: {
        width: 27,
        height: 27,
        marginLeft: spacing['02'],
    },
    walletConnectSubmitButtonWrapper: {
        width: 100,
        display: 'flex',
        alignItems: 'center',
    },
    walletConnectButtonWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    walletConnectSubmitButton: {
        color: color.textPrimary,
    },
});
