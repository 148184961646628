import React, { useEffect, useState } from 'react';
import { Header } from 'antd/es/layout/layout';
import { Link, useLocation } from 'react-router-dom';
import { LOCAL_STORAGE, LocalStorage } from '@blink/components/src/utils';
import { Banner } from '@blink/components/src/Banner';
import { ReactComponent as AlertIcon } from '@blink/assets/src/icons/alert.svg';

import { ReactComponent as LogoIcon } from '../../assets/logo.svg';
import { useManuHeaderStyle } from './MenuHeader.style';
import { ModalWrapper } from '../Modals';
import { ConnectWallet } from '../ConnectWallet/ConnectWallet';
import { WalletConnectStatus } from '../WalletConnect/WalletConnectStatus';
import { useWalletConnect } from '../../hooks/useWalletConnect';
import { ThemeSwitch } from '../Theme/ThemeSwitch';

export const MenuHeader = () => {
    const classes = useManuHeaderStyle();
    const location = useLocation();
    const [showBanner, setShowBanner] = useState<boolean>(false);

    const walletConnectUri = LocalStorage.getItem(LOCAL_STORAGE.WALLET_CONNECT_URI) as string;
    const walletConnectMarginAddress =
        LocalStorage.getItem(LOCAL_STORAGE.WALLET_CONNECT_MARGIN_ADDRESS) || '';

    const walletConnectProps = useWalletConnect({
        marginAccountAddress: walletConnectMarginAddress,
        showErrorMessage: false,
    });

    useEffect(() => {
        if (!walletConnectProps.handlePair || !walletConnectUri) {
            return;
        }
        (async () => {
            try {
                await walletConnectProps.handlePair(walletConnectUri);
            } catch (e) {
                console.warn('WC:connection_lost');
                LocalStorage.setItem(LOCAL_STORAGE.WALLET_CONNECT_URI, '');
                LocalStorage.setItem(LOCAL_STORAGE.WALLET_CONNECT_MARGIN_ADDRESS, '');
            }
        })();
    }, []);

    const disconnectWallet = () => {
        const topic = LocalStorage.getItem(LOCAL_STORAGE.WALLET_CONNECT_TOPIC) as string;
        if (topic) {
            walletConnectProps.disconnect(topic);
        }
    };

    if (location.pathname.includes('wallet-connect')) {
        return null;
    }

    const handleSetBannerState = (value: boolean) => {
        setShowBanner(value);
    };

    return (
        <>
            {showBanner && (
                <Banner
                    icon={<AlertIcon />}
                    message='Arkis is not supported on this network.'
                    description='Please switch to Ethereum'
                />
            )}
            <Header className={classes.root}>
                <div className={classes.left}>
                    <Link to='/' className={classes.logoLink}>
                        <LogoIcon className={classes.logo} />
                    </Link>
                </div>
                <div className={classes.right}>
                    <ThemeSwitch />
                    <WalletConnectStatus disconnect={disconnectWallet} />
                    <ConnectWallet handleSetBannerState={handleSetBannerState} />
                </div>
                <ModalWrapper />
            </Header>
        </>
    );
};
