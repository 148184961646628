import { EthereumProvider } from '@walletconnect/ethereum-provider';

import { EthereumWallet } from './ethereumWallet';
import { WalletAddress } from '../../types';
import { NETWORK, NETWORK_CHAIN } from '../../constants/global';
import { SendTransactionProps } from './types';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
type Provider = EthereumProvider;

/** Wallet Connect class to provide all realisation of abstract wallet methods */
export class WalletConnect extends EthereumWallet {
    private provider: Provider;
    private readonly PROJECT_ID = process.env.REACT_APP_PROJECT_ID as string;
    constructor() {
        super();
        this.getProvider();
    }

    public getProjectId(): string {
        return this.PROJECT_ID;
    }

    public getProjectMetaData() {
        return {
            name: 'Arkis',
            description: 'Arkis Web3Wallet for WalletConnect',
            url: process.env.REACT_APP_HOME_URL as string,
            icons: [`${process.env.REACT_APP_HOME_URL}/logo192.svg` as string],
        };
    }

    public isAvailable(): boolean {
        return true;
    }

    public isWalletInProvider(): boolean {
        return false;
    }

    public getRpcMap(): any {
        return process.env.REACT_APP_RPC_ETHEREUM
            ? { [NETWORK_CHAIN.ETHEREUM_NETWORK]: process.env.REACT_APP_RPC_ETHEREUM }
            : {};
    }

    public async getProvider(): Provider {
        if (this.provider) {
            return this.provider;
        }
        this.provider = await EthereumProvider.init({
            projectId: this.getProjectId(),
            metadata: this.getProjectMetaData(),
            showQrModal: true,
            optionalChains: [Number(NETWORK_CHAIN.ETHEREUM_NETWORK)],
            rpcMap: this.getRpcMap(),
        });
        return this.provider;
    }

    public async changeNetwork(network: NETWORK): Promise<void> {
        await super.changeNetwork(network);
    }

    public async connect(): Promise<WalletAddress[]> {
        const isConnected = await this.isConnected();
        const provider = await this.getProvider();
        if (isConnected && provider.accounts && provider.accounts?.length) {
            return provider.accounts;
        }
        await provider.connect();
        return provider.accounts;
    }

    public async disconnect(): Promise<void> {
        const provider = await this.getProvider();
        await provider.disconnect();
    }

    public async getChainId(): Promise<string> {
        const provider = await this.getProvider();
        return provider.chainId;
    }

    public async getLibrary(): Promise<Provider> {
        return await this.getProvider();
    }

    public async getNetwork(): Promise<NETWORK_CHAIN> {
        const provider = await this.getProvider();
        return provider.chainId;
    }

    public async isConnected(): Promise<boolean> {
        const provider = await this.getProvider();
        return provider.connected;
    }

    public async sendTransaction(data: SendTransactionProps): Promise<void> {
        const provider = await this.getProvider();
        await provider.request(data);
    }
}
