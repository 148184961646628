import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Layout } from '../../components/Layout/Layout';
import { GuideInfo } from '../../components/GuideInfo/GuideInfo';
import { LeverageScreenButtonGroup } from '../../components/Supply/Supply';
import { FixedRateMenu } from '../../components/FixedRateMenu/FixedRateMenu';
import { AssetsToSupplySection, LeverageMode } from '../../components/AssetsToSupply';
import { Faqs } from '../../components/Faqs/Faqs';
import { UserBorrows } from '../../components/Borrow/UserBorrows';
import { FixedRateMenuKey } from '../../components/FixedRateMenu/constants';
import { AssetsToClaim } from '../../components/AssetsToClaim/AssetsToClaim';
import { getFundAction } from 'src/store/supplies/actions';
import { Store } from 'src/store';

const PULL_INTERVAL = 5000;

export const Borrow: FC = () => {
    const [selectedRate, setSelectedRate] = useState(FixedRateMenuKey.FIXED_RATE);
    const type = useSelector((state: Store) => state.wallets.active.type);

    const dispatch = useDispatch();

    useEffect(() => {
        if (type.length === 0) {
            return;
        }
        const action = getFundAction;
        dispatch(getFundAction());

        const interval = setInterval(() => dispatch(action()), PULL_INTERVAL);

        return () => clearInterval(interval);
    }, [type]);

    return (
        <div>
            <Layout isBorder>
                <GuideInfo />
                <LeverageScreenButtonGroup />
                <FixedRateMenu selected={selectedRate} setSelected={setSelectedRate} />
            </Layout>
            <Layout>
                <UserBorrows />
                <AssetsToClaim />
                <AssetsToSupplySection mode={LeverageMode.Borrow} selectedRate={selectedRate} />
            </Layout>
            <Faqs />
        </div>
    );
};
