import { createUseStyles } from 'react-jss';
import { color, spacing } from '@blink/components';

export const useBorrowDetailsBalanceManagementStyles = createUseStyles({
    root: {
        width: 496,
        border: `1px solid ${color.border.opaque}`,
        borderRadius: 4,
        background: color.background.primary,
        padding: `${spacing['07']} ${spacing['08']} ${spacing['08']}`,
        color: color.textPrimary,
    },
    title: {
        fontSize: 21,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: spacing['05'],
        marginBottom: spacing['07'],
        borderBottom: `1px solid ${color.content.disable}`,
    },
    closeButton: {
        padding: 0,
        width: 'fit-content',
        '& > svg': {
            width: '28px',
            height: '28px',
            color: color.content.primary,
            opacity: '50%',

            '&:hover': {
                opacity: '100%',
            },
        },
    },
    submitButtonWrapper: {
        paddingTop: spacing['07'],
        borderTop: `1px solid ${color.content.disable}`,
        '& > button': {
            width: '100%',
            fontSize: 17,
            fontWeight: 600,
        },
    },
    collateralAmount: {
        marginTop: spacing['07'],
    },
    healthCard: {
        border: `1px solid ${color.border.opaque}`,
        backgroundColor: color.background.secondary,
        padding: `${spacing['05']} ${spacing['06']} ${spacing['06']}`,
        margin: `${spacing['07']} 0`,
    },
});
