import React, { FC } from 'react';
import { ButtonGroup } from '@blink/components';
import { RadioChangeEvent } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import { useSupplyStyle } from './Supply.style';

export const supplyGroupList = [
    {
        text: 'Supply',
        value: '/',
    },
    {
        text: 'Borrow',
        value: '/borrow',
    },
];

export const LeverageScreenButtonGroup: FC = () => {
    const classes = useSupplyStyle();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const handleChange = (e: RadioChangeEvent) => {
        if (pathname !== e.target.value) {
            navigate(e.target.value);
        }
    };

    return (
        <div className={classes.root}>
            <ButtonGroup
                className={classes.group}
                items={supplyGroupList}
                value={pathname}
                handleChange={handleChange}
            />
        </div>
    );
};
