import { createUseStyles } from 'react-jss';
import { color, fontSize, spacing } from '@blink/components';

export const useLiquidityStyle = createUseStyles({
    root: {
        maxWidth: 430,
        marginTop: spacing['06'],
    },
    tipWrapper: {
        marginTop: spacing['05'],
    },
    summaryWrapper: {
        display: 'flex',
        flexDirection: 'column',
        margin: `${spacing['05']} 0 ${spacing['07']}`,
        paddingBottom: spacing['07'],
        borderBottom: `1px solid ${color.border40}`,
    },
    summaryLine: {
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${color.border.opaque}`,
        alignItems: 'center',
        alignSelf: 'stretch',
        padding: `${spacing['02']} 0px`,
        height: 53,

        '&:last-child': {
            marginBottom: 0,
            borderBottom: 'none',
            height: 38,
        },
    },
    summaryLeft: {
        color: color.content.secondary,
    },
    apyWrapper: {
        textAlign: 'right',
    },
    logoWrapper: {
        display: 'flex',
        alignItems: 'center',
        color: color.content.secondary,
    },
    logoIcon: {
        display: 'flex',
        marginLeft: spacing['02'],
        '& svg': {
            width: 16,
            height: 16,
        },
    },
    button: {
        '& > span': {
            fontWeight: 600,
        },
        width: '100%',
        fontSize: fontSize.lg,
    },
});
