import { createUseStyles } from 'react-jss';

import { color, fontSize, spacing } from '../../variables';

export const useButtonOutlineStyles = createUseStyles({
    overlay: {
        position: 'relative',
    },
    root: {
        fontSize: fontSize.sm,
        color: color.content.primary,
        fontWeight: 600,
        background: 'transparent',
        borderRadius: 4,
        border: `1px solid ${color.border.selected}`,
        lineHeight: '11px',
        letterSpacing: -0.308,
        padding: spacing['04'],

        '&:hover': {
            border: `1px solid ${color.border.selected} !important`,
            background: `rgba(255, 255, 255, 0.1) !important`,
            color: `${color.content.primary} !important`,
            transition: 'all 0.5s',
        },

        '&:disabled': {
            background: color.background.disabled,
            borderColor: `${color.hoverSecondary} !important`,
            color: `${color.content.disable} !important`,
        },
    },
});
