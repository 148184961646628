import { createUseStyles } from 'react-jss';
import { color, fontSize, radius, spacing } from '@blink/components';

export const useModalStyles = createUseStyles({
    wrapperAccount: {
        background: color.background.secondary,
        display: 'flex',
        borderRadius: radius.overall,
        padding: `${spacing['04']} ${spacing['05']}`,
    },
    iconInfo: {
        width: '20px',
        height: '20px',
        marginRight: spacing['03'],
    },
    amountIcon: {
        width: '20px',
        height: '20px',
        marginRight: spacing['03'],
        '& > path': {
            fill: color.content.secondary,
        },
    },
    title: {
        display: 'flex',
        alignItems: 'center',
    },
    line: {
        height: '1px',
        width: '100%',
        marginTop: spacing['07'],
        borderTop: '1px solid',
        borderColor: color.content.secondary,
    },
    submitButton: {
        fontSize: fontSize.md,
        width: '100%',
        marginTop: spacing['07'],
    },
    root: {
        width: '500px',
    },
    wrapper: {
        minWidth: '456px',
        background: color.background.secondary,
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${color.border}`,
        borderRadius: radius.overall,
        padding: `${spacing['04']} ${spacing['05']}`,
        '&.error': {
            borderColor: color.danger,
            '& .title': {
                color: color.danger,
            },
        },
        '& img': {
            width: 28,
            height: 28,
        },
        '&.supply': {
            paddingBottom: spacing['03'],
        },
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: `${spacing['03']} 0`,
    },
    assetName: {
        fontSize: fontSize.xl,
    },
    container: {
        minHeight: '48px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: `${spacing['03']} 0`,
    },
    estimation: {
        color: color.content.secondary,
    },
    input: {
        display: 'flex',
        width: '100%',
        margin: `0 0 0 ${spacing['04']}`,
        alignItems: 'center',
        justifyContent: 'end',
        fontSize: fontSize.xl,
        '& .ant-input-number': {
            width: '100%',
            fontSize: fontSize.xl,
            borderColor: 'transparent',
            backgroundColor: 'inherit',
            textAlign: 'end',
            lineHeight: 0.5,
        },
        '& .ant-input-number:hover': {
            borderColor: 'transparent',
        },
        '& .ant-input-number-focused': {
            boxShadow: 'none',
        },
        '& input': {
            textAlign: 'end !important',
        },
        '& .ant-input-number-input::placeholder': {
            color: color.content.secondary,
        },
        '& .ant-input-number-input:focus::placeholder': {
            color: color.content.primary,
        },
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        color: color.content.secondary,
    },
    balanceWrapper: {
        display: 'flex',
    },
});
