import { actionCreator } from '@blink/components/src/utils';

import {
    SET_ASSETS_TO_SUPPLY_ERROR,
    SET_FUNDS_TO_CLAIM_ERROR,
    SET_USERS_SUPPLIES_ERROR,
    ADD_WALLETS_ERROR,
    ADD_NETWORK_ERROR,
    SET_USER_BORROWS_ERROR,
} from './constants';

export type ErrorActionProps = {
    message: string;
};

export const assetsToSupplyErrorAction = (payload: ErrorActionProps) =>
    actionCreator(SET_ASSETS_TO_SUPPLY_ERROR, payload);

export const fundsToClaimErrorAction = (payload: ErrorActionProps) =>
    actionCreator(SET_FUNDS_TO_CLAIM_ERROR, payload);

export const usersSuppliesErrorAction = (payload: ErrorActionProps) =>
    actionCreator(SET_USERS_SUPPLIES_ERROR, payload);

export const userBorrowsErrorAction = (payload: ErrorActionProps) =>
    actionCreator(SET_USER_BORROWS_ERROR, payload);

export const addWalletsErrorAction = (payload: ErrorActionProps) =>
    actionCreator(ADD_WALLETS_ERROR, payload);

export const addNetworksErrorAction = (payload: ErrorActionProps) =>
    actionCreator(ADD_NETWORK_ERROR, payload);
