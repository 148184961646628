import { spacing } from '@blink/components';
import { createUseStyles } from 'react-jss';

export const useUserSuppliesConnectWalletStyle = createUseStyles({
    button: {
        width: 136,
        height: 44,
        marginTop: spacing['07'],
    },
});
