import React, { FC, useEffect } from 'react';
import { Tooltip } from 'antd';
import { color } from '@blink/components';
import { useDispatch, useSelector } from 'react-redux';

import { useUserSuppliesSectionStyle } from '../../UserSupplies/UserSuppliesSection/UserSuppliesSection.style';
import { AssetsToSupplyItemRow } from '../AssetsToSupplyItemRow';
import { useAssetsToSupplySectionStyle } from './AssetsToSupplySection.style';
import { ReactComponent as InfoIcon } from '../../../assets/info.svg';
import { Store } from '../../../store';
import { getAssetsToSupplyAction } from '../../../store/supplies/actions';
import { FixedRateMenuKey } from '../../FixedRateMenu/constants';
import { SkeletonRow } from 'src/components/Skeleton/SkeletonRow';

const PULL_INTERVAL = 30000;

type AssetsToSupplySectionProps = {
    mode?: LeverageMode;
    selectedRate: FixedRateMenuKey;
};

export const enum LeverageMode {
    Supply = 'Supply',
    Borrow = 'Borrow',
}

export const AssetsToSupplySection: FC<AssetsToSupplySectionProps> = ({
    mode = LeverageMode.Supply,
    selectedRate,
}) => {
    const externalClasses = useUserSuppliesSectionStyle();
    const classes = useAssetsToSupplySectionStyle();
    const { walletAddress, blockchainName, items, loading } = useSelector((state: Store) => ({
        walletAddress: state.wallets.active?.address,
        blockchainName: state.wallets.network,
        items: state.supplies.assetsToSupply,
        loading: state.supplies.assetsToSupplyLoading,
    }));
    const itemsToSupply = items.filter((item) => item.leverage === true);
    const isBorrowMode = mode === LeverageMode.Borrow;
    const dispatch = useDispatch();

    useEffect(() => {
        const action = getAssetsToSupplyAction;
        dispatch(getAssetsToSupplyAction());

        const interval = setInterval(() => dispatch(action()), PULL_INTERVAL);

        return () => clearInterval(interval);
    }, [walletAddress, blockchainName, isBorrowMode]);

    const getContent = () => {
        if (loading && !itemsToSupply.length) {
            return (
                <>
                    <SkeletonRow />
                    <SkeletonRow />
                    <SkeletonRow />
                    <SkeletonRow />
                    <SkeletonRow />
                    <SkeletonRow />
                </>
            );
        }

        return itemsToSupply?.map((item, index) => (
            <AssetsToSupplyItemRow
                isBorrowMode={isBorrowMode}
                key={index}
                itemIndex={index}
                allItems={itemsToSupply}
                selectedRate={selectedRate}
            />
        ));
    };

    return (
        <div className={externalClasses.root}>
            <div className={externalClasses.title}>
                {isBorrowMode ? 'Assets to Borrow' : 'Assets to Supply'}
            </div>
            <div className={externalClasses.tableWrapper}>
                <table className={externalClasses.table}>
                    <thead>
                        <tr className={externalClasses.tr}>
                            <th className={classes.asset}>Asset</th>
                            <th className={classes.totalSupplyWrapper}>Total Supply</th>
                            <th>
                                <div>
                                    <span>{`${isBorrowMode ? 'Borrow' : 'Supply'} APY`}</span>{' '}
                                </div>
                            </th>
                            {selectedRate === FixedRateMenuKey.PROFIT_SHARING && (
                                <th>
                                    <div>
                                        <Tooltip
                                            className={classes.supply}
                                            rootClassName={classes.tooltipSupplyRoot}
                                            title='Sample'
                                            color={color.background.secondary}
                                        >
                                            <span>Profit Sharing</span>{' '}
                                            <InfoIcon className={classes.icon} />
                                        </Tooltip>
                                    </div>
                                </th>
                            )}
                            <th>Your Wallet</th>
                            <th className={classes.buttons} />
                        </tr>
                    </thead>
                    <tbody>{getContent()}</tbody>
                </table>
            </div>
        </div>
    );
};
