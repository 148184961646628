import React, { FC } from 'react';
import { ButtonStroke } from '@blink/components';
import { ButtonSize } from '@blink/components/src/Button/Button.types';
import { ReactComponent as CryptoLogo } from '@blink/assets/src/icons/crypto.svg';

import { useMarginAccountDisconnectionModalStyle } from './MarginAccountDisconnectionModal.style';
import { useModal } from '../ModalWrapperHooks';

export const MarginAccountDisconnectionModal: FC = () => {
    const classes = useMarginAccountDisconnectionModalStyle();

    const { modalProps } = useModal();
    if (!modalProps) {
        return;
    }

    const { disconnectAction } = modalProps;

    const connectWalletByUriHandler = () => {
        disconnectAction();
    };

    return (
        <>
            <div className={classes.content}>
                <div className={classes.container}>
                    <CryptoLogo />
                </div>

                <div className={classes.text}>
                    Remember, only one margin account can be open at a time. If you disconnect from
                    the current platform, your active trade will close automatically.
                </div>
            </div>
            <div className={classes.buttonWrapper}>
                <ButtonStroke
                    isSecondary={true}
                    size={ButtonSize.md}
                    onClick={connectWalletByUriHandler}
                >
                    Confirm
                </ButtonStroke>
            </div>
        </>
    );
};
