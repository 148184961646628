import { createUseStyles } from 'react-jss';

import { color, spacing } from '../../variables';

export const useDropdownMinMaxStyles = createUseStyles({
    dropdown: {
        border: `1px solid ${color.border.opaque}`,
        height: 46,
        cursor: 'pointer',
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: `0 ${spacing['04']}`,
        width: '100%',
        maxWidth: 'fit-content',
    },
    dropdownLabelWrapper: {
        display: 'flex',
        marginRight: spacing['04'],
    },
    dropdownLabel: {
        color: color.content.secondary,
    },
    dropdownValues: {
        color: color.content.primary,
    },
    calloutWrapper: {
        padding: spacing['04'],
        boxShadow: '0px 8px 24px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4)',
        borderRadius: 4,
        border: `1px solid ${color.border.opaque}`,
        backgroundColor: color.background.primary,
    },
    input: {
        width: '100%',
        marginBottom: spacing['04'],
    },
});
