import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AbstractWallet, ETHEREUM_METHOD, WalletHelper } from '@blink/components/src/utils';
import { walletMap } from '@blink/components/src/constants/wallet';
import { Contract, ethers, parseUnits } from 'ethers';
import { ALLOWANCE_STATUS, WALLET } from '@blink/components/src/types';
import { message, Steps, StepsProps } from 'antd';
import { Api, ButtonStroke } from '@blink/components';
import { Message } from '@blink/components/src/constants/messages';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as SuccessIcon } from '@blink/assets/src/icons/checkmark.svg';
import { NETWORK } from '@blink/components/src/constants/global';

import AccountFactoryABI from '../../../abi/AccountFactoryABI.json';
import IERCAbi from '../../../abi/IERCAbi.json';
import { Allowance } from '../../Allowance';
import { useHideModal, useModal } from '../ModalWrapperHooks';
import { useRegisterMarginAccountStyle } from './RegisterMarginAccountModal.style';
import { Store } from '../../../store';
import { SelectedBorrowAsset } from 'src/pages/BorrowAsset';

const registerAccountErrors = new Map()
    .set('0x91cab504', 'Nonce already used')
    .set('0x0819bdcd', 'Signature expired')
    .set('0xda9670b6', 'Signer is now allowed');

type Collateral = {
    token: string;
    amount: bigint;
};

export const getSigner = async (type: WALLET, blockchainName: NETWORK) => {
    const wallet: AbstractWallet = walletMap.get(type) as AbstractWallet;
    const walletProvider = await wallet.getProvider();

    await walletProvider.request({ method: ETHEREUM_METHOD.REQUEST });
    const provider = new ethers.BrowserProvider(
        walletProvider,
        WalletHelper.getNetworkNumber(blockchainName),
    );
    const signer = await provider.getSigner();
    return signer;
};

export const RegisterMarginAccountModal: FC = () => {
    const classes = useRegisterMarginAccountStyle();
    const { modalProps } = useModal();
    const { handleCloseModal } = useHideModal();
    const { selectedAssets = [], assetAmount, assetName, assetAddress } = modalProps || {};
    const newSelectedAssetsStatuses: ALLOWANCE_STATUS[] = [];
    for (let i = 0; i < selectedAssets?.length; i++) {
        const isFirst = i === 0;
        const status = isFirst ? ALLOWANCE_STATUS.PROCESSING : ALLOWANCE_STATUS.WAITING;
        newSelectedAssetsStatuses.push(status);
    }
    const navigate = useNavigate();
    const [selectedAssetsStatuses, setSelectedAssetsStatuses] =
        useState<ALLOWANCE_STATUS[]>(newSelectedAssetsStatuses);
    const [walletSigner, setWalletSigner] = useState<ethers.JsonRpcSigner>();
    const [isInProgress, setIsInProgress] = useState<boolean>(true);
    const { type, blockchainName } = useSelector((state: Store) => ({
        blockchainName: state.wallets.network,
        type: state.wallets.active.type,
    }));
    const current = selectedAssetsStatuses.findIndex(
        (assetStatus) =>
            assetStatus === ALLOWANCE_STATUS.PROCESSING ||
            assetStatus === ALLOWANCE_STATUS.WARNING ||
            assetStatus === ALLOWANCE_STATUS.ERROR,
    );
    const isDone = !selectedAssetsStatuses.some(
        (assetStatus) => assetStatus !== ALLOWANCE_STATUS.SUCCESS,
    );

    useEffect(() => {
        (async () => {
            if (type && blockchainName) {
                const signer = await getSigner(type, blockchainName);
                setWalletSigner(signer);
            }
        })();
    }, [type, blockchainName]);

    useEffect(() => {
        if (!isDone || !walletSigner || !isInProgress) {
            return;
        }
        (async () => {
            try {
                //setSubmitProcessing(true);
                const accountFactoryAddress = Api.MARGIN_ACCOUNT_ADDRESS as string;
                const accountFactory = new Contract(
                    accountFactoryAddress,
                    AccountFactoryABI,
                    walletSigner,
                );
                const tokenAddress: string = assetAddress;
                const selectedAssetContract = new Contract(tokenAddress, IERCAbi, walletSigner);
                const selectedAssetDecimal = await selectedAssetContract.decimals();

                const collateral: Collateral[] = [];

                for (const selectedAsset of selectedAssets) {
                    const tokenSelectedAssets: string = selectedAsset.address;
                    const token = new Contract(tokenSelectedAssets, IERCAbi, walletSigner);
                    const decimal = await token.decimals();
                    const amount = parseUnits(selectedAsset.amount.toString(), decimal);

                    collateral.push({
                        token: tokenSelectedAssets,
                        amount,
                    });

                    const allowance: bigint = await token.allowance(
                        walletSigner.address,
                        accountFactoryAddress,
                    );

                    if (allowance < amount) {
                        const approveToken = await token.approve(accountFactoryAddress, amount);
                        await approveToken.wait();
                    }
                }

                const leverageAmount = parseUnits(assetAmount.toString(), selectedAssetDecimal);
                const leverage = { amount: leverageAmount, token: tokenAddress };
                await accountFactory.registerMarginAccount(collateral, leverage);

                message.success(Message.TAKE_LEVERAGE_SUCCESS);
                handleCloseModal();
                navigate('/borrow');
            } catch (e: any) {
                console.warn(e);
                let messageError = 'Transaction was rejected';
                const errorData = e?.data;
                setIsInProgress(false);
                if (errorData) {
                    const errorSelector = errorData.substring(0, 10);
                    messageError = registerAccountErrors.has(errorSelector)
                        ? registerAccountErrors.get(errorSelector)
                        : messageError;
                }
                message.error(messageError);
            }
        })();
    }, [isDone, isInProgress]);

    if (!walletSigner || !type || !blockchainName) {
        return <div>Loading</div>;
    }

    const items = selectedAssets?.map((selectedAsset: SelectedBorrowAsset, index: number) => {
        const lastDone = selectedAssetsStatuses.lastIndexOf(ALLOWANCE_STATUS.SUCCESS) === index;
        const onChangeStatusHandler = (status: ALLOWANCE_STATUS) => {
            const copyAllSelectedAssets = [...selectedAssetsStatuses];
            copyAllSelectedAssets[index] = status;
            if (index !== selectedAssets.length - 1 && status === ALLOWANCE_STATUS.SUCCESS) {
                copyAllSelectedAssets[index + 1] = ALLOWANCE_STATUS.PROCESSING;
            }
            setSelectedAssetsStatuses(copyAllSelectedAssets);
        };
        const allowanceStatus = selectedAssetsStatuses[index];
        const additionalClasses =
            allowanceStatus === ALLOWANCE_STATUS.WAITING ? classes.waiting : null;
        return {
            title: (
                <span className={`${classes.allowanceTitle} ${additionalClasses}`}>
                    STEP {index + 1}
                </span>
            ),
            description: (
                <Allowance
                    key={selectedAsset.name}
                    asset={selectedAsset.symbol}
                    assetAddress={selectedAsset.address}
                    requiredAmount={selectedAsset.amount}
                    status={allowanceStatus}
                    changeStatus={onChangeStatusHandler}
                    signer={walletSigner}
                    lastItem={lastDone}
                />
            ),
        };
    });

    const tryAgainHandler = () => {
        setIsInProgress(true);
    };

    const getActions = () => {
        const customDot: StepsProps['progressDot'] = (dot, { status }) => {
            return status === 'finish' ? (
                <div className={classes.check}>
                    <SuccessIcon />
                </div>
            ) : (
                <div className={classes.wrapper}>
                    <div className={classes.dot}></div>
                </div>
            );
        };
        return items.length && items.length > 1 ? (
            <Steps
                progressDot={customDot}
                direction='vertical'
                current={current === -1 ? selectedAssetsStatuses.length - 1 : current}
                status={isDone ? 'finish' : 'process'}
                items={items}
            />
        ) : (
            items[0]?.description
        );
    };

    return (
        <>
            <div className={classes.root}>{getActions()}</div>
            <div className={classes.tipWrapper}>
                <p className={classes.title}>Position Summary</p>
                <p className={classes.summary}>
                    Collateral Value
                    <div className={classes.amount}>
                        <span>
                            {assetName} {assetAmount}
                        </span>
                    </div>
                </p>
                <ButtonStroke
                    className={classes.submitButton}
                    isSecondary={true}
                    onClick={tryAgainHandler}
                    disabled={isInProgress}
                    loading={isInProgress}
                >
                    Processing
                </ButtonStroke>
            </div>
        </>
    );
};
