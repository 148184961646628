import { applyMiddleware, combineReducers, legacy_createStore as createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootWatcher from '../saga';
import wallets from './wallets';
import modals from './modals';
import supplies from './supplies';
import errors from './errors';
import borrows from './borrows';

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
    wallets,
    modals,
    supplies,
    errors,
    borrows,
});

export type Store = ReturnType<typeof store.getState>;

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(rootWatcher);
