import React from 'react';
import { Switch } from 'antd';

import { useTheme } from 'src/hooks/useTheme';

export const ThemeSwitch: React.FC = () => {
    const { toggleTheme } = useTheme();

    return <Switch defaultChecked onChange={toggleTheme} style={{ display: 'none' }} />;
};
