import React, { FC, useState } from 'react';
import { ReactComponent as CoinsIcon } from '@blink/assets/src/icons/coins.svg';
import { ReactComponent as CloseIcon } from '@blink/assets/src/icons/close.svg';
import { AssetWithIcon, TextButton } from '@blink/components';

import { useCollateralPendingActions } from './CollateralPendingActions.style';
import { SelectedBorrowAsset } from 'src/pages/BorrowAsset';

type CollateralPendingActionsProps = {
    selectedAssets: SelectedBorrowAsset[];
    setSelectedAssets: (selectedAssets: SelectedBorrowAsset[]) => void;
};

export const CollateralPendingActions: FC<CollateralPendingActionsProps> = ({
    selectedAssets,
    setSelectedAssets,
}) => {
    const classes = useCollateralPendingActions();
    const [assetToDelete, setAssetToDelete] = useState('');

    const handleClearAll = () => {
        setSelectedAssets([]);
    };

    const handleRemoveAsset = () => {
        const filteredAssets = selectedAssets.filter((asset) => asset.name != assetToDelete);
        setSelectedAssets(filteredAssets);
    };

    return (
        <div>
            <div className={classes.flexWrapper}>
                <div>
                    {selectedAssets.length} Pending Action
                    {selectedAssets.length > 1 ? 's' : ''}
                </div>
                <TextButton onClick={handleClearAll} className={classes.btn}>
                    Clear All
                </TextButton>
            </div>
            <div className={classes.scrollWrapper}>
                {selectedAssets.map((asset, idx) => {
                    return (
                        <div
                            className={classes.root}
                            key={idx}
                            onMouseEnter={() => setAssetToDelete(asset.name)}
                            onMouseLeave={() => setAssetToDelete('')}
                        >
                            <div className={`${classes.addBorder} border`}>
                                <AssetWithIcon
                                    assetName={asset.symbol || ''}
                                    assetSymbol={asset.symbol}
                                />
                                <div className={classes.amountWrapper}>
                                    <div>{asset.amount}</div>
                                    {assetToDelete === asset.name ? (
                                        <TextButton
                                            onClick={handleRemoveAsset}
                                            className={classes.btn}
                                        >
                                            <CloseIcon />
                                        </TextButton>
                                    ) : (
                                        <TextButton className={classes.btn}>
                                            <CoinsIcon />
                                        </TextButton>
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
