import { createUseStyles } from 'react-jss';
import { color, fontSize, radius, spacing } from '@blink/components';

export const userRiskFactorStyle = createUseStyles({
    good: {
        color: color.primary,
    },
    bad: {
        color: color.border.warning,
    },
    placeholderBarWrapper: {
        width: '100%',
        display: 'flex',
        gap: spacing['02'],
        marginTop: spacing['07'],
    },
    small: {
        '&.ant-skeleton-input': {
            width: '124px !important',
            height: '40px !important',
            minWidth: '124px !important',
            backgroundColor: `${color.content.overlayLight} !important`,
            borderRadius: radius.overall,
        },
    },
    barWrapper: {
        width: '100%',
        textAlign: 'center',
    },
    emptyBar: {
        width: '100%',
        height: 6,
        background: 'rgba(116, 129, 164, 0.4)',
        borderRadius: 1,
        marginBottom: spacing['04'],
        '& input': {
            visibility: 'hidden',
        },
    },
    errorBar: {
        position: 'relative',
        width: '100%',
        height: 6,
        background: color.background.negative,
        borderRadius: 1,
        marginBottom: spacing['04'],
    },
    successBar: {
        position: 'relative',
        width: '100%',
        height: 6,
        background: color.background.positive,
        borderRadius: 1,
        marginBottom: spacing['04'],
    },
    warningBar: {
        position: 'relative',
        width: '100%',
        height: 6,
        background: color.background.warning,
        borderRadius: 1,
        marginBottom: spacing['04'],
    },
    slider: {
        position: 'absolute',
        left: 0,
        top: -3.5,
        WebkitAppearance: 'none',
        width: '100%',
        height: 10,
        backgroundColor: 'transparent',
        '&::-webkit-slider-thumb': {
            WebkitAppearance: 'none',
            appearance: 'none',
            width: 1.5,
            height: 8,
            background: color.content.primary,
        },
        '&::-moz-range-thumb': {
            width: 1.5,
            height: 8,
            background: color.content.primary,
        },
    },

    top: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: spacing['04'],
        alignSelf: 'stretch',
        fontSize: fontSize.sm,
    },

    bottom: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
        fontSize: fontSize.sm,
    },
    heading: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
    },
    bar: {
        display: 'flex',
        height: 4,
        alignItems: 'flex-start',
        gap: spacing['10'],
        marginBottom: spacing['09'],
        alignSelf: 'stretch',
        borderRadius: 8,
        background: `linear-gradient(90deg, #D21E50 0%, rgba(210, 30, 80, 0.50) 25%, #EC985B 50%, rgba(54, 201, 160, 0.50) 75%, #36C9A0 100%)`,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
        gap: spacing['02'],
        flex: '1 0 0',
        fontWeight: 400,
        '& p': {
            margin: 0,
            fontWeight: 400,
        },
    },
    percent: {
        color: color.content.tertiary,
        fontSize: fontSize.sm,
        lineHeight: '16px',
        letterSpacing: '-0.312px',
        minWidth: 40,
        textAlign: 'end',
    },
    range: {
        display: 'flex',
        justifyContent: 'space-between',
        width: 288,
        padding: `0px ${spacing['10']}`,
        alignItems: 'center',
        position: 'relative',
        top: -1,
        '&:before': {
            content: "''",
            width: '1px',
            height: '6px',
            backgroundColor: `${color.danger}`,
            position: 'absolute',
            top: 0,
            left: 50,
        },
        '&:after': {
            content: "''",
            width: '1px',
            height: '6px',
            backgroundColor: `${color.background.alwaysLight}`,
            position: 'absolute',
            top: 0,
            right: 47,
        },
    },
    leftPointer: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        color: color.danger,
        left: 10,
        top: 7,
        '& p': {
            margin: 0,
            lineHeight: '16px',
        },
    },
    rightPointer: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        right: 42,
        top: -21,
    },
    tooltipRiskFactor: {
        width: 290,
    },
    leftPointerNote: {
        fontSize: fontSize.xs,
        lineHeight: '14px',
    },
    tooltipRoot: {
        borderRadius: radius.overall,
        width: 'fit-content',
        '& .ant-tooltip-arrow': {
            top: 1,
        },
        '& .ant-tooltip-inner': {
            display: 'flex',
            minWidth: 96,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: spacing['02'],
            padding: spacing['04'],
            border: `1px solid transparent`,
            background: `${color.background.quaternary} !important`,
        },
        '& .ant-tooltip-arrow:before': {
            background: `${color.background.quaternary} !important`,
        },
        '&.ant-tooltip': {
            fontSize: `${fontSize.sm} !important`,
            maxWidth: 320,
        },
    },
    titleTooltip: {
        fontWeight: '500 !important',
    },
});
