import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UserSuppliesRows } from '../UserSuppliesRows';
import { useUserSuppliesSectionStyle } from './UserSuppliesSection.style';
import { UserSuppliesEmpty } from '../UserSuppliesEmpty';
import { Store } from '../../../store';
import { UserSuppliesConnectWallet } from '../UserSuppliesConnectWallet';
import { getUsersSupplies } from '../../../store/supplies/actions';
import { SkeletonRow } from 'src/components/Skeleton/SkeletonRow';

const PULL_INTERVAL = 30000;

export const UserSuppliesSection: FC = () => {
    const classes = useUserSuppliesSectionStyle();
    const dispatch = useDispatch();
    const { walletAddress, blockchainName, loading, items, assetsToSupply, hasUserSupplies } =
        useSelector((state: Store) => ({
            walletAddress: state.wallets.active.address,
            blockchainName: state.wallets.network,
            loading: state.supplies.userSuppliesLoading,
            items: state.supplies.userSupplies,
            assetsToSupply: state.supplies.assetsToSupply,
            hasUserSupplies: state.supplies.hasUserSupplies,
        }));

    useEffect(() => {
        const action = getUsersSupplies;
        dispatch(getUsersSupplies());

        const interval = setInterval(() => dispatch(action()), PULL_INTERVAL);

        return () => clearInterval(interval);
    }, [walletAddress, blockchainName, assetsToSupply]);

    const getContent = () => {
        if (!walletAddress) {
            return <UserSuppliesConnectWallet />;
        }

        const notLoading = !loading && !items.length;
        const userDontHaveSupplies = loading && !hasUserSupplies;
        if (notLoading || userDontHaveSupplies) {
            return <UserSuppliesEmpty />;
        }

        return (
            <div className={classes.tableWrapper}>
                <table className={classes.table}>
                    <thead>
                        <tr className={classes.tr}>
                            <th>Asset</th>
                            <th>Your Balance</th>
                            <th>APY</th>
                            <th>Interest Earned</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <>
                                {items.map((item, index) => (
                                    <SkeletonRow key={index} />
                                ))}
                            </>
                        ) : (
                            <UserSuppliesRows items={items} />
                        )}
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <div className={classes.root}>
            <div className={classes.title}>Your Supplies</div>
            {getContent()}
        </div>
    );
};
