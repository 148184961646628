export const Message = {
    DEFAULT_ERROR: 'We are currently experiencing processing issues, sorry for the inconvenience.',

    SWAP_TOKENS_SUCCESS: 'Successfully swapped tokens!',
    SWAP_TOKENS_ERROR: 'Tokens could not be swapped now. Try again later.',

    GET_BALANCE_ERROR: 'Could not get balance info, sorry for the inconvenience.',
    GET_EXCHANGE_INFO_ERROR: 'Could not get exchange info, sorry for the inconvenience.',
    GET_GAS_ERROR: 'Could not get gas info, sorry for the inconvenience.',
    GET_POOL_ERROR: 'Could not get pool info, sorry for the inconvenience.',

    CHANGE_NETWORK_ERROR: 'Could not get pool info, sorry for the inconvenience.',

    CLOSE_MARGIN_ACCOUNT_ERROR: 'Could not close this account.',
    CLOSE_MARGIN_ACCOUNT_SUCCESS: 'Successfully closed account.',
};
