import { actionCreator } from '@blink/components/src/utils';

import {
    GET_USER_BORROWS,
    SET_USER_BORROWS,
    SET_USER_BORROWS_CLOSED,
    SET_USER_BORROWS_MAINTENANCE,
    SET_USER_BORROWS_MAINTENANCE_HISTORY,
    SET_USER_BORROWS_REGISTERED,
    SET_USER_BORROWS_SUSPENDED,
} from './constants';
import { History, MaintenanceData, UserBorrow } from 'src/api/borrow';

export const getUserBorrows = (status: string) => actionCreator(GET_USER_BORROWS, { status });

export const setUserBorrows = (payload: UserBorrow[]) => actionCreator(SET_USER_BORROWS, payload);

export const setUserBorrowsSuspended = (payload: UserBorrow[]) =>
    actionCreator(SET_USER_BORROWS_SUSPENDED, payload);

export const setUserBorrowsRegistered = (payload: UserBorrow[]) =>
    actionCreator(SET_USER_BORROWS_REGISTERED, payload);

export const setUserBorrowsClosed = (payload: UserBorrow[]) =>
    actionCreator(SET_USER_BORROWS_CLOSED, payload);

export const setUserBorrowsMaintenance = (payload: MaintenanceData) =>
    actionCreator(SET_USER_BORROWS_MAINTENANCE, payload);

export const setUserBorrowsMaintenanceHistory = (payload: History<MaintenanceData>[]) =>
    actionCreator(SET_USER_BORROWS_MAINTENANCE_HISTORY, payload);
