import { createUseStyles } from 'react-jss';

import { color, fontSize } from '../../variables';

export const useButtonStrokeStyle = createUseStyles({
    root: {
        fontSize: fontSize.sm,
        fontWeight: 600,
        color: color.textPrimary,
        borderColor: color.border.selected,
        borderRadius: 4,

        '&:hover': {
            background: color.strokeHover,
            borderColor: `${color.strokeHover} !important`,
            color: `${color.content.primary} !important`,
            opacity: 0.85,
        },
        '&:active': {
            background: color.strokeActive,
            borderColor: `${color.strokeActive} !important`,
            color: `${color.content.primary} !important`,
        },
        '&:disabled': {
            background: color.background.disabled,
            borderColor: `${color.hoverSecondary} !important`,
            color: `${color.disabledText} !important`,
        },
    },
});
