import React, { FC, useEffect, useState, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from '@blink/assets/src/icons/arrow.svg';
import { useDispatch, useSelector } from 'react-redux';
import { message, Progress, Spin } from 'antd';
import {
    AbstractWallet,
    ETHEREUM_METHOD,
    WalletHelper,
    //shortFormatUSDCurrency,
} from '@blink/components/src/utils';
import { walletMap } from '@blink/components/src/constants/wallet';
import { Contract, ethers, parseUnits } from 'ethers';
import { Api, ButtonStroke } from '@blink/components';
import { AssetIcon } from '@blink/components/src/Asset/AssetIcon';
import { MINIMAL_RISK_FACTOR_VALUE } from '@blink/components/src/constants/global';

import { Layout } from '../../components/Layout/Layout';
import { useBorrowAssetStyle } from './BorrowAsset.style';
import { Store } from '../../store';
import { Token, getTokens } from '../../api/borrow';
import { AssetInputSupply } from 'src/components/AssetInputSupply';
import { CollateralAssetsSection } from 'src/components/Modals/BorrowModal/CollateralAsset/CollateralAssetsSection';
import PoolRegistryAbi from '../../abi/PoolRegistryAbi.json';
import IERCAbi from '../../abi/IERCAbi.json';
import { InfoCard } from 'src/components/InfoCard';
import { CollateralPendingActions } from 'src/components/Modals/BorrowModal/CollateralAsset/CollateralPendingActions';
import { CollateralHealthCard } from 'src/components/Modals/BorrowModal/CollateralAsset/CollateralHealthCard';
import { showModalAction } from '../../store/modals/actions';
import { ModalName } from '../../store/modals/constants';
import { getAssetsToSupplyAction } from 'src/store/supplies/actions';
import { CentrifugeContext } from 'src/context/centrifuge';

export type SelectedBorrowAsset = {
    symbol: string;
    amount: string;
    amountInAssetToBorrow: string;
    address: string;
    name: string;
    decimals: number;
};

const defaultAsset = {
    id: 0,
    apy: '',
    address: '',
    name: '',
    symbol: '',
    balance: '',
    decimals: 0,
};

export const BorrowAsset: FC = () => {
    const centrifuge = useContext(CentrifugeContext);
    const classes = useBorrowAssetStyle();
    const { symbol = '' } = useParams();
    const navigate = useNavigate();
    const [asset, setAsset] = useState<Token>(defaultAsset);
    const [loadingAssets, setLoadingAssets] = useState(true);
    const dispatch = useDispatch();

    const { type, blockchainName, walletAddress, assets } = useSelector((state: Store) => ({
        blockchainName: state.wallets.network,
        walletAddress: state.wallets.active.address,
        type: state.wallets.active.type,
        assets: state.supplies.assetsToSupply,
    }));

    useEffect(() => {
        dispatch(getAssetsToSupplyAction());
    }, [type, blockchainName, walletAddress]);

    const assetsToCollateral = assets.filter((asset) => asset.collateral);

    const [maxBorrow, setMaxBorrow] = useState(0);
    const [maxBorrowLoading, setMaxBorrowLoading] = useState(true);

    const [collateralAssets, setCollateralAssets] = useState(assetsToCollateral);

    const getBorrowAsset = async () => {
        setLoadingAssets(true);
        if (!walletAddress || !blockchainName) {
            return;
        }
        try {
            const borrowAssets = await getTokens();

            if (borrowAssets) {
                const borrowAsset = borrowAssets.filter((asset) => asset.symbol === symbol);

                if (borrowAsset[0]) {
                    setLoadingAssets(false);
                    setAsset(borrowAsset[0]);
                } else {
                    navigate('/borrow');
                    return;
                }
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
        setLoadingAssets(false);
    };

    const getSortedCollaterals = (assetsToCollateral: Token[]) => {
        const sortedCollateralAssets = () =>
            assetsToCollateral.sort((collateralAssets1, collateralAssets2) => {
                return Number(collateralAssets2.balance) - Number(collateralAssets1.balance);
            });
        setCollateralAssets(sortedCollateralAssets);
    };

    useEffect(() => {
        getBorrowAsset();
    }, [walletAddress, blockchainName]);

    useEffect(() => {
        getSortedCollaterals(assetsToCollateral);
    }, [assets]);

    const { assetAddress, assetSymbol, borrowApy, assetFullName, decimals } = {
        assetAddress: asset.address,
        assetSymbol: symbol,
        //borrowLimitMax: asset.borrow.max,
        //borrowLimitUsed: asset.borrow.current,
        borrowApy: asset.apy,
        assetFullName: asset.name,
        decimals: asset.decimals,
    };

    const [assetAmount, setAssetAmount] = useState<string>();
    const [selectedAssets, setSelectedAssets] = useState<SelectedBorrowAsset[]>([]);
    const [health, setHealth] = useState<number | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!assetAddress) {
            return;
        }
        (async () => {
            const wallet: AbstractWallet = walletMap.get(type) as AbstractWallet;
            const walletProvider = await wallet.getProvider();

            await walletProvider.request({ method: ETHEREUM_METHOD.REQUEST });

            const provider = new ethers.BrowserProvider(
                walletProvider,
                WalletHelper.getNetworkNumber(blockchainName),
            );

            const signer = await provider.getSigner();
            const liquidityPool = new Contract(
                Api.LIQUIDITY_POOL_ADDRESS as string,
                PoolRegistryAbi,
                provider,
            );

            const poolAddress = await liquidityPool.getPool(assetAddress);

            const token = new Contract(assetAddress as string, IERCAbi, signer);
            const tokenBalanceOf = await token.balanceOf(poolAddress);
            const decimals = await token.decimals();
            const tokenBalance = Number(ethers.formatUnits(tokenBalanceOf, decimals));
            setMaxBorrow(tokenBalance);
            setMaxBorrowLoading(false);
        })();
    }, [assetAddress]);

    const handleAssetAmountChange = (value: string) => {
        setAssetAmount(value);
        setSelectedAssets([]);
        setHealth(null);
    };

    const handleCollateralAssetSelect = async (selectedAssets: SelectedBorrowAsset[]) => {
        const leverageDecimals = assets.find((asset) => asset.symbol === symbol)!.decimals;
        setSelectedAssets(selectedAssets);
        setHealth(null);

        const borrowAmount = assetAmount?.toString() || '0';

        const collateral = selectedAssets.map((item) => {
            return {
                name: item.name,
                amount: parseUnits(item.amount.toString(), item.decimals).toString(),
                address: item.address,
                symbol: item.symbol,
                decimals: item.decimals,
            };
        });

        if (collateral.length === 0) {
            return;
        }

        const params = {
            leverage: {
                address: assetAddress,
                name: assetFullName,
                symbol: assetSymbol,
                decimals: leverageDecimals,
                amount: parseUnits(borrowAmount, leverageDecimals).toString(),
            },
            collateral,
        };

        centrifuge?.rpc('estimate_risk_factor', params).then(
            function (res: { data: { result: { risk_factor: string } } }) {
                setHealth(Number(res.data.result.risk_factor));
            },
            function (err: string) {
                message.error(err);
            },
        );
    };

    const getRegisterModalTitle = () => {
        return selectedAssets.length && selectedAssets.length > 1
            ? selectedAssets.length + ' Pending Actions'
            : '1 Pending Action';
    };

    const handleSubmit = async () => {
        dispatch(
            showModalAction({
                modalName: ModalName.REGISTER_MARGIN_ACCOUNT_MODAL,
                modalTitle: getRegisterModalTitle(),
                modalProps: {
                    selectedAssets,
                    assetSymbol,
                    assetAmount,
                    assetAddress,
                },
            }),
        );
        return;
    };

    const isAssesAvailable = maxBorrow !== 0;

    const noCollateralAssets = !collateralAssets.some((item) => Number(item.balance) > 0);

    // const borrowLimit = `${shortFormatUSDCurrency({
    //     amount: Number(borrowLimitUsed),
    // })} ${assetSymbol} / ${shortFormatUSDCurrency({ amount: Number(borrowLimitMax) })}`;
    // const borrowLimitPercent = Math.round((Number(borrowLimitUsed) / Number(borrowLimitMax)) * 100);

    const getContent = () => {
        return (
            <>
                <div className={classes.left}>
                    <AssetInputSupply
                        assetName={assetSymbol}
                        balance={maxBorrow}
                        value={assetAmount}
                        setValue={handleAssetAmountChange}
                        customTitle='You Borrow'
                        customBalanceLabel='Max Available'
                        maxBorrowLoading={maxBorrowLoading}
                        disabled={noCollateralAssets || maxBorrow === 0}
                        isSupply={false}
                        borrowApy={borrowApy}
                        decimals={decimals}
                    />
                    {!isAssesAvailable && !maxBorrowLoading && (
                        <InfoCard
                            type='alert'
                            text='No assets available to borrow.'
                            classes={{ root: classes.noCollateralAlert }}
                        />
                    )}
                    {noCollateralAssets && !loading && (
                        <InfoCard
                            type='alert'
                            text='No collateralized assets found. To continue borrowing, please make sure to deposit enough collateral into your account.'
                            classes={{ root: classes.noCollateralAlert }}
                        />
                    )}
                    {loading && (
                        <div className={classes.spinWrapper}>
                            <Spin size='large' />
                        </div>
                    )}
                    {!noCollateralAssets && (
                        <CollateralAssetsSection
                            disabled={!assetAmount || !isAssesAvailable}
                            assets={collateralAssets}
                            assetName={assetSymbol}
                            selectedAssets={selectedAssets}
                            setSelectedAssets={handleCollateralAssetSelect}
                            classes={{
                                root:
                                    assetAmount && isAssesAvailable
                                        ? classes.collateralAssetRoot
                                        : classes.collateralAssetNotAllowed,
                            }}
                        />
                    )}
                </div>
                <div className={classes.right}>
                    <div className={`${classes.rightCardWrapper} ${classes.hide}`}>
                        <div className={classes.secondaryText}>Borrow Limit</div>
                        <div className={classes.borrowLimitRow}>
                            <div>Borrow Limit Used</div>
                            <div>NO DATA</div>
                            {/* <div>{borrowLimit}</div> */}
                        </div>
                        {/* <Progress className={classes.progress} percent={borrowLimitPercent} /> */}
                        <Progress className={classes.progress} percent={10} />
                        <div className={classes.borrowRates}>
                            <div className={classes.borrowApyWrapper}>
                                <div className={classes.borrowApy}>
                                    <AssetIcon
                                        assetName={assetSymbol}
                                        className={classes.borrowImg}
                                    />
                                    <div>Borrow APY</div>
                                </div>
                                <div>{Number(borrowApy) * 100}%</div>
                            </div>
                        </div>
                    </div>
                    {selectedAssets.length > 0 && (
                        <div className={classes.rightCardWrapperNoSidePadding}>
                            <CollateralPendingActions
                                selectedAssets={selectedAssets}
                                setSelectedAssets={handleCollateralAssetSelect}
                            />
                        </div>
                    )}

                    <div className={classes.rightCardWrapper}>
                        <CollateralHealthCard health={health} />
                    </div>
                    <ButtonStroke
                        className={classes.submitButton}
                        isSecondary={true}
                        onClick={handleSubmit}
                        disabled={
                            maxBorrow === 0 ||
                            !selectedAssets.length ||
                            noCollateralAssets ||
                            !health ||
                            health < MINIMAL_RISK_FACTOR_VALUE
                        }
                    >
                        Take Leverage
                    </ButtonStroke>
                </div>
            </>
        );
    };

    return (
        <div className={classes.wrapper}>
            <Layout>
                {loadingAssets ? (
                    <div className={classes.spinWrapper}>
                        <Spin size='large' />
                    </div>
                ) : (
                    <>
                        <div className={classes.breadcrumbs}>
                            <Link to='/borrow' className={classes.link}>
                                Fixed Rate
                            </Link>
                            <ArrowIcon className={classes.arrow} />
                            <Link
                                to={`/borrow-asset/${location.pathname.split('/')[2]}`}
                                className={classes.link}
                            >
                                <div>{`${location.pathname.split('/')[2]} Borrow`}</div>
                            </Link>
                        </div>
                        <div className={classes.content}>{getContent()}</div>
                    </>
                )}
            </Layout>
        </div>
    );
};
