import React from 'react';
import { Outlet } from 'react-router-dom';
import { ConfigProvider, Layout } from 'antd';
import { themeAnt } from '@blink/components';
import { Provider } from 'react-redux';

import { Layout as LayoutWrapper } from './components/Layout/Layout';
import { useStyle } from './app.style';
import { MenuHeader } from './components/MenuHeader/MenuHeader';
import { store } from './store';
import { Footer } from './components/Footer/Footer';
import { CentrifugeProvider } from './context/centrifuge';
import { ErrorMessage } from './components/ErrorMessage';
import { ThemeProvider } from './components/Theme/ThemeContext';
import { ProvidersProvider } from './context/provider';

const { Content } = Layout;

const App: React.FC = () => {
    const classes = useStyle();

    return (
        <ProvidersProvider>
            <ConfigProvider theme={themeAnt}>
                <ThemeProvider>
                    <Provider store={store}>
                        <CentrifugeProvider>
                            <Layout className={classes.layout}>
                                <Content>
                                    <ErrorMessage />
                                    <LayoutWrapper isBorder>
                                        <MenuHeader />
                                    </LayoutWrapper>
                                    <Outlet />
                                    <Footer />
                                </Content>
                            </Layout>
                        </CentrifugeProvider>
                    </Provider>
                </ThemeProvider>
            </ConfigProvider>
        </ProvidersProvider>
    );
};

export default App;
