import React, { FC } from 'react';
import { ReactComponent as EyeCrossed } from '@blink/assets/src/icons/eye-crossed.svg';
import { ButtonProps } from 'antd';

import { TextButton } from '../../TextButton';
import { useButtonShowLessStyle } from './ButtonShowLess.style';
import { concatClasses } from '../../utils';

type ButtonShowLessProps = ButtonProps;

export const ButtonShowLess: FC<ButtonShowLessProps> = ({ className, ...props }) => {
    const classes = useButtonShowLessStyle();

    return (
        <TextButton className={concatClasses(classes.root, className || '')} {...props}>
            <EyeCrossed />
            <div>Show Less</div>
        </TextButton>
    );
};
