import { createUseStyles } from 'react-jss';
import { color, fontSize, spacing } from '@blink/components';

export const useAssetsToSupplyRowsStyle = createUseStyles({
    tr: {
        height: 72,
        borderTop: `1px solid ${color.border.opaque}`,
        '&:not(:last-child)': {
            borderBottom: `1px solid ${color.border40}`,
        },
        '&:hover': {
            background: color.hoverSecondary,
        },
    },
    rewardAmount: {
        paddingLeft: '20px',
    },
    subTitle: {
        fontSize: fontSize.sm,
        color: color.content.secondary,
        fontWeight: 600,
        marginLeft: spacing['04'],
    },
    firstTd: {
        paddingLeft: spacing['06'],
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: spacing['05'],
        marginRight: spacing['06'],
    },
    supplyButton: {
        width: 100,
        border: 'none',
    },
    withdrawButton: {
        width: 100,
    },
    detailsButton: {
        width: 100,
        display: 'none',
    },
    supplyApyGroup: {
        display: 'flex',
        alignItems: 'center',
        color: color.content.secondary,
        '& svg': {
            width: 16,
            height: 16,
            marginLeft: spacing['03'],
        },
    },
    progressWrapper: {
        margin: `${spacing['04']} ${spacing['08']} 0 0`,
        '& .ant-progress-line': {
            marginBottom: 0,
        },
        '& .ant-progress-bg': {
            height: '4px !important',
        },
        '& .ant-progress': {
            position: 'relative',
            '& .ant-progress-inner': {
                backgroundColor: `${color.background.quaternary} !important`,
            },
        },
        '& .ant-progress-text': {
            position: 'absolute',
            top: -14,
            visibility: 'hidden',
        },
        '& .ant-progress-outer': {
            paddingInlineEnd: '0 !important',
        },
    },
    tooltipRoot: {
        borderRadius: 4,
        width: 296,
        maxWidth: 296,
        '& .ant-tooltip-inner': {
            padding: spacing['05'],
            border: `1px solid transparent`,
            background: `${color.background.quaternary} !important`,
            borderRadius: 4,
            '& .tooltipContentRow': {
                display: 'flex',
                justifyContent: 'space-between',
            },
        },
        '& .ant-tooltip-arrow:before': {
            background: `${color.background.quaternary} !important`,
        },
        '& .ant-progress-bg': {
            height: '4px !important',
        },
        '& .ant-tooltip': {
            fontSize: `${fontSize.sm} !important`,
        },
        '& .ant-progress': {
            lineHeight: 0.5,
        },
    },
    tooltipContentRow: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: fontSize.sm,
        fontWeight: 400,
    },
});
