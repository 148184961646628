import { parseUnits } from 'ethers';

import { Permission, Token, UserBorrow } from 'src/api/borrow';
import { UserBorrowItem } from 'src/components/Borrow/UserBorrows';

export const getTransformedUserBorrow = (object: UserBorrow): UserBorrowItem => {
    return {
        address: object.address,
        id: object.leverage.id,
        apy: object.leverage.apy,
        leverage_address: object.leverage.address,
        name: object.leverage.name,
        symbol: object.leverage.symbol,
        decimals: object.leverage.decimals,
        amount: object.leverage.amount,
        opened_at: object.opened_at,
        maintenance: object.maintenance,
        history: object.history,
    };
};

export const getTransformedUserBorrowList = (objectsArray: UserBorrow[]): UserBorrowItem[] => {
    return objectsArray
        .sort((a, b) => {
            return new Date(b.opened_at).getTime() - new Date(a.opened_at).getTime();
        })
        .map((object: UserBorrow) => getTransformedUserBorrow(object));
};

export const mergeArraysById = (arr1: Token[], arr2: Permission[]) => {
    const map1 = new Map(arr1.map((obj) => [obj.id, obj]));
    const map2 = new Map(arr2.map((obj) => [obj.id, obj]));

    const result = [];

    for (const [id, obj1] of map1) {
        if (map2.has(id)) {
            const obj2 = map2.get(id);
            result.push({ ...obj1, ...obj2 });
        }
    }

    return result;
};

export const compareBigNumbers = (numStr1: string, numStr2: string, decimals: number) => {
    const bigNumber1 = parseUnits(numStr1, decimals);
    const bigNumber2 = parseUnits(numStr2, decimals);

    if (bigNumber1 > bigNumber2) {
        return 1;
    } else if (bigNumber1 < bigNumber2) {
        return -1;
    } else {
        return 0;
    }
};
