import React, { FC, useEffect, useState } from 'react';
import { ReactComponent as CoinsIcon } from '@blink/assets/src/icons/coins.svg';
import { ReactComponent as CheckmarkIcon } from '@blink/assets/src/icons/checkmark.svg';
import { AssetWithIcon, TextButton } from '@blink/components';

import { useCollateralAssetCardStyle } from './CollateralAssetCard.style';
import { CollateralAssetInput } from './CollateralAssetInput';
import { SelectedBorrowAsset } from 'src/pages/BorrowAsset';

type CollateralAssetCardProps = {
    assetToBorrowName: string;
    amount: string;
    name: string;
    onSubmit: (asset: SelectedBorrowAsset) => void;
    address: string;
    symbol: string;
    decimals: number;
    disabled?: boolean;
    selectedInfo?: SelectedBorrowAsset;
};

export const CollateralAssetCard: FC<CollateralAssetCardProps> = ({
    name,
    amount,
    selectedInfo,
    address,
    onSubmit,
    assetToBorrowName,
    disabled,
    symbol,
    decimals,
}) => {
    const classes = useCollateralAssetCardStyle();
    const [showInput, setShowInput] = useState(false);

    useEffect(() => {
        // When user changes their amount to borrow to 0 while selecting collateral
        if (showInput && disabled) {
            setShowInput(false);
        }
    }, [disabled]);

    const handleShowInput = async () => {
        setShowInput(!showInput);
    };

    const handleSubmit = (collateralValue: string) => {
        onSubmit({
            name,
            amount: collateralValue,
            amountInAssetToBorrow: collateralValue,
            address,
            symbol,
            decimals,
        });
        setShowInput(false);
    };

    return (
        <>
            <TextButton disabled={disabled} onClick={handleShowInput} className={classes.root}>
                <AssetWithIcon assetName={symbol || ''} assetSymbol={symbol} />
                <div className={classes.amountWrapper}>
                    <div>{amount}</div>
                    <CoinsIcon />
                    {selectedInfo && <CheckmarkIcon />}
                </div>
            </TextButton>
            <CollateralAssetInput
                max={amount}
                assetToBorrowName={assetToBorrowName}
                onSubmit={handleSubmit}
                isShown={showInput}
                decimals={decimals}
            />
        </>
    );
};
