import { createUseStyles } from 'react-jss';

import { color, fontSize, lineHeight, spacing } from '../../variables';

export const useButtonShowLessStyle = createUseStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        gap: spacing['03'],
        color: color.textPrimary,
        fontSize: fontSize.sm,
        lineHeight: lineHeight.md,
        '&:hover': {
            background: `${color.hoverSecondary} !important`,
        },
    },
});
