import { useEffect, useState } from 'react';
import { IWeb3Wallet, Web3Wallet } from '@walletconnect/web3wallet';
import { Core } from '@walletconnect/core';
import { buildApprovedNamespaces } from '@walletconnect/utils';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import {
    AbstractWallet,
    LOCAL_STORAGE,
    LocalStorage,
    WalletHelper,
} from '@blink/components/src/utils';
import { Contract, TransactionResponse, ethers, isHexString } from 'ethers';
import { walletMap } from '@blink/components/src/constants/wallet';

import MarginAccountABI from '../../src/abi/MarginAccountABI.json';
import { setWalletConnect, setWalletConnectPairingProcessing } from '../store/wallets/actions';
import { Store } from '../store';

const projectId = process.env.REACT_APP_PROJECT_ID as string;
export let web3wallet: IWeb3Wallet | null;

type UseWalletConnectProps = {
    marginAccountAddress: string;
    showErrorMessage?: boolean;
};

export const useWalletConnect = ({
    marginAccountAddress,
    showErrorMessage = true,
}: UseWalletConnectProps) => {
    const [proposal, setProposal] = useState<any>(null);
    const { address, type, network } = useSelector((state: Store) => ({
        address: state.wallets.active.address,
        network: state.wallets.network,
        type: state.wallets.active.type,
        topic: state.wallets.walletConnect?.connectedDapp?.topic,
    }));

    const wallet = walletMap.get(type) as AbstractWallet;

    const dispatch = useDispatch();
    useEffect(() => {
        if (web3wallet || !type) {
            return;
        }
        if (!isHexString(marginAccountAddress)) {
            return;
        }
        (async () => {
            const core = new Core({
                logger: 'debug',
                projectId,
                relayUrl: 'wss://relay.walletconnect.com',
            });

            const INCREASE_GAS_PRICE_PERCENT = 110n;
            const TOTAL_PERCENT = 100n;

            web3wallet = await Web3Wallet.init({
                core,
                metadata: {
                    name: 'Arkis',
                    description: 'Arkis Web3Wallet for WalletConnect',
                    url: process.env.REACT_APP_HOME_URL as string,
                    icons: [`${process.env.REACT_APP_HOME_URL}/logo192.svg` as string],
                },
            });

            web3wallet.on('session_proposal', (e) => {
                console.log('WC:set_proposal', e);
                setProposal(e);
            });

            web3wallet.on('auth_request', (e) => {
                console.log('WC:auth_request', e);
            });

            web3wallet.on('session_delete', (e) => {
                console.log('WC:sessiont_delete', e);
                dispatch(setWalletConnect({} as any));
                dispatch(setWalletConnectPairingProcessing(false));
            });

            web3wallet.on('session_request', async (e) => {
                console.log('WC:session_request', e);

                const params = e.params.request?.params[0];

                const provider = new ethers.BrowserProvider(
                    await wallet.getProvider(),
                    WalletHelper.getNetworkNumber(network),
                );
                const signer = await provider.getSigner();

                try {
                    console.log('WC:execution', { signer, marginAccountAddress });
                    const account = new Contract(marginAccountAddress, MarginAccountABI, signer);
                    const estimatedGas = await account.execute.estimateGas({
                        target: params.to,
                        value: params.value ?? 0n,
                        payload: params.data,
                    });

                    const gasLimit = (estimatedGas * INCREASE_GAS_PRICE_PERCENT) / TOTAL_PERCENT;

                    const tx: TransactionResponse = await account.execute(
                        {
                            target: params.to,
                            value: params.value ?? 0n,
                            payload: params.data,
                        },
                        { gasLimit },
                    );
                    console.log('WC:tx_hash', tx.hash);

                    await web3wallet?.respondSessionRequest({
                        topic: e.topic,
                        response: {
                            id: e.id,
                            jsonrpc: '2.0',
                            result: tx.hash,
                        },
                    });
                } catch (err: any) {
                    let message: string;
                    if (err.reason === 'rejected') {
                        console.warn('WC:user_rejected', err);
                        message = 'User rejected transaction';
                    } else {
                        console.warn('WC:transaction_failed', err);
                        message = 'Transaction failed';
                    }

                    if (web3wallet) {
                        await web3wallet.respondSessionRequest({
                            topic: e.topic,
                            response: {
                                id: e.id,
                                jsonrpc: '2.0',
                                error: {
                                    code: 5000,
                                    message,
                                },
                            },
                        });
                    } else {
                        console.warn('WC:wallet_undefined');
                    }
                }
            });
        })();
    }, [type]);

    useEffect(() => {
        (async () => {
            if (!proposal) {
                return;
            }

            const { id, params } = proposal;
            const { relays } = params;
            const namespaces = buildApprovedNamespaces({
                proposal: params,
                supportedNamespaces: {
                    eip155: {
                        accounts: [
                            `eip155:5:${marginAccountAddress || ''}`,
                            `eip155:1:${marginAccountAddress || ''}`,
                        ],
                        chains: ['eip155:5', 'eip155:1'],
                        events: ['chainChanged', 'accountsChanged'],
                        methods: ['eth_sendTransaction', 'personal_sign'],
                    },
                },
            });

            try {
                const approveSession = await web3wallet?.approveSession({
                    id,
                    relayProtocol: relays[0].protocol,
                    namespaces,
                });
                const connectedDapp = approveSession?.peer?.metadata;
                const connectedName = connectedDapp?.name || 'dapp';
                dispatch(
                    setWalletConnect({
                        name: connectedName,
                        marginAccount: marginAccountAddress,
                        url: connectedDapp?.url || '/',
                        topic: approveSession?.topic || '',
                    }),
                );
                message.success(`Wallet connected to ${connectedName}`);
                LocalStorage.setItem(
                    LOCAL_STORAGE.WALLET_CONNECT_TOPIC,
                    approveSession?.topic || '',
                );
            } catch (e: any) {
                if (showErrorMessage) {
                    message.error(e.toString());
                }
                console.error('wallet connect error', e);
                await web3wallet?.rejectSession({
                    id: proposal.id,
                    reason: {
                        code: 5000,
                        message: 'Session rejection',
                    },
                });
            } finally {
                setProposal(null);
                dispatch(setWalletConnectPairingProcessing(false));
            }
        })();
    }, [address, proposal]);

    const handlePair = async (uri: string) => {
        dispatch(setWalletConnectPairingProcessing(true));
        try {
            await web3wallet?.core.pairing.pair({ uri, activatePairing: true });
        } catch (e: any) {
            if (showErrorMessage) {
                message.error(e.toString());
            }
            dispatch(setWalletConnectPairingProcessing(false));
        }
    };

    const disconnect = async (topic: string) => {
        console.warn('WC:user_self_disconnected', topic);
        dispatch(setWalletConnectPairingProcessing(true));
        try {
            await web3wallet?.disconnectSession({
                topic,
                reason: {
                    message: 'User self disconnected',
                    code: 6000,
                },
            });
            dispatch(setWalletConnect({} as any));
        } catch (e: any) {
            if (showErrorMessage) {
                message.error(e.toString());
            }
            dispatch(setWalletConnectPairingProcessing(false));
        }
    };

    // Disconnect from trading when user disconnected wallet.
    useEffect(() => {
        if (type !== '') {
            return;
        }
        if (!web3wallet) {
            return;
        }
        const topic = LocalStorage.getItem(LOCAL_STORAGE.WALLET_CONNECT_TOPIC) as string;
        if (topic) {
            disconnect(topic);
        }
    }, [type]);

    return { handlePair, disconnect };
};
