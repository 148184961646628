import { createUseStyles } from 'react-jss';
import { color, fontSize, spacing } from '@blink/components';

export const useCollateralHealthCardStyle = createUseStyles({
    healthFactor: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: spacing['02'],
        '& path': {
            fill: color.content.secondary,
        },
    },
    flexWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: spacing['02'],
        '& svg:hover path': {
            fill: `${color.border.selected} !important`,
        },
    },
    success: {
        color: color.successText,
    },
    error: {
        color: color.background.negative,
    },
    healthBadge: {
        padding: `${spacing['03']} ${spacing['02']}`,
        borderRadius: 16,
        display: 'flex',
        alignItems: 'center',
        gap: spacing['02'],
        lineHeight: '14px',
        fontSize: fontSize.sm,
        fontWeight: 600,
        '& svg': {
            width: 14,
            height: 14,
            display: 'flex',
        },
    },
    healthBadgeSuccess: {
        background: color.successText,
        color: color.background.primary,
    },
    healthBadgeError: {
        background: color.background.negative,
    },
    healthBadgeWarning: {
        background: color.background.warning,
        '& path': {
            fill: color.border.selected,
        },
    },
    secondary: {
        color: color.content.secondary,
    },
    infoCard: {
        marginTop: spacing['06'],
    },
    placeholderHealth: {
        width: 48,
        height: 20,
        background: 'rgba(116, 129, 164, 0.1)',
        borderRadius: 4,
    },
    placeholderBarWrapper: {
        width: '100%',
        display: 'flex',
        gap: spacing['02'],
        marginTop: spacing['07'],
    },
    slider: {
        position: 'absolute',
        left: 0,
        top: -3.5,
        WebkitAppearance: 'none',
        width: '100%',
        height: 10,
        backgroundColor: 'transparent',
        '&::-webkit-slider-thumb': {
            WebkitAppearance: 'none',
            appearance: 'none',
            width: 1.5,
            height: 8,
            background: color.background.alwaysLight,
        },
        '&::-moz-range-thumb': {
            width: 1.5,
            height: 8,
            background: color.background.alwaysLight,
        },
    },
    placeholderBar: {
        width: '100%',
        height: 30,
        background: 'rgba(116, 129, 164, 0.1)',
        borderRadius: 4,
    },
    barWrapper: {
        width: '100%',
        textAlign: 'center',
    },
    emptyBar: {
        width: '100%',
        height: 6,
        background: 'rgba(116, 129, 164, 0.4)',
        borderRadius: 1,
        marginBottom: spacing['04'],
        '& input': {
            visibility: 'hidden',
        },
    },
    errorBar: {
        position: 'relative',
        width: '100%',
        height: 6,
        background: color.background.negative,
        borderRadius: 1,
        marginBottom: spacing['04'],
    },
    successBar: {
        position: 'relative',
        width: '100%',
        height: 6,
        background: color.background.positive,
        borderRadius: 1,
        marginBottom: spacing['04'],
    },
    warningBar: {
        position: 'relative',
        width: '100%',
        height: 6,
        background: color.background.warning,
        borderRadius: 1,
        marginBottom: spacing['04'],
    },
    tooltipRoot: {
        borderRadius: 4,
        minWidth: 88,
        maxWidth: 296,
        '& .ant-tooltip-arrow': {
            top: 1,
        },
        '& .ant-tooltip-inner': {
            padding: `${spacing['03']} ${spacing['04']}`,
            border: `1px solid transparent`,
            background: `${color.background.quaternary} !important`,
        },
        '& .ant-tooltip-arrow:before': {
            background: `${color.background.quaternary} !important`,
        },
        '& .ant-tooltip': {
            fontSize: `${fontSize.xs} !important`,
        },
    },
    small: {
        '&.ant-skeleton-input': {
            width: '124px !important',
            height: '40px !important',
            minWidth: '124px !important',
            backgroundColor: `${color.content.overlayDark1} !important`,
            borderRadius: 4,
        },
    },
    micro: {
        '&.ant-skeleton-input': {
            width: '48px !important',
            height: '20px !important',
            minWidth: '48px !important',
            backgroundColor: `${color.content.overlayDark1} !important`,
            borderRadius: 4,
        },
    },
});
