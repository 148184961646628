import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider } from 'react-router-dom';
import { Api } from '@blink/components';

import router from './routes';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
console.log('%cArkis Version: 1.2.1', 'color:green; font-size: 30px;');
console.table({
    'Margin Account': Api.MARGIN_ACCOUNT_ADDRESS,
    'Liquidity Pool': Api.LIQUIDITY_POOL_ADDRESS,
    'Insurance Fund': Api.INSURANCE_FUND_ADDRESS,
    'PIPELINE ID': Api.PIPELINE_ID,
    'COMMIT ID': Api.COMMIT_ID,
});

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>,
);
