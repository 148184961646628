import { useSelector } from 'react-redux';
import { AbstractWallet, WalletHelper } from '@blink/components/src/utils';
import { Contract, ethers, parseUnits } from 'ethers';
import { Api } from '@blink/components';
import { walletMap } from '@blink/components/src/constants/wallet';

import PoolRegistryAbi from '../abi/PoolRegistryAbi.json';
import { Store } from '../store';
import PoolAbi from '../abi/PoolAbi.json';
import IERCAbi from '../abi/IERCAbi.json';

type Callbacks = {
    /** Will fire on order being placed on blockchain */
    orderPlacedCallback?: () => void;
    /** Will fire on order being completed/failed on blockchain */
    receiptReceivedCallback?: (data: any) => void;
};

type LiquidityProps = Callbacks & {
    amount: string;
    tokenAddress: string;
};

type WithdrawProps = LiquidityProps & {
    addressTo: string;
};

export const useEthers = () => {
    const { address, network, type } = useSelector((state: Store) => ({
        address: state.wallets.active.address,
        type: state.wallets.active.type,
        network: state.wallets.network,
    }));

    const setup = async (tokenAddress: string) => {
        const wallet: AbstractWallet = walletMap.get(type) as AbstractWallet;
        const walletProvider = await wallet.getProvider();

        const provider = new ethers.BrowserProvider(
            walletProvider,
            WalletHelper.getNetworkNumber(network),
        );
        const signer = await provider.getSigner();
        const liquidityPool = new Contract(
            Api.LIQUIDITY_POOL_ADDRESS as string,
            PoolRegistryAbi,
            provider,
        );

        const poolAddress = await liquidityPool.getPool(tokenAddress);
        const pool = new Contract(poolAddress, PoolAbi, signer);
        const token = new Contract(tokenAddress, IERCAbi, signer);
        return { pool, token };
    };

    const deposit = async ({
        amount,
        tokenAddress,
        orderPlacedCallback,
        receiptReceivedCallback,
    }: LiquidityProps) => {
        const { pool, token } = await setup(tokenAddress);
        const poolAddress = await pool.getAddress();
        const decimals = await token.decimals();
        const allowance = await token.allowance(address, poolAddress);
        const amountDecimal = parseUnits(amount.toString(), decimals);
        if (allowance < amountDecimal) {
            const approveToken = await token.approve(poolAddress, amountDecimal);
            await approveToken.wait();
        }

        const tx = await pool.deposit(amountDecimal, []);

        orderPlacedCallback?.();

        if (receiptReceivedCallback) {
            const receipt = await tx.wait();

            receiptReceivedCallback(receipt);
        }
    };

    const withdraw = async ({
        amount,
        tokenAddress,
        orderPlacedCallback,
        receiptReceivedCallback,
        addressTo,
    }: WithdrawProps) => {
        const { pool, token } = await setup(tokenAddress);
        const decimals = await token.decimals();
        const amountDecimal = parseUnits(amount, decimals);

        const tx = await pool.withdraw(amountDecimal, addressTo);

        orderPlacedCallback?.();

        if (receiptReceivedCallback) {
            const receipt = await tx.wait();

            receiptReceivedCallback(receipt);
        }
    };

    return { deposit, withdraw };
};
