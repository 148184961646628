import { createUseStyles } from 'react-jss';

import { color, radius, spacing } from '../variables';

export const useContextMenuStyles = createUseStyles({
    root: {
        cursor: 'pointer',
    },
    dropdown: {
        '& .ant-dropdown-menu': {
            padding: spacing['03'],
            borderRadius: radius.overall,
            border: `1px solid ${color.border.opaque}`,
            '& .ant-dropdown-trigger': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            '& .ant-dropdown-menu-item': {
                padding: spacing['04'],
                display: 'flex',
                alignItems: 'center',
                columnGap: 14,
                color: color.content.secondary,
                position: 'relative',
                '&:hover': {
                    backgroundColor: color.background.primary,
                },
                '&:nth-last-child(n+2):after': {
                    content: "''",
                    display: 'block',
                    position: 'absolute',
                    bottom: 0,
                    left: spacing['03'],
                    width: `calc(100% - ${spacing['03']} - ${spacing['03']})`,
                    borderBottom: `1px solid ${color.border.opaque}`,
                },
            },
            '& .ant-dropdown-menu-item-icon': {
                width: '20px',
                height: '20px',
            },
        },
    },
    icon: {
        width: '20px',
        height: '20px',
    },
});
