import { createUseStyles } from 'react-jss';
import { color, fontSize, spacing } from '@blink/components';

export const useLiquidityAssetInputStyle = createUseStyles({
    root: {
        background: color.background.secondary,
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${color.border.opaque}`,
        borderRadius: 4,
        padding: `${spacing['04']} ${spacing['05']}`,
        '&.error': {
            borderColor: color.danger,
            '& .title': {
                color: color.danger,
            },
        },
        '& img': {
            width: 28,
            height: 28,
        },
        '&.supply': {
            paddingBottom: spacing['03'],
        },
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        color: color.content.secondary,
    },
    balanceWrapper: {
        display: 'flex',
    },
    balance: {
        color: color.content.primary,
        marginLeft: spacing['02'],
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: `${spacing['03']} 0`,
    },
    assetName: {
        fontSize: fontSize.xl,
    },
    input: {
        display: 'flex',
        width: '100%',
        margin: `0 0 0 ${spacing['04']}`,
        alignItems: 'center',
        '& .ant-input': {
            width: '100%',
            fontSize: fontSize.xl,
            borderColor: 'transparent',
            backgroundColor: 'inherit',
            textAlign: 'end',
            lineHeight: 0.5,
        },
        '& .ant-input:hover': {
            borderColor: 'transparent',
        },
        '& .ant-input-outlined:focus': {
            boxShadow: 'none',
        },
        '& input': {
            textAlign: 'end !important',
        },
        '& .ant-input::placeholder': {
            color: color.content.secondary,
        },
        '& .ant-input:focus::placeholder': {
            color: color.content.primary,
        },
    },
    borrowRates: {},
    borrowApyWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    borrowApy: {
        display: 'flex',
        alignItems: 'center',
        gap: spacing['04'],
        color: color.content.secondary,
    },
    borrowImg: {
        width: 24,
        height: 24,
    },
    inputWrapper: {
        display: 'flex',
    },
});
