import { createUseStyles } from 'react-jss';
import { color, fontSize, spacing } from '@blink/components';
import React from 'react';

export const useAssetToSupplyChartStyle = createUseStyles({
    wrapper: {
        position: 'relative',
        width: '100%',
        margin: '120px 0 0 -20px',
        height: 220,
        '& .recharts-cartesian-axis-tick-line': {
            display: 'none',
        },
        '& .recharts-cartesian-axis-line': {
            display: 'none',
        },
        '& line': {
            stroke: color.border.opaque,
        },
    },
    tooltipTitle: {
        color: color.content.secondary,
        marginBottom: spacing['03'],
    },
    tooltipValue: {
        fontSize: fontSize.xxl,
    },
    timestamp: {
        color: color.content.secondary,
        marginTop: spacing['03'],
    },
});

export const axisTickStyles: React.SVGProps<SVGTextElement> = {
    fill: color.border.selected,
    fontSize: fontSize.xs,
};

export const tooltipWrapperStyles: React.CSSProperties = {
    visibility: 'visible',
    outline: 'none',
};
