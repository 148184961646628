import { createUseStyles } from 'react-jss';
import { color, fontSize, radius, spacing } from '@blink/components';

export const useBorrowDetailsSummarySectionStyle = createUseStyles({
    root: {
        flex: 1,
        padding: `${spacing['07']} ${spacing['08']}`,
        border: `1px solid ${color.border.opaque}`,
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        backgroundColor: color.background.secondary,
        color: color.textPrimary,
        height: 'fit-content',
    },
    title: {
        fontSize: fontSize.xl,
        height: 46,
        display: 'flex',
        alignItems: 'center',
    },
    assetWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        margin: `${spacing['07']} 0`,
    },
    assetWithIconWrapper: {
        display: 'flex',
        gap: spacing['03'],
    },
    assetIcon: {
        width: 36,
        height: 36,
    },
    assetNameWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: spacing['01'],
    },
    assetName: {
        fontSize: fontSize.lg,
    },
    walletAddress: {
        color: color.content.secondary,
        display: 'flex',
        padding: 0,
        height: 16,
        gap: spacing['02'],
        '& svg': {
            width: 16,
            height: 16,
            '& > path': {
                fill: color.content.secondary,
            },
        },
        '&:hover > svg > path': {
            fill: color.textPrimary,
        },
    },
    valueWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        color: color.content.secondary,
        padding: `${spacing['04']} 0`,
    },
    riskRed: {
        color: color.danger,
    },
    riskGood: {
        color: color.successText,
    },
    titleWithQuestion: {
        display: 'flex',
        alignItems: 'center',
        color: color.content.secondary,
        gap: spacing['02'],
    },
    value: {
        color: color.textPrimary,
    },
    titleValue: {
        color: color.content.secondary,
    },
    borderBottom: {
        borderBottom: `1px solid ${color.border.opaque}`,
    },
    borrowRateWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        paddingTop: spacing['04'],
        marginTop: spacing['04'],
        borderTop: `1px solid ${color.border.opaque}`,
    },
    borrowRate: {
        marginBottom: spacing['02'],
        textAlign: 'right',
    },
    riskFactorWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        color: color.content.secondary,
        paddingBottom: spacing['04'],
    },
    riskFactorBarWrapper: {
        display: 'flex',
        width: '100%',
        marginBottom: spacing['04'],
    },
    tooltipAccountValue: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: 260,
        gap: spacing['02'],
        '& p': {
            margin: 0,
            fontSize: fontSize.sm,
            fontWeight: 400,
        },
    },
    titleTooltip: {
        fontWeight: '500 !important',
    },
    tooltipRoot: {
        borderRadius: radius.overall,
        width: 'fit-content',
        '& .ant-tooltip-arrow': {
            top: 1,
        },
        '& .ant-tooltip-inner': {
            display: 'flex',
            minWidth: 96,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: spacing['02'],
            padding: spacing['04'],
            border: `1px solid transparent`,
            background: `${color.background.quaternary} !important`,
        },
        '& .ant-tooltip-arrow:before': {
            background: `${color.background.quaternary} !important`,
        },
        '&.ant-tooltip': {
            fontSize: `${fontSize.sm} !important`,
            maxWidth: 320,
        },
    },
});
