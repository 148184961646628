export function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
}

export function isDevEnv() {
    return process.env.NODE_ENV === 'development';
}

export function isProdEnv() {
    return process.env.NODE_ENV === 'production';
}
