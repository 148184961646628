import { createUseStyles } from 'react-jss';

import { color } from '../variables';

export const useMenuStyles = createUseStyles({
    menu: {
        color: color.content.secondary,
        '& .ant-menu-item-selected': {
            color: `${color.content.primary} !important`,
            borderRadius: '0px !important',
            backgroundColor: 'transparent !important',
            '&:after': {
                borderBottom: `1px solid ${color.border.accent} !important`,
                width: '100%',
                marginLeft: -16,
                marginBottom: -1,
                transition: 'none !important',
            },
        },
    },
});
