import { createUseStyles } from 'react-jss';
import { color, fontSize, spacing } from '@blink/components';
import React from 'react';

export const useBorrowDetailsRiskFactorSectionStyle = createUseStyles({
    root: {
        flex: 1,
        borderRadius: 4,
        padding: `${spacing['07']} ${spacing['08']}`,
        border: `1px solid ${color.border.opaque}`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        backgroundColor: color.background.secondary,
        color: color.textPrimary,
    },
    graphLoadingContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    walletConnectIconWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    walletConnectIconList: {
        stroke: color.border.selected,
        width: '22px',
        height: '22px',
    },
    walletConnectIcon: {
        width: '28px',
        height: '28px',
        marginRight: spacing['04'],
    },
    riskFactorTitleWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    riskFactorTitle: {
        fontSize: fontSize.xl,
    },
    filters: {
        width: 'revert',
        '& .ant-radio-button-wrapper': {
            height: 44,
        },
    },
    chart: {
        marginTop: spacing['08'],
        position: 'relative',
        height: 220,
        width: '100%',
        marginLeft: -50,
        '& .recharts-cartesian-axis-tick-line': {
            display: 'none',
        },
        '& .recharts-cartesian-axis-line': {
            display: 'none',
        },
        '& line': {
            stroke: color.border.opaque,
        },
    },
    historicalChartLineWrapper: {
        marginTop: spacing['09'],
        display: 'flex',
        alignItems: 'center',
        gap: spacing['03'],
        paddingBottom: spacing['07'],
        borderBottom: `1px solid ${color.border.opaque}`,
        width: '100%',
    },
    historicalChartLine: {
        width: 24,
        height: 2,
        background: color.chartLine2,
        borderRadius: 4,
    },
    tooltip: {
        display: 'flex',
        padding: `${spacing['03']}`,
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: spacing['02'],
        borderRadius: 4,
        backgroundColor: color.background.quaternary,
    },
    tooltipTitle: {
        color: color.content.secondary,
        fontSize: fontSize.sm,
    },
    tooltipValue: {
        color: color.textPrimary,
        fontSize: fontSize.sm,
    },
    reverseArrowIcon: {
        transform: 'rotate(180deg)',
    },
    actionButtonGroupWrapper: {
        width: '100%',
        marginTop: spacing['05'],
        paddingBottom: spacing['05'],
        borderBottom: `1px solid ${color.border.opaque} !important`,
    },
    actionButtonWrapper: {
        width: '100%',
        padding: `${spacing['04']} 0`,
        '&:not(:last-child)': {
            borderBottom: `1px solid ${color.border.opaque} !important`,
        },
    },
    closeCreditButton: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: color.textPrimary,
        marginTop: spacing['05'],
        padding: `0 ${spacing['03']} 0 0`,
        '& svg': {
            width: 20,
            height: 20,
            transform: 'rotate(-90deg)',
        },
    },
});

export const axisTickStyles: React.SVGProps<SVGTextElement> = {
    fill: color.textPrimary,
    fontSize: fontSize.xs,
};
