import { createUseStyles } from 'react-jss';
import { color, fontSize, spacing } from '@blink/components';

export const useFaqsStyles = createUseStyles({
    root: {
        color: color.content.primary,
        margin: '52px 0',

        '& .ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header': {
            borderRadius: '0 !important',
        },
        '& div.ant-collapse': {
            background: color.background.secondary,
            borderRadius: 0,
            border: `1px solid ${color.border.opaque} !important`,

            '& .ant-collapse-header': {
                padding: `${spacing['06']} 0 ${spacing['04']}`,
                '& .ant-collapse-arrow': {
                    fontSize: fontSize.lg,
                },
            },
            '& .ant-collapse-content-box': {
                padding: 0,
                paddingBlock: '0px !important',
                '& > p': {
                    marginTop: 0,
                },
            },
            '& .ant-collapse-expand-icon': {
                position: 'absolute',
                right: 0,
            },
            '& .ant-collapse-item': {
                padding: `0 ${spacing['06']}`,
                '&:not(:last-child)': {
                    borderBottom: `1px solid ${color.border.opaque} !important`,
                },
            },
            '& .ant-collapse-content': {
                background: 'transparent',
                fontSize: fontSize.md,
                color: color.content.secondary,
                borderTop: 0,
            },
        },
    },
    title: {
        fontSize: fontSize.lg,
        padding: `0 0 ${spacing['06']} ${spacing['06']}`,
        fontWeight: 400,
        '& p': {
            marginBottom: spacing['06'],
        },
    },
    caret: {
        transition: 'transform 0.3s',
    },
    activeCaret: {
        transform: 'rotate(180deg)',
    },
});
