import React, { FC, useEffect, useState } from 'react';
import { Button, ButtonGroup, color } from '@blink/components';
import {
    Area,
    AreaChart,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { RadioChangeEvent, Spin } from 'antd';
import dayjs from 'dayjs';
import { TooltipProps } from 'recharts/types/component/Tooltip';
import { ReactComponent as ArrowIcon } from '@blink/assets/src/icons/arrow.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ReactComponent as WalletConnect } from '@blink/assets/src/icons/walletConnect.svg';

import {
    axisTickStyles,
    useBorrowDetailsRiskFactorSectionStyle,
} from './BorrowDetailsActionsSection.style';
import { BorrowDetailsButton } from './BorrowDetailsButton';
import { BorrowDetailsCloseCredit } from './BorrowDetailsCloseCredit/BorrowDetailsCloseCredit';
import { BorrowDetailState } from '../../../../pages/BorrowDetails';
import { showModalAction } from '../../../../store/modals/actions';
import { ModalName } from '../../../../store/modals/constants';
//import { UserBorrowHistoricalRisk, UserBorrowRiskFactorGraph } from '../../../../api/borrow'; // API will be changed for Risk Factor Graph
import { Store } from '../../../../store';

type BorrowDetailsRiskFactorSectionProps = {
    pageState: BorrowDetailState;
    setPageState: (value: BorrowDetailState) => void;
    marginAccountAddress: string;
    amount: string;
    accumulatedInterest: string;
    leverageAddress: string;
};

export enum UserBorrowRiskFactorChartFormat {
    WEEK = 'week',
    MONTH = 'month',
}

const menuItems = [
    {
        text: 'Week',
        value: UserBorrowRiskFactorChartFormat.WEEK,
    },
    {
        text: 'Month',
        value: UserBorrowRiskFactorChartFormat.MONTH,
    },
];

const TICK_MARGIN = 20;
const MIN_TICK_GAP = 100;
const STROKE_WIDTH = 2;
const GRADIENT_ID = 'gradientId';

const timestampTickFormatter = (value: any) => {
    return dayjs(value * 1000).format('DD');
};

// const normalizeGraphData = (data: UserBorrowRiskFactorGraph) => { // API will be changed for Risk Factor Graph
//     return (
//         data?.historical_risk_factor?.map((riskFactor: UserBorrowHistoricalRisk) => ({
//             timestamp: riskFactor.timestamp,
//             value: Number(riskFactor.risk_factor).toFixed(2),
//         })) || []
//     );
// };

export const BorrowDetailsActionsSection: FC<BorrowDetailsRiskFactorSectionProps> = ({
    pageState,
    setPageState,
    marginAccountAddress,
    amount,
    accumulatedInterest,
    leverageAddress,
}) => {
    const classes = useBorrowDetailsRiskFactorSectionStyle();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [selectedFilter, setSelectedFilter] = useState(UserBorrowRiskFactorChartFormat.WEEK);

    const { walletAddress, blockchainName } = useSelector((state: Store) => ({
        walletAddress: state.wallets.active?.address,
        blockchainName: state.wallets.network,
    }));

    const [graphLoading, setGraphLoading] = useState(true);
    const [riskFactorGraph, setRiskFactorGraph] = useState<any>([]);

    const handleFilterChange = (e: RadioChangeEvent) => setSelectedFilter(e.target.value);

    useEffect(() => {
        (async () => {
            if (!walletAddress || !blockchainName || !id) {
                return;
            }
            try {
                setGraphLoading(true);
                // const userBorrowResponse = await getUserBorrowRiskFactorGraph({ // API will be changed for Risk Factor Graph
                //     blockchainName,
                //     walletAddress,
                //     userBorrowId: id,
                //     format: selectedFilter,
                // });
                //const userBorrowGraphData = normalizeGraphData(userBorrowResponse);
                //setRiskFactorGraph(userBorrowGraphData);
                setGraphLoading(false);
            } catch (e) {
                console.error(e);
                setRiskFactorGraph([]);
                setGraphLoading(false);
            }
        })();
    }, [walletAddress, blockchainName, id, selectedFilter]);

    const renderTooltip = (props: TooltipProps<any, any>) => {
        const entry = props?.payload?.[0]?.payload;

        if (!entry) {
            return;
        }

        const timestamp = dayjs(entry.timestamp * 1000);

        return (
            <div className={classes.tooltip}>
                <div className={classes.tooltipTitle}>
                    {timestamp.format('DD MMM') + ' • ' + timestamp.format('hh:mm a')}
                </div>
                <div className={classes.tooltipValue}>{entry.value}</div>
            </div>
        );
    };
    const walletConnectModalTitle = () => {
        return (
            <div className={classes.walletConnectIconWrapper}>
                <WalletConnect className={classes.walletConnectIcon} />
                WalletConnect
            </div>
        );
    };

    const showTradeModal = () => {
        dispatch(
            showModalAction({
                modalName: ModalName.WALLET_CONNECT,
                modalTitle: walletConnectModalTitle() as any,
                modalProps: {
                    marginAccountAddress,
                },
            }),
        );
    };

    const getContent = () => {
        if (pageState === BorrowDetailState.CLOSE_CREDIT) {
            return (
                <BorrowDetailsCloseCredit
                    marginAccountAddress={marginAccountAddress}
                    onCancel={() => setPageState(BorrowDetailState.DEFAULT)}
                    amount={amount}
                    accumulatedInterest={accumulatedInterest}
                    leverageAddress={leverageAddress}
                />
            );
        }

        const getChart = () => {
            return graphLoading ? (
                <span className={classes.graphLoadingContainer}>
                    <Spin />
                </span>
            ) : (
                <AreaChart data={riskFactorGraph}>
                    <defs>
                        <linearGradient id={GRADIENT_ID} x1='0' x2='0' y1='0' y2='1'>
                            <stop offset='5%' stopColor={color.chartLine2} stopOpacity={0.3} />
                            <stop offset='95%' stopColor={color.chartLine2} stopOpacity={0.1} />
                        </linearGradient>
                    </defs>
                    <CartesianGrid vertical={false} strokeDasharray='8' />
                    <XAxis
                        tickFormatter={timestampTickFormatter}
                        dataKey='timestamp'
                        minTickGap={MIN_TICK_GAP}
                        tickMargin={TICK_MARGIN}
                        tick={axisTickStyles}
                    />
                    <YAxis tick={axisTickStyles} />
                    <Tooltip content={renderTooltip} />
                    <Area
                        type='linear'
                        dataKey='value'
                        stroke={color.chartLine2}
                        fillOpacity={1}
                        fill={`url(#${GRADIENT_ID})`}
                        strokeWidth={STROKE_WIDTH}
                        dot={false}
                    />
                </AreaChart>
            );
        };

        return (
            <>
                <div className={classes.riskFactorTitleWrapper}>
                    <div className={classes.riskFactorTitle}>Risk Factor</div>
                    <ButtonGroup
                        items={menuItems}
                        value={selectedFilter}
                        handleChange={handleFilterChange}
                        className={classes.filters}
                    />
                </div>
                <div className={classes.chart}>
                    <ResponsiveContainer width='99%' height='99%'>
                        {getChart()}
                    </ResponsiveContainer>
                </div>
                <div className={classes.historicalChartLineWrapper}>
                    <div className={classes.historicalChartLine} />
                    <div>Historical Chart Line</div>
                </div>
                <div className={classes.actionButtonGroupWrapper}>
                    <div className={classes.actionButtonWrapper}>
                        <BorrowDetailsButton
                            icon={<WalletConnect className={classes.walletConnectIconList} />}
                            title='Trade with WalletConnect'
                            text='Connect with WalletConnect to trade'
                            onClick={() => showTradeModal()}
                        />
                    </div>
                </div>
                <Button
                    type='text'
                    className={classes.closeCreditButton}
                    onClick={() => setPageState(BorrowDetailState.CLOSE_CREDIT)}
                >
                    <div>Close Credit Account</div>
                    <ArrowIcon />
                </Button>
            </>
        );
    };

    return <div className={classes.root}>{getContent()}</div>;
};
