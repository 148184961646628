import { color, spacing } from '@blink/components/src/variables';
import { createUseStyles } from 'react-jss';

export const useSkeletonRowtyles = createUseStyles({
    skeleton: {
        height: 72,
        borderBottom: `1px solid ${color.border.opaque}`,
        '& span': {
            backgroundColor: `${color.content.overlayDark1} !important`,
        },
        '& .ant-skeleton-avatar': {
            marginRight: spacing['03'],
        },
        '& .ant-skeleton-input': {
            width: '64px !important',
            height: '16px !important',
            minWidth: '64px !important',
            '&.small': {
                width: '40px !important',
                minWidth: '40px !important',
            },
        },
    },
    firstTd: {
        paddingLeft: spacing['06'],
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: spacing['06'],
    },
    vertical: {
        display: 'flex',
        flexDirection: 'column',
        gap: spacing['02'],
    },
    btn: {
        width: '112px !important',
        height: '36px !important',
    },
    small: {
        '&.ant-skeleton-input': {
            width: '40px !important',
            minWidth: '40px !important',
        },
    },
    horizontal: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
});
