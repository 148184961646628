import { InstalledWalletList, WALLET, WalletAddress } from '@blink/components/src/types';
import { installedWallets } from '@blink/components/src/constants/wallet';
import { NETWORK } from '@blink/components/src/constants/global';

import {
    ADD_ACTIVE_WALLET,
    ADD_WALLET_CONNECT,
    ADD_INSTALLED_WALLETS,
    ADD_NETWORK,
    ADD_WALLETS,
    REMOVE_WALLETS,
    SET_WALLET_CONNECT_PAIRING_PROCESSING,
} from './constants';

export type ActiveWallet = {
    address: WalletAddress | string;
    type: WALLET | string;
};

export type WalletConnectState = {
    connectedDapp?: string;
    pairingProcessing?: boolean;
};

export type WalletsState = {
    list: WalletAddress[];
    active: ActiveWallet;
    network: NETWORK | string;
    installedWallets: InstalledWalletList;
    walletConnect: WalletConnectState | null;
};

const defaultState: WalletsState = {
    list: [],
    active: {
        address: '',
        type: '',
    },
    network: '',
    installedWallets: installedWallets,
    walletConnect: null,
};

export default function wallets(state = defaultState, action: any) {
    switch (action.type) {
        case ADD_WALLETS:
            return { ...state, list: action.payload.wallets };
        case ADD_ACTIVE_WALLET:
            return { ...state, active: action.payload };
        case ADD_INSTALLED_WALLETS:
            return { ...state, installedWallets: action.payload };
        case ADD_NETWORK:
            return { ...state, network: action.payload };
        case ADD_WALLET_CONNECT:
            return {
                ...state,
                walletConnect: { ...state.walletConnect, connectedDapp: action.payload },
            };
        case SET_WALLET_CONNECT_PAIRING_PROCESSING: {
            return {
                ...state,
                walletConnect: { ...state.walletConnect, pairingProcessing: action.payload },
            };
        }
        case REMOVE_WALLETS:
            return { ...state, ...defaultState };
    }
    return state;
}
