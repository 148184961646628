import { createUseStyles } from 'react-jss';
import { color, fontSize, spacing } from '@blink/components';

export const useUserSuppliesStyle = createUseStyles({
    noSuppliesBlock: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: 280,
        background: color.background.secondary,
        border: `1px solid ${color.border.opaque}`,
        '& .ant-skeleton-input': {
            width: '250px !important',
            height: '20px !important',
            minWidth: '250px !important',
            backgroundColor: `${color.content.overlayDark1} !important`,
            borderRadius: 4,
        },
    },
    noSuppliesTitle: {
        color: color.content.primary,
        fontSize: fontSize.lg,
        marginTop: spacing['04'],
        letterSpacing: '-0.019em',
    },
    noSuppliesSubtitle: {
        textAlign: 'center',
        color: color.content.secondary,
        marginTop: spacing['03'],
        width: 390,
        letterSpacing: '-0.019em',
    },
});
