import * as actions from './actions';
import {
    GET_ASSETS_TO_SUPPLY,
    GET_USERS_SUPPLIES,
    SET_ASSETS_TO_SUPPLY,
    SET_FUNDS_TO_CLAIM,
    SET_USERS_SUPPLIES,
} from './constants';
import { Token } from 'src/api/borrow';

export type UserSupply = {
    assetAddress?: string;
    asset_name?: string;
    asset_symbol?: string;
    balance?: string;
    apy?: string;
    reward?: string;
    decimals: number;
};

export type Fund = {
    ids: Array<string>;
    isVisible: boolean;
    balances: {
        [symbol: string]: number;
    };
    addresses: {
        [symbol: string]: string;
    };
};

export type SuppliesState = {
    userSuppliesLoading: boolean;
    userSupplies: UserSupply[];
    assetsToSupplyLoading: boolean;
    assetsToSupply: Token[];
    fund: Fund;
    hasUserSupplies: boolean;
};

const defaultState: SuppliesState = {
    userSuppliesLoading: false,
    userSupplies: [],
    assetsToSupplyLoading: false,
    assetsToSupply: [],
    fund: {
        ids: [],
        balances: {},
        addresses: {},
        isVisible: false,
    },
    hasUserSupplies: false,
};

type OrderAction = ReturnType<TInferValueTypes<typeof actions>>;

export default function supplies(state = defaultState, action: OrderAction): SuppliesState {
    switch (action.type) {
        case SET_FUNDS_TO_CLAIM: {
            return {
                ...state,
                fund: { ...state.fund, ...action.payload },
            };
        }
        case GET_USERS_SUPPLIES: {
            return {
                ...state,
                userSuppliesLoading: true,
            };
        }
        case SET_USERS_SUPPLIES: {
            return {
                ...state,
                userSuppliesLoading: false,
                userSupplies: action.payload,
                hasUserSupplies: action.payload.length > 0,
            };
        }
        case GET_ASSETS_TO_SUPPLY: {
            return {
                ...state,
                assetsToSupplyLoading: true,
            };
        }
        case SET_ASSETS_TO_SUPPLY: {
            return {
                ...state,
                assetsToSupply: action.payload,
                assetsToSupplyLoading: false,
            };
        }
        default: {
            return state;
        }
    }
}
