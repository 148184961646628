import React, { FC } from 'react';
import { ReactComponent as WalletIcon } from '@blink/assets/src/icons/wallet.svg';
import { ButtonStroke } from '@blink/components';
import { useDispatch } from 'react-redux';

import { useUserSuppliesStyle } from '../UserSupplies.style';
import { useUserSuppliesConnectWalletStyle } from './UserSuppliesConnectWallet.style';
import { showModalAction } from '../../../store/modals/actions';
import { ModalName } from '../../../store/modals/constants';

export const UserSuppliesConnectWallet: FC = () => {
    const commonClasses = useUserSuppliesStyle();
    const classes = useUserSuppliesConnectWalletStyle();
    const dispatch = useDispatch();

    const handleConnectWalletClick = () =>
        dispatch(showModalAction({ modalName: ModalName.CONNECT_WALLET_MODAL }));

    return (
        <div className={commonClasses.noSuppliesBlock}>
            <WalletIcon />
            <div className={commonClasses.noSuppliesTitle}>Please, connect your wallet</div>
            <div className={commonClasses.noSuppliesSubtitle}>
                Please connect your wallet to see your supplies, borrowings, and open positions
            </div>
            <ButtonStroke className={classes.button} onClick={handleConnectWalletClick}>
                Connect Wallet
            </ButtonStroke>
        </div>
    );
};
