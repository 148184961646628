import React, { FC } from 'react';
import { copyToClipboard, truncate } from '@blink/components/src/utils';
import { ReactComponent as CopyIcon } from '@blink/assets/src/icons/copy.svg';
import { ReactComponent as QuestionIcon } from '@blink/assets/src/icons/question.svg';
import { Button, ViewOnEtherscanLink } from '@blink/components';
import { AssetIcon } from '@blink/components/src/Asset/AssetIcon';
import { message, Tooltip } from 'antd';

import { useBorrowDetailsSummarySectionStyle } from './BorrowDetailsSummarySection.style';
import { RiskFactor } from '../../../RiskFactor/RiskFactor';
import { RiskFactorBar } from 'src/components/RiskFactor/RiskFactorBar';
import { RiskFactorTooltip } from 'src/components/RiskFactor/RiskFactorTooltip';

type BorrowDetailsSummarySectionProps = {
    assetName: string;
    walletAddress: string;
    marginAccount: string;
    riskFactor: string;
    accountValue: string;
    apy: string;
    accumulatedInterest: string;
};

export const BorrowDetailsSummarySection: FC<BorrowDetailsSummarySectionProps> = ({
    assetName,
    marginAccount,
    riskFactor,
    accountValue,
    apy,
    accumulatedInterest,
}) => {
    const classes = useBorrowDetailsSummarySectionStyle();

    const handleCopyAddress = () => {
        copyToClipboard(marginAccount);
        message.success('Copied to clipboard!');
    };

    const tooltipAccountValue = (
        <div className={classes.tooltipAccountValue}>
            <p className={classes.titleTooltip}>Account Value</p>
            <p>
                Total assets in your margin account.
                <br />
                Ensure it covers all obligations before closing.
            </p>
        </div>
    );

    return (
        <div className={classes.root}>
            <div className={classes.title}>Summary</div>
            <div className={classes.assetWrapper}>
                <div className={classes.assetWithIconWrapper}>
                    <AssetIcon assetName={assetName} className={classes.assetIcon} />
                    <div className={classes.assetNameWrapper}>
                        <div className={classes.assetName}>{assetName}</div>
                        <Button
                            type='text'
                            className={classes.walletAddress}
                            onClick={handleCopyAddress}
                        >
                            <div>
                                {truncate({
                                    value: marginAccount || '',
                                    startSymbols: 4,
                                    endSymbols: 4,
                                })}
                            </div>
                            <CopyIcon />
                        </Button>
                    </div>
                </div>
                <ViewOnEtherscanLink address={marginAccount} />
            </div>
            <div className={classes.riskFactorWrapper}>
                <div className={classes.titleWithQuestion}>
                    <RiskFactorTooltip riskFactor={riskFactor} />
                </div>
                <RiskFactor riskFactor={+Number(riskFactor).toFixed(8)} />
            </div>
            <div className={classes.riskFactorBarWrapper}>
                <RiskFactorBar health={+Number(riskFactor).toFixed(8)} />
            </div>
            <div className={classes.valueWrapper}>
                <div className={classes.titleWithQuestion}>
                    <Tooltip
                        rootClassName={classes.tooltipRoot}
                        title={tooltipAccountValue}
                        placement='bottomLeft'
                        arrow={{ pointAtCenter: true }}
                    >
                        <QuestionIcon />
                    </Tooltip>
                    <div>Account Value</div>
                </div>
                <div className={classes.value}>{`${accountValue} ${assetName}`}</div>
            </div>
            <div className={classes.valueWrapper}>
                <div className={classes.titleValue}>Borrow Rate</div>
                <div className={classes.value}>{apy}%</div>
            </div>
            <div className={classes.valueWrapper}>
                <div className={classes.titleValue}>Accumulated Interest</div>
                <div className={classes.value}>{Number(accumulatedInterest).toFixed(8)}</div>
            </div>
        </div>
    );
};
