import React, { FC, ReactNode } from 'react';
import { ReactComponent as CheckmarkIcon } from '@blink/assets/src/icons/checkmark.svg';
import { ReactComponent as ErrorIcon } from '@blink/assets/src/icons/alert.svg';
import { ReactComponent as AlertIcon } from '@blink/assets/src/icons/warning.svg';
import { ReactComponent as InfoIcon } from '@blink/assets/src/icons/info.svg';
import { concatClasses } from '@blink/components/src/utils';

import { useInfoCardStyle } from './InfoCard.style';

type InfoCardProps = {
    type: InfoCardType;
    text: ReactNode;

    classes?: Partial<Record<InfoCardClasses, string>>;
};

export type InfoCardType = 'success' | 'error' | 'alert' | 'info';
type InfoCardClasses = 'root';

export const InfoCard: FC<InfoCardProps> = ({ type, text, classes }) => {
    const baseClasses = useInfoCardStyle();

    const iconMap = new Map<InfoCardType, ReactNode>()
        .set('success', <CheckmarkIcon />)
        .set('alert', <AlertIcon />)
        .set('error', <ErrorIcon />)
        .set('info', <InfoIcon />);

    return (
        <div className={concatClasses(baseClasses.root, baseClasses[type], classes?.root || '')}>
            {iconMap.get(type)}
            <div>{text}</div>
        </div>
    );
};
