import { createUseStyles } from 'react-jss';
import { color, fontSize, spacing } from '@blink/components';

export const useAssetToSupplyStyle = createUseStyles({
    root: {
        padding: `${spacing['04']} 0 ${spacing['07']}`,
        borderTop: `1px solid ${color.border.opaque}`,
        background: 'rgba(0, 0, 0, 0.15)',
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `0 ${spacing['06']} ${spacing['04']}`,
        fontSize: fontSize.lg,
    },
    showLessButton: {
        fontSize: fontSize.sm,
        color: color.content.primary,
        padding: 0,
    },
    menuWrapper: {
        width: '100%',
        borderBottom: `1px solid ${color.border.opaque} !important`,
    },
    menu: {
        fontSize: fontSize.md,
        paddingLeft: spacing['02'],
    },
    chartWrapper: {
        position: 'relative',
    },
    formatButtonsWrapper: {
        position: 'absolute',
        top: -100,
        right: 24,

        '& .ant-radio-button-wrapper': {
            paddingInline: spacing['07'],
        },
    },
    spinner: {
        height: 340,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});
