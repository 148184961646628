import { createUseStyles } from 'react-jss';

import { radius, fontWeight, lineHeight, color, fontSize, spacing } from '../variables';

export const useBtnStyles = createUseStyles({
    btn: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '48px',
        padding: spacing['04'],
        borderRadius: radius.overall,
        fontWeight: fontWeight.regular,
        fontSize: 'inherit',
        lineHeight: lineHeight.sm,
        color: color.background.primary,
        '&:disabled': {
            color: color.background.primary,
            backgroundColor: color.content.tertiary,
            borderColor: color.border.accent,
            opacity: '0.3',
        },
    },
    small: {
        height: 36,
        border: 'none',
    },
    medium: {
        height: 41,
    },
    large: {
        height: 'auto',
        padding: `${spacing['04']} ${spacing['05']}`,
        fontSize: fontSize.lg,
        fontWeight: 600,
        lineHeight: '22px',
        letterSpacing: -0.306,
    },
    extraLarge: {
        height: 48,
    },
});
