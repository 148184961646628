import {
    SET_ASSETS_TO_SUPPLY_ERROR,
    SET_FUNDS_TO_CLAIM_ERROR,
    SET_USERS_SUPPLIES_ERROR,
    ADD_NETWORK_ERROR,
    ADD_WALLETS_ERROR,
    SET_USER_BORROWS_ERROR,
} from 'src/store/errors/constants';
import * as actions from './actions';

export type ErrorState = {
    [key: string]: string;
};

const defaultState: ErrorState = {
    setAssetsToSupplyError: '',
    setFundsToClaimError: '',
    setUsersSuppliesError: '',
    setUserBorrowsError: '',
    addWalletsError: '',
    addNetworksError: '',
};

export type ErrorAction = ReturnType<TInferValueTypes<typeof actions>>;

export default function error(state = defaultState, action: ErrorAction): ErrorState {
    switch (action.type) {
        case SET_ASSETS_TO_SUPPLY_ERROR: {
            return {
                ...state,
                setAssetsToSupplyError: action.payload.message,
            };
        }
        case SET_FUNDS_TO_CLAIM_ERROR: {
            return {
                ...state,
                setFundsToClaimError: action.payload.message,
            };
        }
        case SET_USERS_SUPPLIES_ERROR: {
            return {
                ...state,
                setUsersSuppliesError: action.payload.message,
            };
        }
        case SET_USER_BORROWS_ERROR: {
            return {
                ...state,
                setUserBorrowsError: action.payload.message,
            };
        }
        case ADD_WALLETS_ERROR: {
            return {
                ...state,
                addWalletsError: action.payload.message,
            };
        }
        case ADD_NETWORK_ERROR: {
            return {
                ...state,
                addNetworksError: action.payload.message,
            };
        }
        default: {
            return state;
        }
    }
}
