import React, { FC, useEffect, useRef, useState } from 'react';
import { ButtonGroup, Menu } from '@blink/components';
import { ButtonShowLess } from '@blink/components/src/Button/ButtonShowLess';
import { useSelector } from 'react-redux';
import { RadioChangeEvent, Spin } from 'antd';
import { NETWORK } from '@blink/components/src/constants/global';

import { useAssetToSupplyStyle } from './AssetToSupply.style';
import { AssetToSupplyChart } from '../AssetToSupplyChart';
import { ApyChartFormat, ApyForAssetChartProps, getApyForAsset } from '../../../api/chart';
import { Store } from '../../../store';
import { FixedRateMenuKey } from '../../FixedRateMenu/constants';

type AssetToSupplyDetailsProps = {
    assetName: string;
    handleCloseDetails: () => void;
    selectedRate: FixedRateMenuKey;
};

enum Charts {
    BASE_APY = 'BASE_APY',
    PROTOCOL_ASSET_APY = 'PROTOCOL_ASSET_APY',
    PROFIT_SHARING_APY = 'PROFIT_SHARING_APY',
}

const CHART_MENU_ITEMS = [
    {
        label: 'Base APY',
        key: Charts.BASE_APY,
    },
    {
        label: 'Rewards APY',
        key: Charts.PROTOCOL_ASSET_APY,
    },
    {
        label: 'Profit Sharing APY',
        key: Charts.PROFIT_SHARING_APY,
    },
];

const FORMAT_ITEMS = [
    {
        text: '1D',
        value: ApyChartFormat.DAYS,
    },
    {
        text: '1M',
        value: ApyChartFormat.MONTHS,
    },
];

export const AssetToSupplyDetails: FC<AssetToSupplyDetailsProps> = ({
    handleCloseDetails,
    assetName,
    selectedRate,
}) => {
    const classes = useAssetToSupplyStyle();
    const { network } = useSelector((state: Store) => state.wallets);
    const [selectedChart, setSelectedChart] = useState(Charts.BASE_APY);
    const [chartData, setChartData] = useState<ApyForAssetChartProps[] | null>(null);
    const [loading, setLoading] = useState(false);
    const [format, setFormat] = useState(ApyChartFormat.DAYS);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setLoading(true);
        (async () => {
            try {
                let data = await getApyForAsset({
                    assetName,
                    blockchainName: network || NETWORK.ETHEREUM,
                    format,
                });
                if (data) {
                    data = data.map((item) => ({
                        ...item,
                        base_apy: Number(item.base_apy),
                        profit_sharing_apy: Number(item.profit_sharing_apy),
                        protocol_asset_apy: Number(item.protocol_asset_apy),
                    }));
                }
                setChartData(data);
            } finally {
                setLoading(false);
            }
        })();
    }, [network, format]);

    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'smooth' });
    }, []);

    const getChartContent = () => {
        if (loading || !chartData) {
            return (
                <div className={classes.spinner}>
                    <Spin />
                </div>
            );
        }

        const chartMap = new Map()
            .set(Charts.BASE_APY, { dataKey: 'base_apy', title: 'Base APY' })
            .set(Charts.PROTOCOL_ASSET_APY, {
                dataKey: 'protocol_asset_apy',
                title: 'Rewards APY',
            })
            .set(Charts.PROFIT_SHARING_APY, {
                dataKey: 'profit_sharing_apy',
                title: 'Profit Sharing APY',
            });

        const { dataKey, title } = chartMap.get(selectedChart);

        return (
            <div className={classes.chartWrapper}>
                <div className={classes.formatButtonsWrapper}>
                    <ButtonGroup
                        items={FORMAT_ITEMS}
                        value={format}
                        handleChange={handleFormatChange}
                    />
                </div>
                <AssetToSupplyChart chartData={chartData} dataKey={dataKey} title={title} />
            </div>
        );
    };

    const handleFormatChange = (e: RadioChangeEvent) => setFormat(e.target.value);

    return (
        <div className={classes.root} ref={ref}>
            <div className={classes.title}>
                <div>Overview</div>
                <ButtonShowLess onClick={handleCloseDetails}>Show less</ButtonShowLess>
            </div>
            <div className={classes.menuWrapper}>
                <Menu
                    theme='dark'
                    onSelect={(event) => setSelectedChart(event.key as Charts)}
                    selectedKeys={[selectedChart]}
                    items={
                        selectedRate === FixedRateMenuKey.FIXED_RATE
                            ? CHART_MENU_ITEMS.filter(
                                  (item) => item.key !== Charts.PROFIT_SHARING_APY,
                              )
                            : CHART_MENU_ITEMS
                    }
                    className={classes.menu}
                />
            </div>
            {getChartContent()}
        </div>
    );
};
