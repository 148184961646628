import { createUseStyles } from 'react-jss';
import { color, fontSize, spacing } from '@blink/components';

export const useUserBorrowsCardStyles = createUseStyles({
    root: {
        padding: spacing['05'],
        borderRadius: 4,
        border: `1px solid ${color.border.opaque}`,
        background: color.background.secondary,
        color: color.content.secondary,
        '&:hover': {
            color: color.content.secondary,
            border: `1px solid ${color.activeSecondary}`,
        },
    },
    connected: {
        border: `1px solid ${color.primary}`,
    },
    connectedTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        color: color.primary,
    },
    headerRow: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: spacing['05'],
    },
    assetInfo: {
        display: 'flex',
        gap: spacing['03'],
    },
    assetIcon: {
        width: 32,
        height: 32,
    },
    networkBadge: {
        display: 'flex',
        alignItems: 'center',
        gap: spacing['02'],
        padding: spacing['02'],
        borderRadius: 4,
        border: `1px solid ${color.content.disable}`,
        backgroundColor: color.content.secondary15,
        color: color.textPrimary,
        fontSize: fontSize.sm,
        height: 24,
    },
    footerRow: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    footerRight: {
        display: 'flex',
        gap: spacing['04'],
    },
    footerCell: {
        display: 'flex',
        flexDirection: 'column',
        gap: spacing['03'],
        marginRight: '14px',
        fontSize: fontSize.sm,
    },
    riskFactor: {
        color: color.background.warning,
        fontSize: fontSize.md,
    },
    totalBalance: {
        display: 'flex',
        alignItems: 'baseline',
        lineHeight: '16px',
    },
    totalBalanceFull: {
        marginRight: spacing['02'],
        fontSize: fontSize.lg,
    },
    white: {
        color: color.textPrimary,
        fontSize: fontSize.md,
    },
    emptyRoot: {
        minHeight: 125,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: spacing['03'],
        borderRadius: 4,
        border: `1px dashed ${color.border.opaque}`,
        background: color.background.secondary,
        color: color.content.secondary,
        cursor: 'pointer',
    },
    wallet: {
        display: 'flex',
        fontSize: fontSize.sm,
        '& button': {
            marginTop: -3,
            padding: `0 0 0 ${spacing['02']}`,
            height: 14,
        },
        '& svg': {
            width: 14,
            height: 14,
            color: color.content.secondary,
            '& path': {
                fill: color.content.secondary,
            },
        },
    },
    stressValue: {
        display: 'flex',
        alignItems: 'center',
        lineHeight: '16px',
        '& img': {
            width: 16,
            height: 16,
        },
    },
    stressValueFull: {
        marginLeft: spacing['02'],
        fontSize: fontSize.md,
    },
    stressTestValue: {
        margin: `${spacing['05']} 0`,
        paddingBottom: spacing['05'],
        borderBottom: `1px solid ${color.border.opaque}`,
    },
    totalValue: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: '100%',
        marginRight: 0,
    },
    notClickable: {
        '&:hover': {
            border: `1px solid ${color.border.opaque}`,
            cursor: 'auto',
        },
    },
});
