import { Api } from '@blink/components';
import { NETWORK } from '@blink/components/src/constants/global';

export const enum BorrowStatusType {
    OPENED = 'opened',
    SUSPENDED = 'suspended',
    REGISTERED = 'registered',
    CLOSED = 'closed',
}

export type Token = {
    id?: number;
    apy?: string;
    apyRewards?: string;
    totalSupplyMax?: string;
    totalSupplyCurrent?: string;
    address?: string;
    name: string;
    symbol: string;
    balance?: string;
    decimals: number;
    leverage?: boolean;
    collateral?: boolean;
};

export type Permission = {
    id?: number;
    leverage?: boolean;
    collateral?: boolean;
};

export type MaintenanceData = {
    address: string;
    accrued_interest: string;
    stress_tested: string;
    risk_factor: string;
};

export type History<T> = {
    channel: string;
    data: T;
    offset: number;
};

export type UserBorrow = {
    address: string;
    leverage: {
        id: number;
        apy: string;
        address: string;
        name: string;
        symbol: string;
        decimals: number;
        amount: string;
    };
    maintenance: MaintenanceData;
    history: History<MaintenanceData>[];
    opened_at: string;
};

export type GetAssetsToBorrowProps = {
    blockchainName?: string;
    walletAddress?: string;
    status?: BorrowStatusType;
};

export const getTokens = () => {
    return Api.getResponse<Token[]>(`${Api.REACT_APP_ANALYTICS_HTTP_URL}/v1/tokens`);
};

export const getPermisions = () => {
    return Api.getResponse<Permission[]>(
        `${Api.REACT_APP_ANALYTICS_HTTP_URL}/v1/agreements/0/token-permissions`,
    );
};

export const getUserBorrows = ({
    walletAddress,
    status = BorrowStatusType.OPENED,
}: GetAssetsToBorrowProps) => {
    return Api.getResponse<UserBorrow[]>(
        `${Api.REACT_APP_ANALYTICS_HTTP_URL}/v1/margin-accounts?owner=${walletAddress}&status=${status}`,
    );
};

export type UserBorrowRiskFactorGraph = {
    id: number;
    symbol: string;
    amount: string;
    start_apy: string;
    current_apy: string;
    blockchain_name: NETWORK;
    margin_account_address: string;
    status: string;
    historical_risk_factor: Array<UserBorrowHistoricalRisk>;
};

export type UserBorrowHistoricalRisk = {
    stress_tested: string;
    risk_factor: string;
    accrued_interest: string;
    timestamp: number;
};
