import { createUseStyles } from 'react-jss';

import { color, fontSize } from '../../variables';

export const useButtonSecondaryStyle = createUseStyles({
    root: {
        fontSize: fontSize.sm,
        color: color.background.primary,
        fontWeight: 600,
        background: color.background.alwaysLight,
        borderRadius: 4,

        '&:hover': {
            background: `${color.strokeHover} !important`,
            borderColor: `${color.strokeHover} !important`,
            color: `${color.content.primary} !important`,
            opacity: 0.85,
        },
        '&:active': {
            background: color.activeSecondary,
            borderColor: `${color.activeSecondary} !important`,
            color: `${color.content.primary} !important`,
        },
        '&:disabled': {
            background: color.background.disabled,
            borderColor: `${color.hoverSecondary} !important`,
            color: `${color.disabledText} !important`,
        },
    },
});
