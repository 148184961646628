import { createUseStyles } from 'react-jss';

import { color, fontSize, spacing } from '../variables';

export const usePaginationStyles = createUseStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        gap: spacing['03'],
    },
    number: {
        width: 24,
        height: 24,
        padding: 0,
        color: color.content.secondary,
        fontSize: fontSize.sm,
        '&:hover': {
            color: color.textPrimary,
            backgroundColor: `${color.content.pressedLightOverlay3} !important`,
        },
    },
    activeNumber: {
        color: color.textPrimary,
        backgroundColor: color.content.pressedLightOverlay3,
    },
    arrow: {
        width: 24,
        height: 24,
        padding: 0,
        '& svg > path': {
            fill: color.textPrimary,
        },
        '&:disabled': {
            '& svg > path': {
                fill: color.content.secondary,
            },
        },
        '& svg': {
            width: 10,
            height: 10,
        },
    },
    leftArrow: {
        transform: 'rotate(90deg)',
    },
    rightArrow: {
        transform: 'rotate(270deg)',
    },
});
