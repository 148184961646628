import React, { FC, useState } from 'react';
import { AssetWithIcon, ButtonStroke } from '@blink/components';
import { ReactComponent as LogoIcon } from '@blink/assets/src/icons/logoInCircle.svg';
import { Progress } from '@blink/components/src/Progress/Progress';
import { useSelector, useDispatch } from 'react-redux';
import { ButtonSize } from '@blink/components/src/Button/Button.types';
import { shortFormatNumber } from '@blink/components/src/utils';
import { useNavigate } from 'react-router';

import { useAssetsToSupplyRowsStyle } from './AssetsToSupplyItemRow.style';
import { AssetToSupplyDetails } from '../AssetToSupplyDetails';
import { showModalAction } from '../../../store/modals/actions';
import { ModalName } from '../../../store/modals/constants';
import { FixedRateMenuKey } from '../../FixedRateMenu/constants';
import { Store } from 'src/store';
import { Token } from 'src/api/borrow';

type AssetsToSupplyRowsProps = {
    allItems: Token[];
    itemIndex: number;
    isBorrowMode: boolean;
    selectedRate: FixedRateMenuKey;
};

export const AssetsToSupplyItemRow: FC<AssetsToSupplyRowsProps> = ({
    itemIndex,
    allItems,
    isBorrowMode,
    selectedRate,
}) => {
    const classes = useAssetsToSupplyRowsStyle();
    const [detailsOpened, setDetailsOpened] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const item = allItems[itemIndex];
    const { symbol, apy, balance, apyRewards, totalSupplyCurrent, totalSupplyMax } = item;
    const formattedApyRewards = Number(apyRewards).toFixed(2);
    const currentApy = isBorrowMode ? Number(apy) : Number(apy) + Number(formattedApyRewards);
    const totalSupply = shortFormatNumber({ amount: Number(totalSupplyMax) });
    const walletAddress = useSelector((state: Store) => state.wallets.active.address);

    const handleDetailsOpen = () => setDetailsOpened(!detailsOpened);

    const handleSupplyClick = () => {
        if (isBorrowMode) {
            navigate(`/borrow-asset/${symbol}`);
            return;
        }

        dispatch(
            showModalAction({
                modalName: ModalName.LIQUIDITY_MODAL,
                modalTitle: 'Supply Liquidity',
                modalProps: {
                    assetName: symbol,
                    balance,
                    currentApy,
                    reward: `${Number(apy)}% + ${Number(apyRewards)}%`,
                    poolCap: shortFormatNumber({ amount: Number(totalSupplyCurrent) }),
                    poolCapMax: totalSupply,
                    rate: 1.007,
                },
            }),
        );

        return;
    };

    const supplyProgress = Math.round((Number(totalSupplyCurrent) / Number(totalSupplyMax)) * 100);

    // AR-54
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const tooltip = (
        <>
            <div className={classes.tooltipContentRow}>
                <div>Percentage</div>
                <div>No DATA</div>
                <div>{supplyProgress}%</div>
            </div>
            <Progress percent={supplyProgress} showInfo={false} />
            <div className={classes.tooltipContentRow}>
                <div>Circulating Supply</div>
                <div>No DATA</div>
                <div>
                    {shortFormatNumber({ amount: Number(totalSupplyCurrent) })} {symbol}
                </div>
            </div>
            <div className={classes.tooltipContentRow}>
                <div>Max Supply</div>
                <div>No DATA</div>
                <div>
                    {shortFormatNumber({ amount: Number(totalSupplyMax) })} {symbol}
                </div>
            </div>
        </>
    );

    return (
        <>
            <tr className={classes.tr}>
                <td className={classes.firstTd}>
                    <AssetWithIcon assetName={symbol} />
                </td>
                <td>
                    <div className={classes.progressWrapper}>
                        {/* <Tooltip  // Temporary hide AR-54
                            rootClassName={classes.tooltipRoot}
                            title={tooltip}
                            color={color.background.secondary}
                            placement='bottom'
                        > */}
                        <div>{`${totalSupply} ${symbol}`}</div>
                        <Progress percent={supplyProgress} />
                        {/* </Tooltip> */}
                    </div>
                </td>
                <td>
                    <div>{currentApy}%</div>
                    {!isBorrowMode && (
                        <div className={classes.supplyApyGroup}>
                            <div>{`${Number(apy)}% + ${formattedApyRewards}%`}</div>
                            <LogoIcon />
                        </div>
                    )}
                </td>
                <td>
                    <div>{`${balance} ${symbol}`}</div>
                </td>
                <td>
                    <div className={classes.buttonWrapper}>
                        <ButtonStroke
                            isSecondary={true}
                            size={ButtonSize.md}
                            className={classes.supplyButton}
                            onClick={handleSupplyClick}
                            disabled={!walletAddress}
                        >
                            {isBorrowMode ? 'Borrow' : 'Supply'}
                        </ButtonStroke>
                        <ButtonStroke
                            size={ButtonSize.md}
                            className={classes.detailsButton}
                            onClick={handleDetailsOpen}
                        >
                            Details
                        </ButtonStroke>
                    </div>
                </td>
            </tr>
            {detailsOpened && (
                <tr>
                    <td colSpan={100}>
                        <AssetToSupplyDetails
                            assetName={symbol}
                            handleCloseDetails={handleDetailsOpen}
                            selectedRate={selectedRate}
                        />
                    </td>
                </tr>
            )}
        </>
    );
};
