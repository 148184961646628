export const Message = {
    DEFAULT_ERROR: 'We are currently experiencing processing issues, sorry for the inconvenience.',

    SWAP_TOKENS_SUCCESS: 'Successfully swapped tokens!',
    SWAP_TOKENS_ERROR: 'Tokens could not be swapped now. Try again later.',

    SUPPLY_ORDER_PLACED: 'Successfully placed your order on blockchain',
    TRANSACTION_COMPLETED: 'Supply transaction was completed',

    GET_BALANCE_ERROR: 'Could not get balance info, sorry for the inconvenience.',
    GET_EXCHANGE_INFO_ERROR: 'Could not get exchange info, sorry for the inconvenience.',
    GET_GAS_ERROR: 'Could not get gas info, sorry for the inconvenience.',
    GET_POOL_ERROR: 'Could not get pool info, sorry for the inconvenience.',

    CHANGE_NETWORK_ERROR: 'Could not change the network. Please, try again.',

    PENDING_TEXT: 'Your request will take sometime for processing.',

    TAKE_LEVERAGE_SUCCESS: 'Successfully took leverage',
};
