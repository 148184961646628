import React from 'react';
import { useSelector } from 'react-redux';

import { Store } from '../../store';

export type LoginProviderProps = {
    haveAuth: unknown;
    haveNoAuth?: unknown;
};

/**
 * Logged in provider components switching components
 * in case if user is not logged in or showing nothing by default
 * */
export const LoggedInProvider = ({ haveAuth, haveNoAuth }: LoginProviderProps) => {
    const address = useSelector((state: Store) => state.wallets.active.address);
    const componentsToShow = address ? haveAuth : haveNoAuth;
    return <>{componentsToShow}</>;
};
