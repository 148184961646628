import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import App from '../App';
import { Leverage } from '../pages/Leverage';
import { WalletConnect } from '../pages/WalletConnect';
import { Borrow } from '../pages/Borrow';
import { BorrowDetails } from '../pages/BorrowDetails';
import { BorrowAsset } from 'src/pages/BorrowAsset';

const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        children: [
            {
                path: '/',
                element: <Leverage />,
            },
            {
                path: '/borrow',
                element: <Borrow />,
            },
            {
                path: '/borrow/:address',
                element: <BorrowDetails />,
            },
            {
                path: '/wallet-connect',
                element: <WalletConnect />,
            },
            {
                path: '/borrow-asset/:symbol',
                element: <BorrowAsset />,
            },
        ],
    },
]);

export default router;
