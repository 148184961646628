import React, { FC } from 'react';
import { Tooltip } from 'antd';
import { ReactComponent as QuestionIcon } from '@blink/assets/src/icons/question.svg';

import { userRiskFactorStyle } from './RiskFactor.style';

export type RiskFactorTooltipProps = {
    riskFactor: string | undefined;
};

export const RiskFactorTooltip: FC<RiskFactorTooltipProps> = ({ riskFactor }) => {
    const classes = userRiskFactorStyle();

    const tooltipRiskFactor = (
        <div className={classes.tooltipRiskFactor}>
            <div className={classes.top}>
                <div className={classes.heading}>
                    <div className={classes.content}>
                        <p className={classes.titleTooltip}>Risk Factor</p>
                        <p>Safety of your collateral against borrowed assets.</p>
                    </div>
                    <div className={classes.percent}>{+Number(riskFactor).toFixed(8)}</div>
                </div>
                <div className={classes.bar}>
                    <div className={classes.range}>
                        <div className={classes.leftPointer}>
                            <p>1x</p>
                            <p className={classes.leftPointerNote}>Liquidation Value</p>
                        </div>
                        <div className={classes.rightPointer}>1x</div>
                    </div>
                </div>
            </div>
            <div className={classes.bottom}>
                If health factor drops below 1, collateral liquidation may occur.
            </div>
        </div>
    );
    return (
        <>
            <Tooltip
                rootClassName={classes.tooltipRoot}
                title={tooltipRiskFactor}
                placement='bottomLeft'
                arrow={{ pointAtCenter: true }}
            >
                <QuestionIcon />
            </Tooltip>
            <div>Risk Factor</div>
        </>
    );
};
