import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as InfoIcon } from '@blink/assets/src/icons/info.svg';
import { Tooltip } from 'antd';

import { useUserSuppliesSectionStyle } from '../UserSupplies/UserSuppliesSection/UserSuppliesSection.style';
import { useAssetsToSupplySectionStyle } from '../AssetsToSupply/AssetsToSupplySection/AssetsToSupplySection.style';
import { AssetToClaim } from './AssetToClaim/AssetToClaim';
import { Store } from '../../store';

export const AssetsToClaim = () => {
    const externalClasses = useUserSuppliesSectionStyle();
    const classes = useAssetsToSupplySectionStyle();

    const { ids = [], isVisible } = useSelector((state: Store) => ({
        ids: state.supplies.fund.ids,
        isVisible: state.supplies.fund.isVisible,
    }));

    const getAssetToClaim = () => {
        return ids.map((id) => <AssetToClaim key={id} assetName={id} />);
    };

    const tooltipAccountValue = (
        <div className={externalClasses.tooltipAccountValue}>
            <p>
                Here's where you can find any assets that you
                <br />
                get back from positions that were closed due
                <br />
                to market conditions.
            </p>
        </div>
    );

    return isVisible ? (
        <div className={externalClasses.root}>
            <div className={`${externalClasses.title} ${externalClasses.titleWrapper}`}>
                <span>Assets to Claim</span>
                <Tooltip
                    rootClassName={externalClasses.tooltipRoot}
                    title={tooltipAccountValue}
                    placement='bottomLeft'
                    arrow={{ pointAtCenter: true }}
                >
                    <InfoIcon className={externalClasses.iconInfo} />
                </Tooltip>
            </div>
            <div className={externalClasses.tableWrapper}>
                <table className={externalClasses.table}>
                    <thead>
                        <tr className={externalClasses.tr}>
                            <th className={`${classes.asset}`}>Asset</th>
                            <th className={`${classes.totalSupplyWrapper} ${classes.claimReward}`}>
                                Claim Amount
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>{getAssetToClaim()}</tbody>
                </table>
            </div>
        </div>
    ) : null;
};
