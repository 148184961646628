import { createUseStyles } from 'react-jss';
import { color, fontSize, spacing } from '@blink/components';

export const useBorrowDetailsStyle = createUseStyles({
    breadcrumbs: {
        display: 'flex',
        alignItems: 'center',
        margin: `${spacing['10']} 0 ${spacing['08']}`,
        color: color.textPrimary,
        fontSize: fontSize.lg,
        gap: spacing['03'],
    },
    link: {
        color: color.content.secondary,
        '&:hover': {
            color: color.textPrimary,
        },
        '&:last-of-type': {
            color: color.textPrimary,
        },
    },
    arrow: {
        transform: 'rotate(-90deg)',
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        gap: spacing['07'],
    },
});
