export class Api {
    static MARGIN_ACCOUNT_ADDRESS = process.env.REACT_APP_MARGIN_ACCOUNT_ADDRESS;
    static LIQUIDITY_POOL_ADDRESS = process.env.REACT_APP_LIQUIDITY_POOL_ADDRESS;
    static INSURANCE_FUND_ADDRESS = process.env.REACT_APP_INSURANCE_FUND_ADDRESS;
    static PIPELINE_ID = process.env.REACT_APP_CI_PIPELINE_IID;
    static COMMIT_ID = process.env.REACT_APP_CI_COMMIT_SHORT_SHA;

    static MARKET_DATA_SERVER_URL = process.env.REACT_APP_MARKET_DATA_SERVER_URL;
    static LEVERAGE_MARGIN_ENGINE_URL = process.env.REACT_APP_LEVERAGE_MARGIN_ENGINE_URL;
    static ARKIS_PORTAL_HTTP_URL = process.env.REACT_APP_ARKIS_PORTAL_HTTP_URL;
    static REACT_APP_ANALYTICS_HTTP_URL = process.env.REACT_APP_ANALYTICS_HTTP_URL;

    // @deprecated
    static DEFILLAMA_URL = 'https://yields.llama.fi';
    // @deprecated
    static BASE_URL = 'http://16.16.166.19:11460/';
    // @deprecated
    static WEBSOCKET_MARKET_URL = 'ws://api.blinkfinance.xyz:11410';
    // @deprecated
    static WEBSOCKET_ORDER_URL = 'ws://api.blinkfinance.xyz:11420';
    // @deprecated
    static WEBSOCKET_ORDER_RECEIVER_URL = 'ws://api.blinkfinance.xyz:11430';

    static async getResponse<T>(url: string, init?: RequestInit): Promise<T> {
        try {
            const urlWithApiKey = `${url}${url.includes('?') ? '&' : '?'}`;
            const data = await fetch(urlWithApiKey, {
                headers: {
                    ...init?.headers,
                },
                ...init,
            });
            if (data.ok) {
                return data.json().then((json) => {
                    if (url.includes(this.DEFILLAMA_URL)) {
                        return json;
                    }

                    return json.result;
                });
            }
            return Promise.reject(data);
        } catch (e) {
            return Promise.reject(e);
        }
    }
}
