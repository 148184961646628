import { actionCreator } from '@blink/components/src/utils';

import {
    GET_ASSETS_TO_SUPPLY,
    GET_FUNDS_TO_CLAIM,
    GET_USERS_SUPPLIES,
    SET_ASSETS_TO_SUPPLY,
    SET_FUNDS_TO_CLAIM,
    SET_USERS_SUPPLIES,
} from './constants';
import { Fund, UserSupply } from './index';
import { Token } from 'src/api/borrow';

export const getUsersSupplies = () => actionCreator(GET_USERS_SUPPLIES);

export const setUsersSupplies = (payload: UserSupply[]) =>
    actionCreator(SET_USERS_SUPPLIES, payload);

export const getAssetsToSupplyAction = () => actionCreator(GET_ASSETS_TO_SUPPLY);

export const getFundAction = () => actionCreator(GET_FUNDS_TO_CLAIM);

export const setFundAction = (payload: Fund) => actionCreator(SET_FUNDS_TO_CLAIM, payload);

export const setAssetsToSupply = (payload: Token[]) => actionCreator(SET_ASSETS_TO_SUPPLY, payload);
