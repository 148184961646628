import { put, select, call, takeEvery } from 'redux-saga/effects';
import { Errors } from '@blink/components/src/constants/errors';

import { Store } from '../store';
import { userBorrowsErrorAction } from 'src/store/errors/actions';
import { BorrowStatusType, getUserBorrows, UserBorrow } from 'src/api/borrow';
import {
    setUserBorrows,
    setUserBorrowsClosed,
    setUserBorrowsRegistered,
    setUserBorrowsSuspended,
} from 'src/store/borrows/actions';
import { GET_USER_BORROWS } from 'src/store/borrows/constants';

function* getUserBorrowsSaga(action: any) {
    const status = action.payload.status;
    try {
        const { walletAddress } = yield select((state: Store) => ({
            walletAddress: state.wallets.active.address,
        }));
        const userBorrows: UserBorrow[] = yield call(getUserBorrows, {
            walletAddress,
            status,
        });

        switch (status) {
            case BorrowStatusType.OPENED:
                yield put(setUserBorrows(userBorrows as UserBorrow[]));
                break;
            case BorrowStatusType.SUSPENDED:
                yield put(setUserBorrowsSuspended(userBorrows as UserBorrow[]));
                break;
            case BorrowStatusType.REGISTERED:
                yield put(setUserBorrowsRegistered(userBorrows as UserBorrow[]));
                break;
            case BorrowStatusType.CLOSED:
                yield put(setUserBorrowsClosed(userBorrows as UserBorrow[]));
                break;
        }
    } catch (e: any) {
        yield put(setUserBorrows([] as UserBorrow[]));
        yield put(userBorrowsErrorAction({ message: e.message || Errors.USER_BORROWS }));
        console.error(e);
    }
}

export function* borrowsWatcher() {
    yield takeEvery(GET_USER_BORROWS, getUserBorrowsSaga);
}
