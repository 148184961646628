import { createUseStyles } from 'react-jss';

import { color, fontSize } from '../../variables';

export const useButtonPrimaryStyles = createUseStyles({
    root: {
        fontSize: fontSize.sm,
        color: color.background.primary,
        fontWeight: 600,
        background: color.content.tertiary,
        borderRadius: 4,
        border: 'none',

        '&:hover': {
            background: color.ctaHover,
            borderColor: `${color.ctaHover} !important`,
            color: `${color.textTertiaryHover} !important`,
        },
        '&:active': {
            background: color.ctaActive,
            borderColor: `${color.ctaActive} !important`,
            color: `${color.textTertiaryActive} !important`,
        },
        '&:disabled': {
            background: color.background.disabled,
            borderColor: `${color.hoverSecondary} !important`,
            color: `${color.disabledText} !important`,
        },
    },
});
