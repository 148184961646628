import { createUseStyles } from 'react-jss';

import { color, fontSize, spacing } from '../../variables';

const tagItemStyles = {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${color.border.opaque}`,
    borderRadius: 4,
    padding: spacing['03'],
    marginRight: spacing['02'],
    color: color.content.secondary,
    fontSize: fontSize.sm,
};

export const useMultiselectStyles = createUseStyles({
    select: {
        border: `1px solid ${color.border.opaque}`,
        borderRadius: 4,
        width: '100%',
        '& .ant-select-selector': {
            alignItems: 'center',
            boxShadow: 'none',
            minHeight: '44px',
            border: `1px solid transparent !important`,
            fontSize: `${fontSize.md} !important`,
            WebkitBoxShadow: 'none !important',
            backgroundColor: 'transparent !important',
        },
        '& .ant-select-selection-placeholder': {
            color: color.content.secondary,
        },
        '& .ant-select-selection-item': {
            ...tagItemStyles,
            height: 'unset',
            lineHeight: 'unset',
        },
        '& .ant-select-selection-overflow-item-suffix': {
            top: 'unset !important',
        },
    },
    tagRoot: tagItemStyles,
    tagCancel: {
        paddingLeft: spacing['03'],
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            width: 16,
            height: 16,
        },
    },
    dropdownRender: {
        '& .ant-select-item': {
            padding: spacing['04'],
        },
    },
    dropdownControls: {
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${color.border.opaque}`,
        marginBottom: spacing['03'],
    },
    dropdownButton: {
        color: color.content.tertiary,
        padding: `0 ${spacing['04']}`,
    },
});
