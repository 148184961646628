import { createUseStyles } from 'react-jss';

export const useSupplyStyle = createUseStyles({
    root: {
        marginTop: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '32px 0 48px',
    },
    group: {
        width: 280,
        fontWeight: 500,
        '& .ant-radio-button-wrapper': {
            height: 42,
        },
    },
});
