import React, { ChangeEvent, FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { ButtonStroke, Input } from '@blink/components';
import { ButtonSize } from '@blink/components/src/Button/Button.types';
import { LOCAL_STORAGE, LocalStorage } from '@blink/components/src/utils';
import { ReactComponent as CheckMark } from '@blink/assets/src/icons/checkmark.svg';

import { Store } from '../../../store';
import { useWalletInfoModalStyle } from './WalletConnectModal.style';
import { useWalletConnect } from '../../../hooks/useWalletConnect';

export const WalletConnectModal: FC = () => {
    const classes = useWalletInfoModalStyle();
    const { marginAccountAddress, connectedDapp } = useSelector((state: Store) => ({
        ...(state.modals.modalProps as any),
        connectedDapp: state.wallets?.walletConnect?.connectedDapp?.name,
    }));
    const { handlePair } = useWalletConnect({
        marginAccountAddress,
    });
    const connectWalletByUriHandler = () => {
        walletConnectUri && handlePair(walletConnectUri);
        LocalStorage.setItem(LOCAL_STORAGE.WALLET_CONNECT_URI, walletConnectUri);
        LocalStorage.setItem(LOCAL_STORAGE.WALLET_CONNECT_MARGIN_ADDRESS, marginAccountAddress);
    };
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => setWalletConnectUri(e.target.value);
    const [walletConnectUri, setWalletConnectUri] = useState('');

    if (connectedDapp) {
        return (
            <div className={classes.connected}>
                <CheckMark />
                <div>Successfully connected via WalletConnect</div>{' '}
            </div>
        );
    }

    return (
        <div className={classes.root}>
            <Input
                placeholder='Input'
                className={classes.walletConnectInput}
                value={walletConnectUri}
                onChange={handleChange}
            />
            <div className={classes.walletConnectSubmitButtonWrapper}>
                <ButtonStroke
                    isSecondary={true}
                    size={ButtonSize.md}
                    onClick={connectWalletByUriHandler}
                >
                    Connect
                </ButtonStroke>
            </div>
        </div>
    );
};
